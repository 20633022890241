import React, { ReactNode, useEffect, useRef, useState } from 'react'

import { intersperse, sortSearchFilters } from '@utils/obj-utils'

import Button from '@components/core/button'
import { Popover, PopoverContent, PopoverTrigger } from '@components/core/popover'
import { Separator } from '@components/core/separator'
import { FinancialReportType } from '@components/financial/types'
import { ChargerFormFieldConfig } from '@components/form/types'
import { DotsVertical, Icon } from '@components/icons'

import { SearchFilters } from '@pages/layout-view/types'

import { Frequency } from 'types/filter'
import { FILTER_IDENTIFIER } from 'types/page-filters'

import ControlComponent from './control-component'

interface ActionBarProps {
  searchFilters: SearchFilters
  financialReportType?: FinancialReportType
  children?: ReactNode
  exporterFieldsConfig?: ChargerFormFieldConfig[]
  backendExportHandler?: (data: any, exportFilters: any) => void
  onRefresh?: () => void
  dateFilterFrequenciesToOmit?: Frequency[]
  approximateChildWidth?: number
}

interface ControlItemBase {
  key: string
  element: React.ReactNode
  width: number
  index: number
}

interface SeparatorControlItem extends ControlItemBase {
  isSeparator: true
}

interface NormalControlItem extends ControlItemBase {
  isSeparator?: false
}

type ControlItem = SeparatorControlItem | NormalControlItem

export const ActionBar = ({
  searchFilters,
  financialReportType,
  children,
  backendExportHandler,
  exporterFieldsConfig,
  onRefresh,
  dateFilterFrequenciesToOmit,
  approximateChildWidth = 80
}: ActionBarProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [overflowingIndexes, setOverflowingIndexes] = useState<number[]>([])

  const sortedFilters = _.omit<SearchFilters>(sortSearchFilters(searchFilters), [
    _.camelCase(FILTER_IDENTIFIER.DIMENSION_FILTERS),
    FILTER_IDENTIFIER.DIMENSION_FILTERS
  ])

  const allFilters = _.keys(sortedFilters)
  const childrenArray = React.Children.toArray(children)

  // Create static width map for items
  const itemWidths = React.useMemo(
    () => ({
      filter: 60,
      separator: 20,
      child: approximateChildWidth
    }),
    [approximateChildWidth]
  )

  // Create all control items with proper memoization
  const controlItems = React.useMemo(
    () => [
      ...allFilters.map(
        (filterName, index): NormalControlItem => ({
          key: filterName,
          element: (
            <ControlComponent
              key={filterName}
              name={filterName}
              shouldRender={true}
              componentProps={{
                filter: sortedFilters[filterName],
                financialReportType: financialReportType,
                exporterFieldsConfig: exporterFieldsConfig,
                backendExportHandler: backendExportHandler,
                type: financialReportType,
                onRefresh: onRefresh,
                frequenciesToOmit: dateFilterFrequenciesToOmit
              }}
            />
          ),
          width: itemWidths.filter,
          index
        })
      ),
      ...intersperse(childrenArray, '-').map(
        (child: any, index): ControlItem =>
          child === '-'
            ? {
                key: `child-${index}`,
                element: (
                  <Separator key={'sep-' + index} orientation='vertical' className='h-[38px]' />
                ),
                width: itemWidths.separator,
                index: allFilters.length + index,
                isSeparator: true
              }
            : {
                key: `child-${index}`,
                element: child,
                width: child?.type?.aproximateWidth ?? itemWidths.child,
                index: allFilters.length + index,
                isSeparator: false
              }
      )
    ],
    [
      allFilters,
      childrenArray,
      sortedFilters,
      financialReportType,
      exporterFieldsConfig,
      backendExportHandler,
      onRefresh,
      dateFilterFrequenciesToOmit,
      itemWidths
    ]
  )

  const checkOverflow = React.useCallback(() => {
    if (!containerRef.current) return

    const container = containerRef.current
    const containerWidth = container.offsetWidth

    let currentWidth = 0
    const newOverflowingIndexes: number[] = []

    controlItems.forEach((item) => {
      currentWidth += item.width
      if (currentWidth > containerWidth - 60) {
        // Leave space for dropdown
        newOverflowingIndexes.push(item.index)
      }
    })

    if (!_.isEqual(newOverflowingIndexes, overflowingIndexes)) {
      setOverflowingIndexes(newOverflowingIndexes)
    }
  }, [controlItems, overflowingIndexes])

  useEffect(() => {
    const handleResize = () => {
      window.requestAnimationFrame(checkOverflow)
    }

    handleResize() // Initial check
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [checkOverflow])

  const visibleItems = controlItems.filter((item) => !overflowingIndexes.includes(item.index))
  const overflowingItems = controlItems.filter(
    (item) => overflowingIndexes.includes(item.index) && !item.isSeparator
  )

  return (
    <div
      className='flex size-full flex-1 items-center gap-2 overflow-x-auto overflow-y-hidden'
      ref={containerRef}
    >
      {visibleItems.map((item) => item.element)}

      {overflowingItems.length > 0 && (
        <Popover>
          <PopoverTrigger asChild>
            <Button variant='ghost' size='action-bar'>
              <Icon icon={<DotsVertical />} />
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <div className='flex flex-col gap-2 p-2'>
              {overflowingItems.map((item, idx) => (
                <div key={item.key} className='w-full'>
                  {item.element}
                  {idx < overflowingItems.length - 1 && (
                    <Separator orientation='horizontal' className='my-2' />
                  )}
                </div>
              ))}
            </div>
          </PopoverContent>
        </Popover>
      )}
    </div>
  )
}
