import { ICellRendererParams } from 'ag-grid-community'

import { Button } from '@components/core/button'
import { ChevronRight, Icon } from '@components/icons'

export const chevronActionCellRenderer = (props: ICellRendererParams) => {
  return (
    <div className='flex size-full items-center'>
      <Button variant={props.node.isSelected() ? 'primary' : 'text'} className='p-0'>
        <Icon icon={<ChevronRight />} />
      </Button>
    </div>
  )
}
