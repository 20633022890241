import { useMutation, useQueryClient } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'
import { errorSnack } from 'utils/snackbar-utils'

function reorderSubsidiary({
  parent_node_id,
  node_ids,
  business_id
}: {
  parent_node_id?: number
  node_ids?: Array<number>
  business_id: number
}) {
  return axiosServices.post(`/businesses/${business_id}/subsidiaries/reorder`, {
    parent_node_id,
    node_ids
  })
}

export const useReorderSubsidiaries = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      parent_node_id,
      node_ids
    }: {
      parent_node_id?: number
      node_ids?: Array<number>
    }) => reorderSubsidiary({ business_id: user?.business_id!, parent_node_id, node_ids }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['fetch-subsidiaries-hierarchy', user?.business_id]
      })
      queryClient.invalidateQueries({
        queryKey: ['fetch-model-properties']
      })
    },
    onError: (err) => {
      queryClient.invalidateQueries({
        queryKey: ['fetch-subsidiaries-hierarchy', user?.business_id]
      })
      queryClient.invalidateQueries({
        queryKey: ['fetch-model-properties']
      })

      errorSnack(`Error Reordering Subsidiaries. \n ${err?.message}`)
    }
  })
}
