import { useState } from 'react'

import { ConfirmationDialog } from '@core/custom/confirmation-dialog'

import Button from '@components/core/button'
import { Icon, Trash } from '@components/icons'

export interface IActionData {
  id: number
}

interface ActionCellRendererProps {
  data: IActionData
  onAction: (data: IActionData) => void
  actionType: 'dialog' | 'custom'
  confirmationDialogTitle?: string
  confirmationDialogDescription?: string
  confirmLabel?: string
  cancelLabel?: string
  iconName?: React.ReactNode
}

export function ActionCellRenderer({
  data,
  onAction,
  actionType,
  confirmationDialogTitle = 'Dialog Title',
  confirmationDialogDescription = 'Please define your action here',
  confirmLabel = 'Yes',
  cancelLabel = 'Cancel',
  iconName = <Icon icon={<Trash />} />
}: ActionCellRendererProps) {
  const [showDialog, setShowDialog] = useState(false)

  const handleActionClick = () => {
    if (actionType === 'dialog') {
      setShowDialog(true)
    } else if (onAction) {
      onAction(data)
    }
  }

  const handleConfirmAction = () => {
    if (onAction) {
      onAction(data)
    }

    setShowDialog(false)
  }

  return (
    <div className='flex size-full items-center'>
      <Button
        size='action-bar'
        variant='text'
        className='rounded-[4px] p-0 focus:border-hidden'
        onClick={handleActionClick}
      >
        {iconName}
      </Button>

      {actionType === 'dialog' && (
        <ConfirmationDialog
          open={showDialog}
          onOpenChange={setShowDialog}
          title={confirmationDialogTitle}
          description={confirmationDialogDescription}
          confirmLabel={confirmLabel}
          cancelLabel={cancelLabel}
          onConfirm={handleConfirmAction}
        />
      )}
    </div>
  )
}
