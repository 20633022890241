import { ReactNode } from 'react'

import { ChipProps } from '@mui/material'

import { LayoutViewProps } from '../pages/layout-view/types'
import { EnumValueUnion } from '../utils/obj-utils'
import { GenericCardProps } from './root'

export type NavItemType = {
  breadcrumbs?: boolean
  caption?: ReactNode | string
  children?: NavItemType[]
  chip?: ChipProps
  color?: 'primary' | 'secondary' | 'default' | undefined
  disabled?: boolean
  external?: boolean
  icon?: GenericCardProps['iconPrimary']
  id?: string
  search?: string
  target?: boolean
  title?: ReactNode | string
  type?: string
  isSuperAdmin?: boolean
  url?: string | undefined
  itemRelated?: boolean | undefined
  role?: Array<string>
  feature_name?: string
  position?: number
}

export type LinkTarget = '_blank' | '_self' | '_parent' | '_top'

export enum MenuItemType {
  item = 'item',
  group = 'group',
  collapse = 'collapse',
  navigation = 'navigation'
}

export const isMenuItemTypeNavigation = (itemType: MenuItemType): boolean =>
  itemType === MenuItemType.navigation
export const isMenuItemTypeItem = (itemType: MenuItemType): boolean =>
  itemType === MenuItemType.item
export const isMenuItemTypeGroup = (itemType: MenuItemType): boolean =>
  itemType === MenuItemType.group
export const isMenuItemTypeCollapse = (itemType: MenuItemType): boolean =>
  itemType === MenuItemType.collapse

export enum RightDrawerTypeEnum {
  dimensions = 'dimensions',
  reportScheduler = 'report-scheduler'
}

export const isRightDrawerTypeDimensions = (type: RightDrawerTypeEnum): boolean =>
  _.isEqual(type, RightDrawerTypeEnum.dimensions)

export const isRightDrawerTypeReportScheduler = (type: RightDrawerTypeEnum): boolean =>
  _.isEqual(type, RightDrawerTypeEnum.reportScheduler)

export type RightDrawerType = EnumValueUnion<typeof RightDrawerTypeEnum>

export type CurrentPage = LayoutViewProps | null
