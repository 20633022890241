import React, { useMemo } from 'react'

import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { CHANGE_KEY } from '@hooks/use-change-detection'

import { AG_RIGHT_ALIGNED_CELL } from 'components/ag-grid/types'
import { CHARGER_MONOSPACE_CLASS } from 'config'
import { formatAmountWithDollarUnit, formatNumber } from 'utils/number-utils'

import { ITableColumn, NumberFormat, RoundingType } from '../../types/table-builder-types'
import { booleanHeader, dateHeader, numberHeader } from '../../utils/header-utils'
import { getPivotComparator } from '../utils'
import useHeatmap from './use-heatmap'

interface Props {
  columns: ITableColumn[]
  gridRef: React.MutableRefObject<AgGridReact | null>
  externalFiltersChanged: CHANGE_KEY
  showFilters?: boolean
  paginated?: boolean
}

export const useColDefs = (props: Props) => {
  const { columns, gridRef, externalFiltersChanged, showFilters = false, paginated = false } = props
  const { getColorClass } = useHeatmap({ columns, gridRef, externalFiltersChanged })

  const columnDefs = useMemo<ColDef[]>(() => {
    return columns.map((item) => {
      const header: ColDef = _.omit(item, 'customData')

      if (numberHeader(header)) {
        header.type = 'rightAligned'
        header.valueGetter = (params) => {
          if (!params.data) return null
          const value = params.data[header.field!]
          if ([null, undefined, ''].includes(value)) {
            return null
          }
          return +value
        }
        header.valueFormatter = (params) => {
          if (params.value === null) return header.aggFunc ? '' : '-'

          let value = params.value
          const rounding = item.customData?.rounding
          const numberFormat = item.customData?.numberFormat
          const noCommaSeparator = item.customData?.noCommaSeparator || false

          // Apply rounding
          if (rounding) {
            switch (rounding) {
              case RoundingType.EXACT:
                break
              case RoundingType.INTEGER:
                value = Math.round(value)
                break
              case RoundingType.ONE_DECIMAL:
                value = _.round(value, 1)
                break
              case RoundingType.TWO_DECIMAL:
                value = _.round(value, 2)
                break
              case RoundingType.THOUSANDS:
                value = _.round(value / 1000)
                break
              case RoundingType.MILLIONS:
                value = _.round(value / 1000000)
                break
            }
          }

          // Get number of decimal places needed
          let decimals: number | null = 0
          switch (rounding) {
            case RoundingType.ONE_DECIMAL:
              decimals = 1
              break
            case RoundingType.TWO_DECIMAL:
              decimals = 2
              break
            case RoundingType.EXACT:
              decimals = null
              break
          }

          // Apply number format
          if (numberFormat) {
            switch (numberFormat) {
              case NumberFormat.AMOUNT:
                return formatAmountWithDollarUnit(value, decimals, noCommaSeparator)
              case NumberFormat.PERCENTAGE:
                value = value * 100
                return `${formatNumber(value, decimals, noCommaSeparator)}%`
              default:
                return formatNumber(value, decimals, noCommaSeparator)
            }
          }

          return formatNumber(value, decimals, noCommaSeparator)
        }
      }

      if (dateHeader(header)) {
        header.cellRenderer = (params: ICellRendererParams) => params.value
      }

      if (showFilters) {
        if (numberHeader(header)) {
          header.filter = 'agNumberColumnFilter'
        } else if (booleanHeader(header)) {
          header.filter = 'agSetColumnFilter'
          header.filterParams = {
            values: ['true', 'false', 'blank'],
            suppressSorting: true
          }
          header.cellDataType = 'text'
        } else if (dateHeader(header)) {
          header.filter = 'agDateColumnFilter'
        } else {
          header.filter = 'agTextColumnFilter'
        }
      }

      header.cellClass = (params) => {
        const heatmapClass = getColorClass(params, item)
        const alignClass = numberHeader(header)
          ? `${AG_RIGHT_ALIGNED_CELL} ${CHARGER_MONOSPACE_CLASS}`
          : ''
        return _.filter([heatmapClass, alignClass], Boolean).join(' ')
      }

      const pivotComparatorConfig = _.get(item, ['customData', 'pivotComparatorConfig'])
      if (pivotComparatorConfig) {
        header.pivotComparator = getPivotComparator(pivotComparatorConfig)
      }

      if (paginated && item.customData?.formula) {
        header.filter = false
        header.sortable = false
      }

      return header
    })
  }, [columns, showFilters, paginated, getColorClass])

  return columnDefs
}
