import { useMutation } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import { queryErrorToast } from 'queries/query-toasts'
import axiosServices from 'utils/axios'

export const useConfirmPropertyLabel = () => {
  const { user } = useAuth()

  return useMutation({
    mutationFn: ({
      model,
      property,
      recordId,
      confirmedLabel,
      onError
    }: {
      model: string
      property: string
      recordId: string | number
      confirmedLabel: string
      onError: () => void
    }) => {
      return axiosServices.post(
        `/businesses/${user?.business_id}/property_renamings/${recordId}/confirm`,
        {
          model: model,
          property: property,
          confirmed_label: confirmedLabel
        }
      )
    },
    onSuccess: (data, variables, context) => {},
    onError: (error, variables) => {
      queryErrorToast(`Error Renaming Property: ${error.message || ''}`)

      variables.onError()
    }
  })
}
