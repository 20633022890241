/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'

import {
  CellEditingStoppedEvent,
  CellKeyDownEvent,
  ColDef,
  ColGroupDef,
  Column,
  FillEndEvent,
  GridReadyEvent
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { FinancialAdjustment } from 'components/financial/types'

export const UNDO_REDO_CELL_EDITING_LIMIT = 1000

export const useKeyboardDetection = (
  gridRef: React.RefObject<AgGridReact>,
  tabId: string,
  refetch?: () => void
) => {
  const [hasChanges, setHasChanges] = useState(false)
  const editedRows = useRef<Partial<FinancialAdjustment>[]>([])

  const onCellEditingStarted = useCallback(() => {
    setHasChanges(true)
  }, [])

  const onCellEditingStopped = useCallback((event: CellEditingStoppedEvent) => {
    const { data } = event.node
    const index = editedRows.current.findIndex((row) => row.id === data.id)
    if (index > -1) {
      editedRows.current[index] = data
    } else {
      editedRows.current.push(data)
    }
  }, [])

  const onRowEditingStopped = useCallback(() => {
    // Do nothing here to prevent buttons from disappearing too early
  }, [])

  const onFillEnd = useCallback((event: FillEndEvent) => {
    setHasChanges(true)

    if (event.finalRange) {
      const startRowIndex = event.finalRange.startRow?.rowIndex ?? 0
      const endRowIndex = event.finalRange.endRow?.rowIndex ?? 0

      // Get all rendered nodes
      const renderedNodes = event.api.getRenderedNodes()

      // Process nodes that are within the fill range
      renderedNodes.forEach((node) => {
        const rowIndex = node.rowIndex ?? -1
        if (rowIndex >= startRowIndex && rowIndex <= endRowIndex) {
          if (node.data) {
            // Check if this row is already in the editedRows
            const index = editedRows.current.findIndex((row) => row.id === node.data.id)
            if (index > -1) {
              // Update the existing entry
              editedRows.current[index] = node.data
            } else {
              // Add as a new entry
              editedRows.current.push(node.data)
            }
          }
        }
      })
    }
  }, [])

  const cancelEdit = useCallback(() => {
    if (gridRef.current) {
      const api = gridRef.current.api
      while (api.getCurrentUndoSize() > 0) {
        api.undoCellEditing()
      }
      api.stopEditing(true) // Stop editing and cancel changes

      // Reset the validation state in the context
      const context = api.getGridOption('context') || {}
      context.validationState = {}
      api.setGridOption('context', context)
      api.refreshCells() // Refresh the grid to reflect changes

      setHasChanges(false)
    }
  }, [gridRef])

  const isInputFocused = useCallback(() => {
    const focusedElement = document.activeElement as HTMLElement
    return (
      _.isEqual(focusedElement?.tagName, 'INPUT') ||
      _.isEqual(focusedElement?.tagName, 'TEXTAREA') ||
      _.isEqual(focusedElement?.tagName, 'SELECT') ||
      focusedElement?.isContentEditable
    )
  }, [])

  const isTextHighlighted = useCallback(() => {
    const selectedText = window.getSelection()?.toString() || ''
    return selectedText.length > 0
  }, [])

  // Type guard to check if colDef is a ColDef
  function isColDef<TData>(colDef: ColDef<TData> | ColGroupDef<TData>): colDef is ColDef<TData> {
    return !(colDef as ColGroupDef<TData>).children
  }

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (isInputFocused() || isTextHighlighted()) return

      if (
        event.key === 'Delete' ||
        event.key === 'Backspace' ||
        ((event.ctrlKey || event.metaKey) && event.key === 'x')
      ) {
        setHasChanges(true)
        if (gridRef.current) {
          const api = gridRef.current.api
          // Check if the grid is using Server-Side Row Model
          const isServerSideRowModel = _.isEqual(api.getGridOption('rowModelType'), 'serverSide')

          if (isServerSideRowModel) {
            // Update editedRows with the data of all selected cells
            api.getCellRanges()?.forEach((range) => {
              range.columns.forEach((col) => {
                api.forEachNode((node) => {
                  // Default rowIndex to 0 if undefined
                  const startRowIndex = range.startRow?.rowIndex ?? 0
                  const endRowIndex = range.endRow?.rowIndex ?? 0
                  const nodeRowIndex = node.rowIndex ?? 0

                  if (node && nodeRowIndex >= startRowIndex && nodeRowIndex <= endRowIndex) {
                    if (node.data) {
                      const index = editedRows.current.findIndex((row) => row.id === node.data.id)
                      if (index > -1) {
                        editedRows.current[index] = node.data
                      } else {
                        editedRows.current.push(node.data)
                      }
                    }
                  }
                })
              })
            })
          }
        }
      }

      if (gridRef.current) {
        const api = gridRef.current.api
        const firstRow = api.getDisplayedRowAtIndex(0)
        if (firstRow) {
          const focusedElement = document.activeElement as HTMLElement
          if (!focusedElement?.classList?.toString().includes('ag')) {
            const focusedCell = api.getFocusedCell()
            const rowIndex = focusedCell?.rowIndex ?? 0
            const focusedColumn = focusedCell?.column

            if (focusedColumn) {
              const colDef = focusedColumn.getColDef()
              if (isColDef(colDef) && colDef.pinned === 'right') {
                const allColumns = api.getColumns()
                if (allColumns && allColumns.length > 0) {
                  const columnIndex = allColumns.findIndex(
                    (col) => col.getColId() === focusedColumn.getColId()
                  )
                  let nextColumn: Column | null = null

                  // Search for the next not pinned right column
                  for (let i = columnIndex + 1; i < allColumns.length; i++) {
                    const nextColDef = allColumns[i].getColDef()
                    if (isColDef(nextColDef) && nextColDef.pinned !== 'right') {
                      nextColumn = allColumns[i]
                      break
                    }
                  }

                  // If not found, loop back to the beginning
                  if (!nextColumn) {
                    for (let i = 0; i < columnIndex; i++) {
                      const nextColDef = allColumns[i].getColDef()
                      if (isColDef(nextColDef) && nextColDef.pinned !== 'right') {
                        nextColumn = allColumns[i]
                        break
                      }
                    }
                  }

                  if (nextColumn) {
                    // Set focus to the next not pinned cell
                    api.setFocusedCell(rowIndex, nextColumn)
                  }
                }
              } else {
                // Column is not pinned right, set focus to the current cell
                api.setFocusedCell(rowIndex, focusedColumn)
              }
            } else {
              // No focused cell column, focus on the first not pinned right column
              const columnDefs = api.getColumnDefs()
              if (columnDefs && columnDefs.length > 0) {
                const firstNonPinnedRightColDef = columnDefs.find(
                  (def): def is ColDef<any> => isColDef(def) && def.pinned !== 'right'
                )
                const firstColumnId =
                  firstNonPinnedRightColDef?.field || firstNonPinnedRightColDef?.colId || ''
                if (firstColumnId) {
                  api.setFocusedCell(rowIndex, firstColumnId)
                }
              }
            }
          }
        }
      }
    },
    [isInputFocused, isTextHighlighted, gridRef, setHasChanges]
  )

  // Jump to next row after Enter pressed
  // Needed on AG Grids with select cells because enterNavigatesVerticallyAfterEdit applies too broadly
  const onCellKeyDown = useCallback((event: CellKeyDownEvent) => {
    const keyEvent = event.event as KeyboardEvent
    if (keyEvent && keyEvent.key === 'Enter') {
      const api = event.api
      const focusedCell = api.getFocusedCell()
      if (focusedCell) {
        const nextRowIndex = focusedCell.rowIndex + 1
        if (nextRowIndex < api.getDisplayedRowCount()) {
          api.ensureIndexVisible(nextRowIndex)
          api.setFocusedCell(nextRowIndex, event.column.getId())
          api.startEditingCell({
            rowIndex: nextRowIndex,
            colKey: event.column.getId()
          })
        }
      }
    }
  }, [])

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      if (refetch) {
        refetch()
      }
      return () => {
        document.removeEventListener('keydown', handleKeyDown)
      }
    },
    [handleKeyDown, refetch]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  return {
    hasChanges,
    setHasChanges,
    onCellEditingStarted,
    onCellEditingStopped,
    onRowEditingStopped,
    onFillEnd,
    editedRows,
    cancelEdit,
    onGridReady,
    isInputFocused,
    onCellKeyDown
  }
}
