import { useParams } from 'react-router-dom'

import { Switch } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'

import { ActionBar } from '@components/control-panel/action-bar'

import { isDynamicPage } from 'types/page'

interface LayoutSettingsProps {
  layout_type?: string
  gridLine?: boolean
  devMode: boolean
  published: boolean
  onGridLineToggle?: (gridLine: boolean) => void
  onDevModeToggle?: (newDevMode: boolean) => void
  onPublishedToggle?: (newDevMode: boolean) => void
  onSaveOrUpdate?: () => void
  previewMode: boolean
  onPreviewToggle?: () => void
  searchFilters: any
}

const LayoutSettings = ({
  layout_type,
  gridLine,
  devMode,
  published,
  onGridLineToggle,
  onDevModeToggle,
  onPublishedToggle,
  onSaveOrUpdate,
  previewMode,
  onPreviewToggle,
  searchFilters
}: LayoutSettingsProps) => {
  const { id } = useParams()

  const handleGridLineToggle = () => {
    if (onGridLineToggle) {
      onGridLineToggle(!gridLine)
    }
  }
  const handleDevModeToggle = () => {
    if (onDevModeToggle) {
      onDevModeToggle(!devMode)
    }
  }

  const handlePublishedToggle = () => {
    if (onPublishedToggle) {
      onPublishedToggle(!published)
    }
  }

  const handleSaveOrUpdate = () => {
    if (onSaveOrUpdate) {
      onSaveOrUpdate()
    }
  }

  const handlePreviewToggle = () => {
    if (onPreviewToggle) {
      onPreviewToggle()
    }
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', gap: '16px' }}>
      {!previewMode && (
        <>
          {isDynamicPage(layout_type) && (
            <Tooltip title='Toggle Grid Line'>
              <div>
                GridLine:
                <Switch
                  checked={gridLine}
                  onChange={handleGridLineToggle}
                  name='enable'
                  color='primary'
                />
              </div>
            </Tooltip>
          )}
          {isDynamicPage(layout_type) && (
            <Tooltip title='Toggle Dev Mode'>
              <div>
                Dev Mode:
                <Switch
                  checked={devMode}
                  onChange={handleDevModeToggle}
                  name='enable'
                  color='primary'
                />
              </div>
            </Tooltip>
          )}
          <Tooltip title='Toggle Status. Publishing a page will make it available to all connected businesses.'>
            <div>
              Published:
              <Switch
                checked={published}
                onChange={handlePublishedToggle}
                name='enable'
                color='primary'
              />
            </div>
          </Tooltip>

          <Button variant='contained' color='primary' onClick={handleSaveOrUpdate}>
            {id ? 'Update' : 'Save'}
          </Button>
        </>
      )}
      {previewMode && <ActionBar searchFilters={searchFilters} />}
      {isDynamicPage(layout_type) && (
        <Tooltip title='Toggle Preview Mode'>
          <div>
            <Button variant='contained' color='primary' onClick={handlePreviewToggle}>
              {previewMode ? 'Back to Edit' : 'Preview'}
            </Button>
          </div>
        </Tooltip>
      )}
    </Box>
  )
}

export default LayoutSettings
