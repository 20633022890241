import React, { useState } from 'react'

import { IHeaderParams } from 'ag-grid-community'

import { JobScheduleType } from '@layout-components/general/data-connections/queries/fetch-job-schedules'

interface CustomRustOnlyHeaderCheckboxProps extends IHeaderParams {
  selectedType: JobScheduleType | null
  setSelectedType: (type: JobScheduleType | null) => void
}

const CustomRustOnlyHeaderCheckbox = ({
  api,
  selectedType,
  setSelectedType
}: CustomRustOnlyHeaderCheckboxProps) => {
  const [checked, setChecked] = useState(false)

  const handleSelectAll = (event: any) => {
    const newCheckedState = !checked
    setChecked(newCheckedState)

    if (newCheckedState) {
      setSelectedType(selectedType)
    } else {
      setSelectedType(null)
    }

    api.getRenderedNodes().map((node) => {
      const row = node.data
      const isRustBased = row.name.endsWith('_rs')

      if (isRustBased) {
        node.setSelected(newCheckedState)
      } else {
        node.setSelected(false)
      }
      return node
    })
  }

  return (
    <input
      type='checkbox'
      checked={checked}
      onChange={handleSelectAll}
      className='ag-checkbox-input'
    />
  )
}

export default CustomRustOnlyHeaderCheckbox
