import { useMemo, useState } from 'react'

import { DndContext } from '@dnd-kit/core'

import { cn } from '@utils/style-utils'

import Button from '@components/core/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@components/core/dialog'
import { Text } from '@components/core/text'
import { Archive, ArchiveOut, Icon } from '@components/icons'
import { SortableTree } from '@components/tree/sortable-tree'
import { TreeSourceEnum } from '@components/tree/types'

import useAuth from '@hooks/useAuth'

import { useAddSubsidiary } from '@pages/property-renaming/queries/add-subsidiary'
import { useArchiveSubsidiary } from '@pages/property-renaming/queries/archive-subsidiary'
import { useReorderSubsidiaries } from '@pages/property-renaming/queries/reorder-subsidiary'
import { useUnarchiveSubsidiary } from '@pages/property-renaming/queries/unarchive-subsidiary'

export function SubsidiaryHierarchy({ nodes }: { nodes: any[] }) {
  const { user } = useAuth()

  const { mutate: reorderSubsidiaries } = useReorderSubsidiaries()
  const { mutate: archiveSubsidiary } = useArchiveSubsidiary()
  const { mutate: unarchiveSubsidiary } = useUnarchiveSubsidiary()
  const { mutate: addSubsidiary } = useAddSubsidiary()

  const [showArchiveDialog, setShowArchiveDialog] = useState(false)
  const [showUnarchiveDialog, setShowUnarchiveDialog] = useState(false)
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null)

  const parsedNodes = useMemo(() => {
    const parseNodes = (nodes: any[]): any[] => {
      return nodes?.map((node) => ({
        ...node,
        label: node.name || '',
        children: node.children ? parseNodes(node.children) : undefined
      }))
    }
    return parseNodes(nodes) || []
  }, [nodes])

  const onDragEnd = ({
    parentNodeId,
    sortedNodeIds
  }: {
    parentNodeId?: number
    sortedNodeIds?: number[]
  }) =>
    reorderSubsidiaries({
      parent_node_id: parentNodeId,
      node_ids: sortedNodeIds
    })

  const handleArchiveClick = (item: any) => {
    setSelectedItemId(item.id)
    setShowArchiveDialog(true)
  }

  const handleUnarchiveClick = (id: number) => {
    setSelectedItemId(id)
    setShowUnarchiveDialog(true)
  }

  const handleConfirmArchive = () => {
    if (selectedItemId) {
      archiveSubsidiary({ id: selectedItemId })
    }
    setShowArchiveDialog(false)
  }

  const handleConfirmUnarchive = () => {
    if (selectedItemId) {
      unarchiveSubsidiary({ id: selectedItemId })
    }
    setShowUnarchiveDialog(false)
  }

  return (
    <>
      <DndContext key={'tree'}>
        <div className='flex size-full max-w-[800px]'>
          <SortableTree
            collapsible
            indicator
            containerClassName='size-full pr-4'
            source={TreeSourceEnum.SubsidiaryDataRenaming}
            sortable={true}
            editable={true}
            showEdit={false}
            defaultItems={parsedNodes}
            showSearchInput={false}
            onNodeCreate={({ item, parentNodeId }) => {
              addSubsidiary({
                parent_node_id: parentNodeId,
                name: item.label,
                currency_code: item.currency
              })
            }}
            showAddHeadingButton={false}
            onDragEnd={onDragEnd}
            persistedCollapsedIdsKey={`data-renaming-subsidiary-${user?.business_id}`}
            editOptions={['add']}
            treeItemClasses={(item) => {
              return {
                'text-grey-light': !item.is_visible
              }
            }}
            treeItemSecondaryUtils={(item) => {
              return (
                <div className='flex items-center gap-2'>
                  <Text
                    variant='description'
                    className={cn('truncate !text-[14px]', !item.is_visible && 'text-grey-light')}
                  >
                    {item.source_system}
                  </Text>

                  {item.is_visible ? (
                    <Button
                      variant='ghost'
                      color='danger'
                      className='rounded-[4px] p-0 focus:border-hidden'
                      onClick={() => handleArchiveClick(item)}
                    >
                      <Icon icon={<Archive />} className='text-primary-darker'></Icon>
                    </Button>
                  ) : (
                    <Button
                      variant='ghost'
                      color='danger'
                      className='rounded-[4px] p-0 focus:border-hidden'
                      onClick={() => handleUnarchiveClick(item.id as number)}
                    >
                      <Icon
                        icon={<ArchiveOut />}
                        className='text-grey-light hover:text-primary-darker'
                      ></Icon>
                    </Button>
                  )}
                </div>
              )
            }}
          ></SortableTree>
        </div>
      </DndContext>

      <Dialog open={showArchiveDialog} onOpenChange={setShowArchiveDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Archive Subsidiary</DialogTitle>
            <DialogDescription>
              Are you sure you want to archive this subsidiary? This action can be undone later.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className='gap-2'>
            <Button variant='outline' onClick={() => setShowArchiveDialog(false)}>
              Cancel
            </Button>
            <Button variant='primary' onClick={handleConfirmArchive}>
              Archive
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={showUnarchiveDialog} onOpenChange={setShowUnarchiveDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Unarchive Subsidiary</DialogTitle>
            <DialogDescription>
              Are you sure you want to unarchive this subsidiary?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className='gap-2'>
            <Button variant='outline' onClick={() => setShowUnarchiveDialog(false)}>
              Cancel
            </Button>
            <Button variant='primary' onClick={handleConfirmUnarchive}>
              Unarchive
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}
