import {
  Frequency,
  YearInfo,
  YearMonthDayInfo,
  YearMonthInfo,
  YearQuarterInfo
} from '../../../types/filter'

export const ComponentRenderMap = {
  [Frequency.Yearly]: (info: YearInfo) => `FY ${info.year}`,
  [Frequency.Quarterly]: (info: YearQuarterInfo) =>
    `${info.quarter}`.toUpperCase() + `, FY ${info.year} `,
  [Frequency.Monthly]: (info: YearMonthInfo) => `${info.month}, FY ${info.year} `,
  [Frequency.Daily]: (info: YearMonthDayInfo) => `${info.month} ${info.day}, FY ${info.year} `
}

export enum QuickActionType {
  YTD = 'YTD',
  PREVIOUS_YEAR = 'PREVIOUS_YEAR',
  TTM = 'TTM',
  LAST_MONTH = 'LAST_MONTH',
  TTM_CURRENT = 'TTM_CURRENT',
  CURRENT_MONTH = 'CURRENT_MONTH'
}
