import { useQuery } from '@tanstack/react-query'

import { QUERY_STALE_TIME_DEFAULT } from '@queries/constants'
import axiosServices from '@utils/axios'
import { camelCaseKeys } from '@utils/case-conversion'

import { INotificationProfile } from '@components/control-panel/types'

import useAuth from '@hooks/useAuth'

const fetchNotificationProfiles = async (
  businessId: number | undefined
): Promise<INotificationProfile[]> => {
  if (!businessId) throw new Error('Business ID is required')

  const endpoint = `/businesses/${businessId}/notification_profiles`

  const response = await axiosServices.get(endpoint)

  return camelCaseKeys(response.data) as INotificationProfile[]
}

export const useFetchNotificationProfiles = () => {
  const { user } = useAuth()

  return useQuery({
    queryKey: ['fetch-notification-profiles', user?.business_id],
    enabled: !!user?.business_id,
    queryFn: () => fetchNotificationProfiles(user?.business_id),
    staleTime: QUERY_STALE_TIME_DEFAULT
  })
}
