import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  CellValueChangedEvent,
  ColDef,
  FilterChangedEvent,
  GetContextMenuItemsParams,
  GetDataPath,
  IRowNode,
  MenuItemDef,
  RowClassParams,
  ValueFormatterParams,
  ValueGetterParams
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { restoreExpansionWithClick } from '@utils/ag_grid/restore-expansion-with-click'
import { isBlank, isNotEqual } from '@utils/lodash'
import { cn } from '@utils/style-utils'

import AgGrid, { AUTO_FIT_TIMEOUT_MS } from '@components/ag-grid'
import { autoFitAllColumns, autoFitColumnsWithAPI } from '@components/ag-grid/utils'
import ExpandCollapseControl from '@components/control-panel/expand-collapse-control'
import { Separator } from '@components/core/separator'
import { Text } from '@components/core/text'
import { useFinancialValueFormatter } from '@components/financial/shared/hooks/useFinancialValueFormatter'

import useRouteBasedID from '@hooks/useRouteBasedID'

import { usePageDispatch, usePageSelector } from '@store/index'
import {
  selectDisplayExpansionControls,
  updateExpandLevels,
  updateMaxExpandLevel
} from '@store/slices/action-bar'
import { setDisplayExpansionControls } from '@store/slices/action-bar'
import {
  selectAdjustHierarchy,
  selectCurrentModel,
  selectHideAllFinalLabels,
  selectIsFetchModelCompleted,
  selectShowPath,
  selectShowStatisticsColumns
} from '@store/slices/property-renaming'

import { useConfirmPropertyLabel } from '../queries/confirm-property-label'
import { ModelPropertiesPayload, useFetchModelProperties } from '../queries/fetch-model-properties'
import { useFetchSubsidiariesHierarchy } from '../queries/fetch-subsidiaries'
import { AddSubsidiaryPopover } from './subsidiary/add-subsidiary-popover'
import { SubsidiaryHierarchy } from './subsidiary/subsidiary-hierarchy'

interface SubsidiaryPropertyDataGridProps {
  gridRef: React.RefObject<AgGridReact>
  id?: string
}

export function getSubsidiaryVisibilityStatus(data: any) {
  const isVisibleData = data?.standaloneProperties?.is_visible
  const isVisible =
    isVisibleData?.confirmed !== null ? isVisibleData?.confirmed : isVisibleData?.source
  return isVisible
}

const INITIAL_WIDTH = 20
const CACHE_BLOCK_SIZE = 500

export function getNameForEmptyPath(data: any) {
  return `${_.snakeCase(data?.properties?.name?.source)}_${data?.additionalfields?.source_id}`
}

export const getSubsidiaryNameWithId = (data: any) => {
  if (!data?.additionalFields?.path) {
    return getNameForEmptyPath(data)
  }

  return data?.additionalFields?.path?.split('.').at(-1)
}

export function SubsidiaryPropertyGrid({ gridRef, id }: SubsidiaryPropertyDataGridProps) {
  const [hasLtmAmountData, setHasLtmAmountData] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const adjustHierarchy = useSelector(selectAdjustHierarchy)
  const showPath = useSelector(selectShowPath)
  const routeBasedID = useRouteBasedID()
  const displayExpansionControls = usePageSelector(selectDisplayExpansionControls)
  const pageDispatch = usePageDispatch()
  const currentModel = useSelector(selectCurrentModel)
  const isFetchModelCompleted = useSelector(selectIsFetchModelCompleted)
  const { mutate } = useConfirmPropertyLabel()
  const [propertyTypes, setPropertyTypes] = useState<string[]>([])
  const [additionalFields, setAdditionalFields] = useState<
    Array<{ field: string; headerName: string }>
  >([])
  const [hasObjectStats, setHasObjectStats] = useState(false)
  const showStatistics = useSelector(selectShowStatisticsColumns) ?? false

  const { data: subsidiariesHierarchy, isRefetching } = useFetchSubsidiariesHierarchy()
  const { data: modelProperties, isLoading: isLoadingModelProperties } = useFetchModelProperties(
    'Subsidiary',
    showStatistics
  )

  useEffect(() => {
    if (!displayExpansionControls) {
      pageDispatch(setDisplayExpansionControls({ displayExpansionControls: true }))
    }
  }, [displayExpansionControls, pageDispatch])

  useEffect(() => {
    if (modelProperties) {
      setPropertyTypes(modelProperties.propertyTypes)
      setPropertyTypes(modelProperties.propertyTypes)
      setAdditionalFields(modelProperties.additionalFields)
      setHasObjectStats(modelProperties.hasObjectStats)

      // Check if ltm_amount is present in the data
      const hasAmountData = modelProperties.records.some(
        (record: ModelPropertiesPayload) => record.ltm_amount !== undefined
      )
      setHasLtmAmountData(hasAmountData)

      if (gridRef.current?.api) {
        autoFitColumnsWithAPI(gridRef.current.api)
      }
    }
  }, [modelProperties, gridRef])

  const hideAllFinalLabels = useSelector(selectHideAllFinalLabels)
  const valueFormatter = useFinancialValueFormatter()

  const getDataPath = useCallback<GetDataPath>((data) => {
    if (!data?.additionalFields?.path) {
      return [getNameForEmptyPath(data)]
    }

    return data?.additionalFields?.path?.split('.') || []
  }, [])

  const flattenedSubsidiaries = useMemo(() => {
    const flattenSubsidiaries = (subsidiaries: any[]): any[] => {
      return (
        subsidiaries?.reduce((acc: any[], curr: any) => {
          acc.push(curr)
          if (curr.children?.length) {
            acc.push(...flattenSubsidiaries(curr.children))
          }
          return acc
        }, []) || []
      )
    }

    return flattenSubsidiaries(subsidiariesHierarchy || [])
  }, [subsidiariesHierarchy])

  const getSubsidiaryHierarchyFromNameID = useCallback(
    (nameId: string) => {
      const subsidiary = flattenedSubsidiaries.find((subsidiary: any) => subsidiary.id === nameId)
      return subsidiary
    },
    [flattenedSubsidiaries]
  )

  useEffect(() => {
    if (isRefetching) {
      gridRef.current?.api?.refreshServerSide()
    }
  }, [isRefetching, gridRef])

  const isObjectStatsBasedOnGeneralLedger = useMemo(() => {
    return hasObjectStats && isNotEqual(currentModel, 'Customer')
  }, [hasObjectStats, currentModel])

  const transactionStatsColDefs = useMemo(() => {
    if (hasObjectStats && showStatistics) {
      const columns = [
        {
          colId: '_spacer',
          minWidth: 1,
          flex: 1,
          suppressMenu: true,
          suppressMovable: true,
          suppressNavigable: true,
          suppressColumnsToolPanel: true,
          suppressSizeToFit: true,
          suppressAutoSize: true,
          suppressPaste: true,
          suppressFiltersToolPanel: true,
          suppressFillHandle: true,
          resizable: false,
          filter: false
        },
        {
          field: 'ltm_count',
          colId: 'object_stats.ltm_count',
          headerName: isObjectStatsBasedOnGeneralLedger ? 'LTM GL Txn Count' : 'Order Count',
          filter: 'agNumberColumnFilter',
          filterParams: {
            defaultOption: 'greaterThan'
          },
          valueFormatter: valueFormatter,
          useValueFormatterForExport: false,
          pinned: 'right' as const
        }
      ]

      // Include 'ltm_amount' column only if data includes 'ltm_amount'
      if (hasLtmAmountData) {
        columns.push({
          field: 'ltm_amount',
          colId: 'object_stats.ltm_amount',
          headerName: isObjectStatsBasedOnGeneralLedger ? 'LTM GL Txn Total' : 'Total',
          filter: 'agNumberColumnFilter',
          filterParams: {
            defaultOption: 'greaterThan'
          },
          valueFormatter: valueFormatter,
          useValueFormatterForExport: false,
          pinned: 'right' as const
        })
      }

      return columns
    } else {
      return []
    }
  }, [
    hasObjectStats,
    showStatistics,
    isObjectStatsBasedOnGeneralLedger,
    valueFormatter,
    hasLtmAmountData
  ])

  const columnDefs = useMemo(() => {
    const propertiesColDefs = propertyTypes.map((property) => [
      {
        field: `properties.${property}.source`,
        // IMPORTANT: colId is used by ag grid filterModel so this should be matched to the field name that is used to filter the DB query
        // Additional we are also using this to determine the edited property
        colId: `${property}_source.label`,
        headerName: `${_.startCase(property)} Source`
      },
      {
        field: `properties.${property}.confirmed`,
        // IMPORTANT: colId is used by ag grid filterModel so this should be matched to the field name that is used to filter the DB query
        // Additional we are also using this to determine the edited property
        colId: `${property}_confirmed.label`,
        headerName: `${_.startCase(property)} Confirmed`,
        editable: true,
        cellClassRules: {
          'editable-cell': () => true
        }
      },
      {
        headerName: `${_.startCase(property)} Final`,
        colId: `${property}_coalesce.label`,
        showRowGroup: property === 'name',
        cellRenderer: property === 'name' ? 'agGroupCellRenderer' : undefined,
        minWidth: property === 'name' ? 300 : undefined,
        cellRendererParams: {
          suppressCount: true
        },
        valueGetter: (params: ValueGetterParams) => {
          if (!params.data) return ''

          if (params.data.additionalFields?.path) {
            return getSubsidiaryNameWithId(params.data)
          }

          const name =
            params.data.properties[property]?.confirmed?.trim() ||
            params.data.properties[property]?.source

          return `${name}_${params.data.additionalFields?.source_id}`
        },
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.data) return ''

          return (
            params.data.properties[property]?.confirmed?.trim() ||
            params.data.properties[property]?.source
          )
        },
        hide: hideAllFinalLabels
      }
    ])

    const additionalFieldsFiltered = showPath
      ? additionalFields
      : additionalFields.filter((item) => item.field !== 'path')

    const additionalFieldsColDefs = additionalFieldsFiltered.map(
      (item: { field: string; headerName: string }) => {
        return {
          field: `additionalFields.${item.field}`,
          colId: item.field,
          headerName: item.headerName,
          minWidth: item.field === 'path' ? 200 : undefined
        }
      }
    )

    return _.compact([
      { field: 'id', hide: true },
      {
        field: 'source_system',
        colId: 'connected_source_systems.name',
        headerName: 'Source System',
        filter: 'agTextColumnFilter',
        filterParams: {
          buttons: ['clear']
        }
      },
      ..._.flatten(additionalFieldsColDefs),
      ..._.flatten(propertiesColDefs),
      {
        field: 'standaloneProperties.is_visible.confirmed',
        colId: 'is_visible_final',
        headerName: 'Is Visible Final',
        cellRenderer: 'agCheckboxCellRenderer',
        valueGetter: (params: ValueGetterParams) => {
          if (!params.data) return ''

          const { confirmed, source } = params.data.standaloneProperties.is_visible
          return confirmed !== undefined && confirmed !== null ? confirmed : source
        },
        hide: hideAllFinalLabels
      },
      {
        field: 'standaloneProperties.currency_code.source',
        colId: 'currency_code_source',
        headerName: 'Currency Source'
      },
      {
        field: 'standaloneProperties.currency_code.confirmed',
        colId: 'currency_code_confirmed',
        headerName: 'Currency Confirmed',
        cellEditor: 'agSelectCellEditor',
        editable: true,
        cellEditorParams: { values: ['USD', 'EUR', 'GBP', 'CAD', 'BRL'] }
      },
      {
        field: 'standaloneProperties.currency_code.confirmed',
        headerName: `Currency Final`,
        colId: `currency_code_coalesce.label`,
        valueGetter: (params: ValueGetterParams) => {
          if (!params.data) return ''

          return (
            params.data.standaloneProperties.currency_code.confirmed?.trim() ||
            params.data.standaloneProperties.currency_code.source
          )
        },
        hide: hideAllFinalLabels
      },
      {
        field: 'standaloneProperties.include_in_consolidation.confirmed',
        colId: 'include_in_consolidation_source',
        headerName: 'Include in Consolidation Source'
      },
      {
        field: 'standaloneProperties.include_in_consolidation.confirmed',
        colId: 'include_in_consolidation_confirmed',
        headerName: 'Include in Consolidation Confirmed',
        cellEditor: 'agCheckboxCellEditor',
        editable: true
      },
      ...transactionStatsColDefs
    ])
  }, [additionalFields, hideAllFinalLabels, propertyTypes, transactionStatsColDefs, showPath])

  const onFilterChanged = useCallback(
    (event: FilterChangedEvent) => {
      if (_.eq(event.source, 'api')) return

      const allFilters = event.api.getFilterModel()
      const isAnyFilterActive = Object.keys(allFilters).length > 0

      restoreExpansionWithClick(pageDispatch, gridRef)

      if (isAnyFilterActive) {
        let mostExpandedLevel = 0
        let maxLevel = 0

        event.api.forEachNode((node: IRowNode) => {
          if (node.group) {
            const childrenAfterFilter = node.childrenAfterFilter || []
            const filterEvent = { type: 'filter' } as MouseEvent

            if (childrenAfterFilter.length > 0) {
              node.setExpanded(true, filterEvent)
              mostExpandedLevel = Math.max(mostExpandedLevel, node.level + 1)
              maxLevel = Math.max(maxLevel, node.level + 1)
            } else {
              node.setExpanded(false, filterEvent)
            }
          }
        })

        mostExpandedLevel = Math.max(0, mostExpandedLevel)
        maxLevel = Math.max(0, maxLevel)

        pageDispatch(updateExpandLevels({ mostExpandedLevel }))
        pageDispatch(updateMaxExpandLevel({ maxExpandLevel: maxLevel }))
      }
      autoFitAllColumns(event)
    },
    [pageDispatch, gridRef]
  )

  // Helper function to get the filterType based on the colDef.filter
  const getFilterType = (colDef: ColDef): string => {
    const filter = colDef.filter
    if (typeof filter === 'string') {
      // Map ag-Grid filter names to filter types
      switch (filter) {
        case 'agNumberColumnFilter':
          return 'number'
        case 'agDateColumnFilter':
          return 'date'
        case 'agSetColumnFilter':
          return 'set'
        case 'agTextColumnFilter':
        default:
          return 'text'
      }
    } else {
      return 'text'
    }
  }

  const getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
    const defaultItems: (string | MenuItemDef)[] = params.defaultItems || []

    const result: (string | MenuItemDef)[] = [...defaultItems]

    if (params.column && params.column.getColDef().filter) {
      const column = params.column

      result.push('separator')

      const filterType = getFilterType(column.getColDef())

      const filterBlanksItem: MenuItemDef = {
        name: 'Filter By Null',
        action: () => {
          params.api.setFilterModel({
            ...params.api.getFilterModel(),
            [column.getColId()]: {
              filterType: filterType,
              type: 'blank'
            }
          })
          params.api.onFilterChanged()
        }
      }

      const filterNonBlanksItem: MenuItemDef = {
        name: 'Filter By Non-Null',
        action: () => {
          params.api.setFilterModel({
            ...params.api.getFilterModel(),
            [column.getColId()]: {
              filterType: filterType,
              type: 'notBlank'
            }
          })
          params.api.onFilterChanged()
        }
      }
      result.push(filterBlanksItem)
      result.push(filterNonBlanksItem)
    }

    return result
  }

  if (!isFetchModelCompleted) {
    return <></>
  }

  if (isBlank(currentModel)) {
    return (
      <Text variant='h5' weight='bold' className='pt-4'>
        Please Select a Model From The Side Menu
      </Text>
    )
  }

  return (
    <div className='flex size-full flex-col'>
      <div className={cn('flex items-center gap-2 transition-all duration-1000')}>
        <Text variant='h5' className='mb-3 mt-2'>
          Subsidiary
        </Text>
      </div>
      <Separator className='my-2' />
      {adjustHierarchy && (
        <div className='flex items-center'>
          <Separator orientation='vertical' />
          <AddSubsidiaryPopover />
        </div>
      )}
      <div className='flex size-full flex-1' ref={wrapperRef}>
        {adjustHierarchy && (
          <div className='flex flex-1'>
            <SubsidiaryHierarchy nodes={subsidiariesHierarchy as any[]} />
          </div>
        )}
        {!adjustHierarchy && (
          <div className='flex-[2]'>
            <ExpandCollapseControl gridRef={gridRef} type={routeBasedID} />
            <AgGrid
              ref={gridRef}
              style={{
                height: `calc(100% - 60px)`,
                width: '100%'
              }}
              suppressServerSideFullWidthLoadingRow={true}
              columnDefs={columnDefs}
              defaultColDef={{
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                filterParams: {
                  buttons: ['clear']
                },
                initialWidth: INITIAL_WIDTH
              }}
              autoSizeStrategy={{ type: 'fitCellContents' }}
              onCellValueChanged={(event: CellValueChangedEvent) => {
                const RevertEventSource = 'valueRevert'
                if (event.source === RevertEventSource) return // Prevents Sending Update Request When the Cell Value is Reverted Back to Original Value on Update Error

                mutate({
                  model: currentModel,
                  property: event.colDef
                    .colId!.replace('.label', '')
                    .replace('_confirmed', '') as string,
                  recordId: event.data.id,
                  confirmedLabel: event.newValue,
                  onError: () => {
                    event.node.setDataValue(
                      event.column.getColId(),
                      event.oldValue || '',
                      RevertEventSource
                    )
                    event.api.refreshCells({
                      rowNodes: [event.node],
                      force: true
                    })
                  }
                })
              }}
              getRowStyle={(row: RowClassParams) => {
                const isVisible = getSubsidiaryVisibilityStatus(row.data)
                return !isVisible ? { color: '#c6c6c6', fontStyle: 'italic' } : undefined
              }}
              groupDisplayType={'custom'}
              treeData={true}
              getDataPath={getDataPath}
              onFirstDataRendered={(params) => {
                params.api.autoSizeAllColumns()
              }}
              rowData={modelProperties?.records}
              groupDefaultExpanded={-1}
              initialMostExpandedLevel={-1}
              enableFillHandle={true}
              loading={isLoadingModelProperties}
              loadingOverlayComponentParams={{
                showHeader: true,
                topOffset: '48px'
              }}
              onRowDataUpdated={(params) => {
                setTimeout(() => {
                  autoFitColumnsWithAPI(params.api)
                }, AUTO_FIT_TIMEOUT_MS)

                if (!displayExpansionControls) {
                  pageDispatch(setDisplayExpansionControls({ displayExpansionControls: true }))
                }
              }}
              postSortRows={(params) => {
                return params.nodes.sort((a, b) => {
                  const aIsVisible = getSubsidiaryVisibilityStatus(a.data)
                  const bIsVisible = getSubsidiaryVisibilityStatus(b.data)

                  if (aIsVisible !== bIsVisible) {
                    return bIsVisible ? 1 : -1
                  }

                  const aSubsidiary = getSubsidiaryHierarchyFromNameID(a.data.id)
                  const bSubsidiary = getSubsidiaryHierarchyFromNameID(b.data.id)

                  // Then sort active nodes by whether they have children
                  if (aIsVisible && bIsVisible) {
                    const aHasChildren = a?.childrenAfterFilter?.length! > 0
                    const bHasChildren = b?.childrenAfterFilter?.length! > 0
                    if (aHasChildren !== bHasChildren) {
                      return bHasChildren ? 1 : -1
                    }
                  }

                  // If there is no children, then sort by sort_order
                  // we take the sort_order from the subsidiary hierarchy
                  if (aSubsidiary?.sort_order && bSubsidiary?.sort_order) {
                    return aSubsidiary?.sort_order - bSubsidiary?.sort_order
                  }

                  return 0
                })
              }}
              onFilterChanged={onFilterChanged}
              suppressAutoFitColumns
              id={id}
              cacheBlockSize={CACHE_BLOCK_SIZE}
              cacheOverflowSize={5}
              maxConcurrentDatasourceRequests={1}
              infiniteInitialRowCount={CACHE_BLOCK_SIZE}
              maxBlocksInCache={10}
              getContextMenuItems={getContextMenuItems}
            />
          </div>
        )}
      </div>
    </div>
  )
}
