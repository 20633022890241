import { ColDef } from 'ag-grid-community'

export enum PivotComparatorType {
  FIXED = 'Fixed',
  REST = 'Rest'
}
export const isPivotComparatorTypeFixed = (type: PivotComparatorType) =>
  _.isEqual(type, PivotComparatorType.FIXED)
export const isPivotComparatorTypeRest = (type: PivotComparatorType) =>
  _.isEqual(type, PivotComparatorType.REST)

export enum PivotComparatorOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}
export const isPivotComparatorOrderAsc = (order?: PivotComparatorOrder) =>
  _.isEqual(order, PivotComparatorOrder.ASC)
export const isPivotComparatorOrderDesc = (order?: PivotComparatorOrder) =>
  _.isEqual(order, PivotComparatorOrder.DESC)

export interface PivotComparatorConfigRow {
  type: PivotComparatorType
  order?: PivotComparatorOrder
  value?: string
}

export enum NumberFormat {
  NONE = 'none',
  AMOUNT = 'amount',
  PERCENTAGE = 'percentage'
}

export enum RoundingType {
  NONE = 'none',
  EXACT = 'exact',
  INTEGER = 'integer',
  ONE_DECIMAL = 'one_decimal',
  TWO_DECIMAL = 'two_decimal',
  THOUSANDS = 'thousands',
  MILLIONS = 'millions'
}

export interface ITableColumn extends ColDef {
  customData?: Partial<{
    heatmap: boolean
    pivotComparatorConfig: PivotComparatorConfigRow[]
    broadcastValue: string
    numberFormat: NumberFormat
    rounding: RoundingType
    noCommaSeparator: boolean
    formula: boolean
    showInGroup: boolean
  }>
  field: string
}

export const isCellDataTypeNumber = (type?: string | boolean) => _.isEqual(type, 'number')
export const isCellDataTypeText = (type?: string | boolean) => _.isEqual(type, 'text')
export const isCellDataTypeDate = (type?: string | boolean) =>
  _.includes(['date', 'dateString'], type)
export const isCellDataTypeBoolean = (type?: string | boolean) => _.isEqual(type, 'boolean')

export enum FilterType {
  SELECT = 'SELECT'
}

export interface ISelectFilterConfigOption {
  id: string
  value: string | undefined
  label: string
}

export type IFilter = {
  filterType: FilterType.SELECT
  defaultValue: ISelectFilterConfigOption['value']
  options: ISelectFilterConfigOption[]
}

export enum PivotRowTotalsType {
  NONE = 'none',
  BEFORE = 'before',
  AFTER = 'after'
}

export enum GrandTotalRowType {
  BOTTOM = 'bottom',
  TOP = 'top',
  PINNED = 'pinned'
}
export const isGrandTotalRowPinned = (type?: GrandTotalRowType) =>
  _.isEqual(type, GrandTotalRowType.PINNED)
export const isGrandTotalRowBottom = (type?: GrandTotalRowType) =>
  _.isEqual(type, GrandTotalRowType.BOTTOM)
export const isGrandTotalRowTop = (type?: GrandTotalRowType) =>
  _.isEqual(type, GrandTotalRowType.TOP)

export enum GroupTotalRowType {
  BOTTOM = 'bottom',
  TOP = 'top'
}
export const isGroupTotalRowBottom = (type?: GroupTotalRowType) =>
  _.isEqual(type, GroupTotalRowType.BOTTOM)
export const isGroupTotalRowTop = (type?: GroupTotalRowType) =>
  _.isEqual(type, GroupTotalRowType.TOP)

export type GrandTotalSettings = Partial<{
  pivotRowTotals: PivotRowTotalsType
  showTotalFooter: boolean
  // @deprecated -- use grandTotalRow instead
  pinTotalFooter: boolean
  grandTotalRow: GrandTotalRowType
  groupTotalRow: GroupTotalRowType
}>

export type TableSettings = GrandTotalSettings & Partial<{ groupDefaultExpanded: number }>

export type IExternalFilters = Record<string, IFilter>

export interface ITableConfig {
  pivotMode: boolean
  columns: ITableColumn[]
  externalFilters: IExternalFilters
  tableSettings: TableSettings
  serversideTransformation?: boolean
}

export enum NoneType {
  NONE = 'none'
}

export enum ColumnPinType {
  LEFT = 'left',
  RIGHT = 'right'
}

export const isColumnPinTypeLeft = (type?: ColumnPinType | ColDef['pinned']) =>
  _.isEqual(type, ColumnPinType.LEFT)
export const isColumnPinTypeRight = (type?: ColumnPinType | ColDef['pinned']) =>
  _.isEqual(type, ColumnPinType.RIGHT)

export enum AggFuncStringType {
  SUM = 'sum',
  AVG = 'avg',
  MIN = 'min',
  MAX = 'max',
  COUNT = 'count',
  FIRST = 'first',
  LAST = 'last'
}
