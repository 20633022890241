import { useCallback } from 'react'

import { ValueFormatterParams } from 'ag-grid-community'

import { FinancialHierarchyNode } from '@components/financial/types'
import { financialValueFormatter } from '@components/financial/utils/formatting-utils'

import { isCellValueVisible } from 'utils/ag_grid/is-cell-value-visible'

export const useFinancialValueFormatter = () => {
  return useCallback((params: ValueFormatterParams) => {
    const data = params.node?.data as FinancialHierarchyNode
    const _isCellVisible = isCellValueVisible(params.node!)

    const isPercentageColumn = params.colDef.headerName?.endsWith('%')

    return financialValueFormatter(
      params.value,
      data,
      undefined,
      _isCellVisible,
      isPercentageColumn
    )
  }, [])
}
