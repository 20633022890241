import Button from '@components/core/button'
import { ChartGridVisibility, ChartGridVisibilityOff, Icon } from '@components/icons'

import { usePageDispatch, usePageSelector } from '@store/index'

import { Separator } from 'components/core/separator'
import { Text } from 'components/core/text'
import Tooltip from 'components/core/tooltip'
import { selectShowChartGridLines, toggleShowChartGridLines } from 'store/slices/action-bar'

import { BaseFilterProps, FilterDisplayModeEnum, isFilterDisplayModeList } from './types'

export default function ChartGridToggle({
  filter,
  filterDisplayMode = FilterDisplayModeEnum.default
}: BaseFilterProps) {
  const showChartGridLines = usePageSelector(selectShowChartGridLines)
  const pageDispatch = usePageDispatch()
  const label = _.get(filter, 'label', 'Toggle Grid Lines')

  if (isFilterDisplayModeList(filterDisplayMode)) {
    return (
      <>
        <div className='px-4'>
          <div className='flex items-center justify-between'>
            <Text variant='description'>{label}</Text>
          </div>
          <Separator />

          <div className='mt-2 flex items-center justify-between'>
            <Text variant='description'>Show Grid Lines</Text>
            <Button
              variant='ghost'
              size='action-bar'
              onClick={() => pageDispatch(toggleShowChartGridLines({}))}
              className='m-1'
            >
              <Icon
                icon={showChartGridLines ? <ChartGridVisibility /> : <ChartGridVisibilityOff />}
              />
            </Button>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <Tooltip title={label}>
        <Button
          variant='ghost'
          size='action-bar'
          onClick={() => pageDispatch(toggleShowChartGridLines({}))}
          className='m-1'
        >
          <Icon icon={showChartGridLines ? <ChartGridVisibility /> : <ChartGridVisibilityOff />} />
        </Button>
      </Tooltip>
    )
  }
}

ChartGridToggle.approximateWidth = 40
