import { useQuery } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import { User } from 'types/user'
import axiosServices from 'utils/axios'

const fetchUsers = async (businessId?: number): Promise<User[]> => {
  const response = await axiosServices.get(`/businesses/${businessId}/users`)

  return response.data
}

export const useFetchUsers = () => {
  const { user } = useAuth()

  return useQuery({
    queryKey: ['fetch-users', user?.business_id],
    queryFn: () => fetchUsers(user?.business_id),
    retry: 2
  })
}
