import { PayloadAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'

import { getInitialDateFilterInfo, getRangeInfoFromDateFilterInfo } from '@utils/date-filter-utils'
import { getFrequencyAndDateRangeForQuickActions, getMonthFromIndex } from '@utils/date-utils'

import { QuickActionType } from '@components/control-panel/calendar-filter-popover/types'
import {
  DateFrequency,
  DateFrequencyEnum,
  TimePeriodEnum,
  TimePeriodType
} from '@components/control-panel/types'

import { pageSettingsLoaded } from '@store/actions/page-settings'
import { WithID, createPageAction } from '@store/page-util'

import { endOfMonth, format, getYear, startOfMonth, subMonths } from 'date-fns'
import { HANDLE_BUSINESS_SELECTOR_CHANGE, LOGIN } from 'store/reducers/actions'
import { AuthActionProps } from 'types/auth'
import { DateFilterInfo, DateRangeType, ExpandedDateFilterInfo, Frequency } from 'types/filter'
import { FILTER_IDENTIFIER } from 'types/page-filters'

import { rehydrateStore } from '../actions/rehydrate-store'
import { RootState } from '../index'

interface DateTimeFilterState {
  dateFrequency: DateFrequency
  timePeriod: TimePeriodType
  dateFilter: {
    active: true
    filterInfo: DateFilterInfo
  }
  expandedDateFilterInfo: ExpandedDateFilterInfo
}

const dateTimeFiltersAdapter = createEntityAdapter<WithID<DateTimeFilterState>>()

const now = new Date()
const priorMonth = subMonths(now, 1)
const trailingTwelveMonths = subMonths(priorMonth, 12)

const initialDateFilterInfo: DateFilterInfo = {
  frequency: Frequency.Monthly,
  fiscalYearStart: undefined,
  rangeType: DateRangeType.Range,
  startDate: {
    year: getYear(trailingTwelveMonths),
    month: getMonthFromIndex(trailingTwelveMonths.getMonth())
  },
  endDate: {
    year: getYear(priorMonth),
    month: getMonthFromIndex(priorMonth.getMonth())
  }
}

type DefaultDateFilterConfig = {
  base: DateTimeFilterState
  pages: Record<string, DateTimeFilterState>
}

const initialState = dateTimeFiltersAdapter.getInitialState<{
  default: DefaultDateFilterConfig
}>({
  default: {
    base: {
      dateFrequency: DateFrequencyEnum.M,
      timePeriod: TimePeriodEnum.Today,
      dateFilter: { active: true, filterInfo: initialDateFilterInfo },
      expandedDateFilterInfo: {
        rangeType: initialDateFilterInfo.rangeType,
        startDate: format(startOfMonth(trailingTwelveMonths), 'yyyy-MM-dd'),
        endDate: format(endOfMonth(priorMonth), 'yyyy-MM-dd'),
        frequency: initialDateFilterInfo.frequency,
        originalInfo: initialDateFilterInfo
      }
    },
    pages: {}
  }
})

const getDefaultDateFilter = (defaultConfig: DefaultDateFilterConfig, id: string) => {
  const defaultObject = defaultConfig.pages[id]
  return defaultObject || defaultConfig.base
}

const findOrCreateEntity = (state: typeof initialState, id: string) => {
  let entity = state.entities[id]
  if (!entity) {
    dateTimeFiltersAdapter.addOne(
      state,
      _.assign({ id }, _.cloneDeep(getDefaultDateFilter(state.default, id)))
    )
    entity = state.entities[id]
  }
  return entity
}

const dateTimeFilterSlice = createSlice({
  name: '@DATE_TIME_FILTER',
  initialState,
  reducers: {
    updateDateFilter: (state, action: PayloadAction<WithID<DateFilterInfo>>) => {
      const { id, ...dateFilter } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.dateFilter = { active: true, filterInfo: dateFilter }
      entity.expandedDateFilterInfo = getRangeInfoFromDateFilterInfo(dateFilter)
    },
    updateTimePeriod: (state, action: PayloadAction<WithID<{ timePeriod: TimePeriodType }>>) => {
      const { id, timePeriod } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.timePeriod = timePeriod
    },
    updateDateFrequency: (
      state,
      action: PayloadAction<WithID<{ dateFrequency: DateFrequency }>>
    ) => {
      const { id, dateFrequency } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.dateFrequency = dateFrequency
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrateStore, (_state, action) => {
      return { ...initialState, ...action.payload.dateTimeFilter }
    })
    builder.addCase(pageSettingsLoaded, (state, action) => {
      const defaultEntities = state.default.pages
      const pageSettings = action.payload?.pageSettings
      const user = action.payload?.user
      if (!pageSettings || !user) return

      const fiscalYearStart = user.business_fiscal_year_start

      _.forEach(pageSettings, (value, entityID) => {
        const dateFilterSettings = _.find(
          _.values(value),
          (filter) => filter.identifier === FILTER_IDENTIFIER.DATE_FILTER
        )
        if (!dateFilterSettings) return
        const quickActionType = dateFilterSettings.defaultValue as QuickActionType // default quick action value in the page search settings
        const data = getFrequencyAndDateRangeForQuickActions({
          quickActionType,
          user
        })
        if (!data) return

        const dateFilter: { active: true; filterInfo: DateFilterInfo } = {
          active: true,
          filterInfo: {
            frequency: data.frequency,
            fiscalYearStart,
            rangeType: DateRangeType.Range,
            startDate: data.dateRange.startDate,
            endDate: data.dateRange.endDate
          }
        }
        const expandedDateFilterInfo = getRangeInfoFromDateFilterInfo(dateFilter.filterInfo)

        defaultEntities[entityID] = {
          dateFrequency: DateFrequencyEnum.M,
          timePeriod: TimePeriodEnum.Today,
          dateFilter,
          expandedDateFilterInfo
        }
      })
    })
    builder.addMatcher(
      (action) => [LOGIN, HANDLE_BUSINESS_SELECTOR_CHANGE].includes(action.type),
      (state, action: AuthActionProps) => {
        const defaultValues = state.default.base
        defaultValues.dateFilter = {
          active: true,
          filterInfo: getInitialDateFilterInfo(action.payload?.user?.business_fiscal_year_start)
        }
        defaultValues.expandedDateFilterInfo = getRangeInfoFromDateFilterInfo(
          defaultValues.dateFilter.filterInfo
        )
      }
    )
  }
})

// Actions
export const updateDateFilter = createPageAction(dateTimeFilterSlice.actions.updateDateFilter)
export const updateTimePeriod = createPageAction(dateTimeFilterSlice.actions.updateTimePeriod)
export const updateDateFrequency = createPageAction(dateTimeFilterSlice.actions.updateDateFrequency)

// Reducer
export const dateTimeFilterReducer = dateTimeFilterSlice.reducer

// Selectors
const getDateTimeFilterState = (id: string) => (state: RootState) =>
  _.get(
    state,
    ['dateTimeFilter', 'entities', id],
    getDefaultDateFilter(_.get(state, ['dateTimeFilter', 'default']), id)
  )

export const selectDateFilter = (id: string) =>
  createSelector(getDateTimeFilterState(id), (dateTimeFilter) =>
    _.get(dateTimeFilter, ['dateFilter'])
  )

export const selectExpandedDateFilterInfo = (id: string) =>
  createSelector(getDateTimeFilterState(id), (dateTimeFilter) =>
    _.get(dateTimeFilter, ['expandedDateFilterInfo'])
  )

export const selectDateFrequency = (id: string) =>
  createSelector(getDateTimeFilterState(id), (dateTimeFilter) =>
    _.get(dateTimeFilter, ['dateFrequency'])
  )

export const selectTimePeriod = (id: string) =>
  createSelector(getDateTimeFilterState(id), (dateTimeFilter) =>
    _.get(dateTimeFilter, ['timePeriod'])
  )
