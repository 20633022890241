import React, { useCallback, useEffect, useMemo, useState } from 'react'

import InfoIcon from '@mui/icons-material/Info'
import { Link } from '@mui/material'

import { snakeCaseKeys } from '@utils/case-conversion'
import { awsCronExpressionValidator, humanizeCron } from '@utils/cron-utils'
import { enumToOptions } from '@utils/obj-utils'
import { cn } from '@utils/style-utils'

import { Text } from '@core/text'

import { extractComponentIds } from '@components/control-panel/data-exporter'
import { useFetchNotificationProfiles } from '@components/control-panel/report-scheduler/queries/notification-profiles/fetch-notification-profiles'
import { Button } from '@components/core/button'
import { Input } from '@components/core/input'
import { FinancialStatementView } from '@components/financial/types'
import { Select } from '@components/form/select'

import { useCreateJobSchedule } from '@layout-components/general/data-connections/queries/create-job-schedule'

import useAuth from '@hooks/useAuth'

import { usePageSelector, useSelector } from '@store/index'
import {
  selectCurrencyId,
  selectFinancialModelId,
  selectFinancialStatementViewType,
  selectLanguageId,
  selectViewMoreItems
} from '@store/slices/action-bar'
import { selectFilterModels, selectSortModels } from '@store/slices/component/table-parameters'
import { selectExpandedDateFilterInfo } from '@store/slices/date-time-filter'
import { selectDimensions } from '@store/slices/dimension'
import { selectCurrentPage } from '@store/slices/navigation'

import { useParameterizedFilters } from '@pages/component-management/viewer'

import {
  IJobSchedule,
  JobScheduleType,
  ScheduleStateEnum,
  isJobScheduleEmailReporting,
  isJobScheduleRust
} from '../../queries/fetch-job-schedules'
import { useUpdateJobSchedule } from '../../queries/update-job-schedule'

interface UpdateScheduleFormProps {
  data?: IJobSchedule
  onSuccess: () => void
  onCancel: () => void
  formType: 'add' | 'update'
}

export enum FormType {
  Add = 'add',
  Update = 'update'
}

export const UpdateScheduleForm: React.FC<UpdateScheduleFormProps> = ({
  onSuccess,
  onCancel,
  data: inputData,
  formType
}) => {
  const { user } = useAuth()
  const defaultGeneratedScheduleType = JobScheduleType.EmailReporting // TODO: Update this to support other types in future
  const currentPage = useSelector(selectCurrentPage)

  // TODO: CHAR-4024: Get these data dynamically
  const expandedDateFilterInfo = usePageSelector(selectExpandedDateFilterInfo)
  const appliedDimensions = usePageSelector(selectDimensions)
  const financialStatementView =
    usePageSelector(selectFinancialStatementViewType) || FinancialStatementView.ACTUAL
  const viewMoreItems = usePageSelector(selectViewMoreItems)
  const languageId = usePageSelector(selectLanguageId)
  const currencyId = usePageSelector(selectCurrencyId)
  const { resolvedParameterizedFilters: parameterizedFilters } = useParameterizedFilters({
    firstFetch: false
  })
  const selectedModelId = usePageSelector(selectFinancialModelId)

  const layoutStructure = currentPage?.pageSettings?.layout_structure
  const componentIds = extractComponentIds(layoutStructure)
  const filterModels = useSelector(selectFilterModels(componentIds))
  const sortModels = useSelector(selectSortModels(componentIds))

  const [scheduleData, setScheduleData] = useState<IJobSchedule | null>(inputData || null)

  const cronPart = scheduleData?.scheduleExpression.match(/cron\((.*)\)/)?.[1]
  const [cronVal, setCronVal] = useState<string>(cronPart || '')
  const [error, setError] = useState<string>('')
  const [isValid, setIsValid] = useState<boolean>(true)

  const { data: notificationProfiles } = useFetchNotificationProfiles()

  const { mutate: updateJobSchedule } = useUpdateJobSchedule({ onSuccess })
  const { mutate: createJobSchedule } = useCreateJobSchedule({ onSuccess })

  const scheduleTypeIsRust = isJobScheduleRust(scheduleData?.scheduleType)
  const scheduleEmailReporting = isJobScheduleEmailReporting(scheduleData?.scheduleType)

  const generateScheduleName = useCallback(
    (friendlyName: string) => {
      const internalScheduleName = _.snakeCase(friendlyName)
      return `email_report_${_.snakeCase(user?.business_friendly_name)}_${internalScheduleName}`
    },
    [user?.business_friendly_name]
  )

  const validateForm = useCallback(() => {
    let valid = true

    try {
      awsCronExpressionValidator(cronVal)
    } catch (err) {
      valid = false
    }

    if (formType === FormType.Add) {
      if (!scheduleData?.friendlyName || scheduleData.friendlyName.trim() === '') {
        valid = false
      }
    }

    if (scheduleEmailReporting && !scheduleData?.notificationProfileId) {
      valid = false
    }

    setIsValid(valid)
  }, [cronVal, formType, scheduleData, scheduleEmailReporting])

  useEffect(() => {
    if (formType === FormType.Add && !scheduleData) {
      setScheduleData({
        name: generateScheduleName(''),
        scheduleExpression: `cron(${cronVal})`,
        scheduleExpressionTimezone: user?.business_local_timezone || 'UTC',
        parallelism: undefined,
        replicationJobIdFromTargetInput: undefined,
        state: ScheduleStateEnum.Disabled,
        scheduleType: defaultGeneratedScheduleType,
        friendlyName: '',
        id: 0,
        notificationProfileId: ''
      })
    }
    validateForm()
  }, [
    formType,
    cronVal,
    defaultGeneratedScheduleType,
    user?.business_local_timezone,
    generateScheduleName,
    setScheduleData,
    scheduleData,
    validateForm
  ])

  useEffect(() => {
    if (scheduleData?.friendlyName && formType == FormType.Add) {
      const newName = generateScheduleName(scheduleData.friendlyName)
      setScheduleData((prevData) => ({
        ...prevData!,
        name: newName
      }))
    }
  }, [scheduleData?.friendlyName, formType, generateScheduleName])

  const handleFieldChange = (field: keyof IJobSchedule) => (value: any) => {
    setScheduleData((prevData) => ({
      ...prevData!,
      [field]: value
    }))

    validateForm()
  }

  const handleCronChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setCronVal(value)

    try {
      awsCronExpressionValidator(value)
      setError('')
      validateForm()
    } catch (err) {
      setError((err as Error).message)
      setIsValid(false)
    }
  }

  const handleSave = () => {
    if (formType == FormType.Update && scheduleData) {
      // Update
      const payload = {
        id: scheduleData?.id,
        scheduleExpression: `cron(${cronVal})`,
        timezone: user?.business_local_timezone || 'UTC',
        parallelism: scheduleData?.parallelism ?? null,
        replicationJobId: scheduleData?.replicationJobIdFromTargetInput ?? null,
        state: scheduleData?.state,
        description: scheduleData?.description,
        notificationProfileId: scheduleData?.notificationProfileId
      }
      updateJobSchedule(payload)
    } else {
      // Create
      const payload = {
        friendlyName: scheduleData?.friendlyName,
        description: scheduleData?.description,
        scheduleExpression: `cron(${cronVal})`,
        timezone: user?.business_local_timezone || 'UTC',
        state: scheduleData?.state,
        sourceId: currentPage?.pageSettings?.id,
        sourceType: 'page',
        scheduleConfigs: JSON.stringify(
          snakeCaseKeys({
            dateFilter: expandedDateFilterInfo,
            dimensions: appliedDimensions,
            financialStatementView: financialStatementView,
            modelId: selectedModelId,
            languageId: languageId,
            currencyId: currencyId,
            showDuplicateAccounts: viewMoreItems.duplicateGlAccount,
            showAllZeroRows: viewMoreItems.showAllZeroRows,
            parameterizedFilters: parameterizedFilters,
            filterModels: filterModels,
            sortModels: sortModels
          })
        ),
        notificationProfileId: scheduleData?.notificationProfileId
      }

      createJobSchedule(payload)
    }
  }

  const notificationOptions = useMemo(
    () =>
      notificationProfiles?.map((group) => ({
        label: group.name,
        value: group.id!.toString()
      })) || [],
    [notificationProfiles]
  )

  return (
    <div>
      <div className={cn('flex items-center gap-2', 'w-full justify-between')}>
        {formType === FormType.Add && <Text variant='cardValue'>Create Schedule</Text>}
        {formType === FormType.Update && <Text variant='cardValue'>Update Schedule</Text>}

        <div className='flex items-center justify-between gap-2'>
          <Button onClick={onCancel} type='button' variant='outline' size={'default'}>
            {'Cancel'}
          </Button>
          <Button
            onClick={handleSave}
            disabled={!isValid}
            type='button'
            variant={!isValid ? 'disabled' : 'primary'}
            size='default'
          >
            Save
          </Button>
        </div>
      </div>

      <div className='my-2 flex flex-col items-stretch'>
        {formType === FormType.Add && (
          <>
            <div className='flex gap-4'>
              {/*Friendly Name*/}
              <div className='flex-1'>
                <div className='flex items-center justify-between'>
                  <Text variant='description'>Friendly Name</Text>
                </div>
                <Input
                  onChange={(e) => handleFieldChange('friendlyName')(e.target.value)}
                  value={scheduleData?.friendlyName || ''}
                  type='text'
                  className='w-full'
                />
              </div>
            </div>

            <div className='flex items-center justify-between'>
              <Text variant='h6'>Schedule: {scheduleData?.name}</Text>
            </div>
          </>
        )}

        {formType === FormType.Update && (
          <>
            <div className='flex items-center justify-between'>
              <Text variant='h6'>{scheduleData?.friendlyName}</Text>
            </div>
            <div className='flex items-center justify-between'>
              <Text variant='h6'>{scheduleData?.name}</Text>
            </div>
          </>
        )}

        <div className='mt-2 flex gap-4'>
          {/*Description*/}
          <div className='flex-1'>
            <div className='flex items-center justify-between'>
              <Text variant='description'>Description</Text>
            </div>
            <Input
              onChange={(e) => handleFieldChange('description')(e.target.value)}
              value={scheduleData?.description || ''}
              type='text'
              className='w-full'
            />
          </div>
        </div>

        <div className='mt-2 flex gap-4'>
          {/*Timezone*/}
          <div className='flex-1'>
            <div className='flex items-center justify-between'>
              <Text variant='description'>New Timezone</Text>
            </div>
            <div className='gap-1'>
              <Input
                value={user?.business_local_timezone}
                type='text'
                className='w-full'
                disabled
              />
            </div>
          </div>

          {/*AWS Schedule State*/}
          <div className='flex-1'>
            <div className='flex items-center justify-between'>
              <Text variant='description'>State</Text>
            </div>
            <div className='gap-1'>
              <Select
                options={enumToOptions(ScheduleStateEnum)}
                value={scheduleData?.state || ScheduleStateEnum.Disabled}
                onChange={(value) => handleFieldChange('state')(value as ScheduleStateEnum)}
              />
            </div>
          </div>
        </div>

        {scheduleTypeIsRust && (
          <>
            <div className='mt-2 flex gap-4'>
              {/*Parallelism */}
              <div className='flex-1'>
                <div className='flex items-center justify-between'>
                  <Text variant='description'>Parallelism</Text>
                </div>
                <Input
                  value={scheduleData?.parallelism ?? ''}
                  type='number'
                  onChange={(e) =>
                    handleFieldChange('parallelism')(e.target.value ? e.target.value : null)
                  }
                  min={1}
                />
              </div>

              {/*Replication Job ID*/}
              <div className='flex-1'>
                <div className='flex items-center justify-between'>
                  <Text variant='description'>Replication Job ID</Text>
                </div>
                <Input
                  value={scheduleData?.replicationJobIdFromTargetInput ?? ''}
                  type='number'
                  onChange={(e) =>
                    handleFieldChange('replicationJobIdFromTargetInput')(
                      e.target.value ? Number(e.target.value) : null
                    )
                  }
                />
              </div>
            </div>
          </>
        )}

        {/*Cron Expression*/}
        <div className='mb-4 mt-2 flex gap-4'>
          <div className='flex-1'>
            <div className='flex items-center justify-start'>
              <Text variant='description'>New Schedule Expression</Text>
              <Link
                className='ml-2'
                href='https://docs.aws.amazon.com/eventbridge/latest/userguide/eb-scheduled-rule-pattern.html'
                target='_blank'
                rel='noopener noreferrer'
                display='flex'
                alignItems='center'
              >
                <InfoIcon fontSize='small' />
              </Link>
            </div>
            <Input
              value={cronVal}
              type='text'
              placeholder='0 23 * * ? *'
              onChange={handleCronChange}
            />
            {error && (
              <Text variant='details' className='mt-2 text-error-darker'>
                Error: {error}
              </Text>
            )}
            <Text variant='details' className='mt-2'>
              {humanizeCron(cronVal, '')}
            </Text>
          </div>

          {scheduleEmailReporting ? (
            <div className='flex-1'>
              <div className='flex items-center justify-between'>
                <Text variant='description'>Notification Profile</Text>
              </div>
              <div className='gap-1'>
                <Select
                  options={notificationOptions}
                  value={scheduleData?.notificationProfileId?.toString() || ''}
                  onChange={(value) => handleFieldChange('notificationProfileId')(value as string)}
                />
              </div>
            </div>
          ) : (
            <div className='flex-1'></div>
          )}
        </div>
      </div>
    </div>
  )
}
