import React, { useMemo, useRef, useState } from 'react'

import { AgGridReact } from 'ag-grid-react'

import { useRegisterAgGridRefEffect } from '@contexts/grid-ref-context'
import { hasArrayChanged, isNotBlank } from '@utils/lodash'

import { Button } from '@core/button'
import { IconButton } from '@core/icon-button'
import { MultiSelect } from '@core/multi-select'
import { Popover, PopoverContent, PopoverTrigger } from '@core/popover'
import { Separator } from '@core/separator'
import { Text } from '@core/text'

import { AgDataTable } from '@components/ag-data-table'
import { chevronActionCellRenderer } from '@components/ag-grid/custom-cell-renderer/chevron-action-cell-renderer'
import AgGridSearchInput from '@components/control-panel/ag-grid-search-input'
import { useDeleteNotificationProfile } from '@components/control-panel/report-scheduler/queries/notification-profiles/delete-notification-profile'
import { useFetchNotificationProfiles } from '@components/control-panel/report-scheduler/queries/notification-profiles/fetch-notification-profiles'
import { useUpdateNotificationProfile } from '@components/control-panel/report-scheduler/queries/notification-profiles/update-notification-profile'
import { NotificationProfileForm } from '@components/control-panel/report-scheduler/shared/form'
import { INotificationProfile } from '@components/control-panel/types'
import { closePopover } from '@components/form/charger-form'
import { Icon, Pencil } from '@components/icons'

import { DeactivateRestorePopover } from '@pages/admin/access-management/shared/deactivate-restore-popover'
import { useFetchUsers } from '@pages/admin/access-management/users/queries/fetch-users'

import { User } from '../../../../types/user'

const ManageNotificationProfiles = () => {
  const { isPending, data } = useFetchNotificationProfiles()
  const notificationProfiles = useMemo(() => data ?? [], [data])
  const gridRef = useRef<AgGridReact<any>>(null)
  useRegisterAgGridRefEffect(gridRef)

  const [currentProfile, setCurrentProfile] = useState<INotificationProfile | null>(null)
  const popoverTriggerRef = useRef<HTMLButtonElement>(null)

  const { mutate: deleteNotificationProfile } = useDeleteNotificationProfile({
    onSuccess: () => {
      gridRef.current?.api?.refreshCells({ force: true })
      setCurrentProfile(null)
    }
  })

  const columnDefs = useMemo(() => {
    return _.compact([
      { field: 'id', hide: true },
      {
        field: 'name',
        headerName: 'Name',
        cellClass: 'text-body text-primary-darker',
        minWidth: 230,
        maxWidth: 230
      },
      // { field: 'description', headerName: 'Description', type: ColumnTypes.RIGHT_ALIGNED },
      {
        minWidth: 60,
        maxWidth: 60,
        sortable: false,
        cellRenderer: chevronActionCellRenderer
      }
    ])
  }, [])

  return (
    <>
      <div className='flex min-h-11 w-full items-center space-x-2'>
        <AgGridSearchInput />
        <Popover>
          <PopoverTrigger asChild>
            <Button ref={popoverTriggerRef}>New Notification Profile</Button>
          </PopoverTrigger>

          <PopoverContent className='w-[550px]'>
            <NotificationProfileForm
              onSuccess={() => {
                closePopover(popoverTriggerRef.current)
              }}
              onCancel={() => closePopover(popoverTriggerRef.current)}
            />
          </PopoverContent>
        </Popover>
      </div>

      <div className='flex w-full'>
        <div className='h-[calc(100vh-300px)] w-2/4'>
          <AgDataTable
            loading={isPending}
            getRowClass={(params) => 'text-grey-dark'}
            ref={gridRef}
            rowData={notificationProfiles || []}
            columnDefs={columnDefs}
            rowSelection='single'
            enableRangeSelection={false}
            onRowDataUpdated={(params) => {
              if (!currentProfile) return

              params.api.forEachNode((node) => {
                if (node.data.id === currentProfile.id) {
                  node.setSelected(true)
                  return
                }
              })
            }}
            onFirstDataRendered={(params) => {
              const firstNode = params.api.getDisplayedRowAtIndex(0)
              firstNode?.setSelected(true)
            }}
            onSelectionChanged={(params) => {
              const selectedRows = gridRef.current?.api.getSelectedRows()
              const selectedProfile = _.head(selectedRows || [])

              if (isNotBlank(selectedProfile)) {
                setCurrentProfile(selectedProfile)
              }
            }}
          />
        </div>

        <Separator orientation='vertical' className='mx-2' />

        {/* Notification Profile Detail */}
        {!currentProfile && (
          <div className='w-2/4 px-0 py-2'>
            <Text className='italic' variant='h6'>
              Select a Notification Profile
            </Text>
          </div>
        )}

        {currentProfile && (
          <div className='w-2/4 px-0 py-2'>
            <div className='flex items-center gap-[16px]'>
              <div className='flex flex-col'>
                <Text variant='h6'>
                  Notification Profile:
                  <span className='font-bold'> {currentProfile?.name}&apos;s</span>
                </Text>
                <Text variant='details' className='text-blue-dark'>
                  {currentProfile?.description}
                </Text>

                <div className='flex justify-start'>
                  <Popover>
                    <PopoverTrigger asChild>
                      <IconButton ref={popoverTriggerRef} className='m-0 border-none p-0'>
                        <Icon icon={<Pencil />} />
                      </IconButton>
                    </PopoverTrigger>

                    <PopoverContent>
                      <NotificationProfileForm
                        existingProfile={currentProfile}
                        onSuccess={() => {
                          closePopover(popoverTriggerRef.current)
                        }}
                        onCancel={() => closePopover(popoverTriggerRef.current)}
                      />
                    </PopoverContent>
                  </Popover>

                  <Separator orientation='vertical' className='m-2' />
                  <DeactivateRestorePopover
                    isActiveResource={true}
                    resourceType='Notification Profile'
                    onDeactivate={() =>
                      currentProfile && deleteNotificationProfile(currentProfile.id)
                    }
                  />
                </div>

                <Separator className='my-2' />

                <div className='flex h-full flex-col'>
                  <div className='flex w-full flex-wrap justify-between gap-2'>
                    <div className='min-w-[324px] flex-1'>
                      <Text variant='body' className='mb-2 text-primary-darker'>
                        Users
                      </Text>

                      <Separator className='my-2' />

                      <UsersSelection currentProfile={currentProfile} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

const UsersSelection = ({ currentProfile }: { currentProfile: INotificationProfile | null }) => {
  const { data: users } = useFetchUsers()

  const { mutate: updateNotificationProfile } = useUpdateNotificationProfile()

  const userOptions = useMemo(() => {
    if (!users) return []

    const selectableUsers = users.filter(
      (user: User) => user.is_active || user.has_active_invitation
    )

    return selectableUsers?.map((user: User) => ({ label: user.name, value: user.id }))
  }, [users])

  const defaultSelectedValues = useMemo(
    () => currentProfile?.users?.map((user: User) => user.id) || [],
    [currentProfile?.users]
  )

  return (
    <MultiSelect
      options={userOptions}
      defaultSelectedValues={defaultSelectedValues}
      onBlur={(selectedOptions, setSelectedOptions) => {
        const updatedUserIds = selectedOptions.map((option) => option.value as string)

        if (!hasArrayChanged(defaultSelectedValues, updatedUserIds)) return

        const payload = {
          id: currentProfile?.id,
          userIds: updatedUserIds
        }

        const defaultSelectedOptions = userOptions.filter((option) =>
          defaultSelectedValues.includes(option.value)
        )

        updateNotificationProfile({
          payload,
          onError: () => setSelectedOptions(defaultSelectedOptions)
        })
      }}
    />
  )
}

export default ManageNotificationProfiles
