import { useMemo } from 'react'

import { humanizeFieldName } from '@utils/string-utils'

import LazyComponent from '../core/LazyComponent'
import { FilterError } from '../fallback/filter-error'

const ControlComponent = ({
  name,
  shouldRender,
  componentProps
}: {
  name: string
  shouldRender: boolean
  componentProps: any
}) => {
  const Component = useMemo(() => {
    // TODO: only load the required files
    const modules: Record<string, any> = import.meta.glob('./*.tsx')
    const component = modules[`./${_.kebabCase(name)}.tsx`]
    return LazyComponent(component, undefined, () => (
      <FilterError message={`${humanizeFieldName(name)} filter failed to load`} />
    ))
  }, [name])

  if (!shouldRender) return <></>

  return <Component {...componentProps} />
}

export default ControlComponent
