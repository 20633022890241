import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { CurrentPage, NavItemType } from '../../types/menu'
import { rehydrateStore } from '../actions/rehydrate-store'
import { RootState } from '../index'

interface NavigationState {
  currentMenuItem: NavItemType
  drawerOpen: boolean
  headerCollapsed: boolean
  currentPage: CurrentPage
}

const initialState: NavigationState = {
  currentMenuItem: { title: 'dashboard' },
  currentPage: null,
  drawerOpen: true,
  headerCollapsed: false
}

const navigationSlice = createSlice({
  name: '@NAVIGATION',
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      state.drawerOpen = !state.drawerOpen
    },
    openDrawer: (state) => {
      state.drawerOpen = true
    },
    closeDrawer: (state) => {
      state.drawerOpen = false
    },
    updateCurrentPage: (state, action: PayloadAction<CurrentPage>) => {
      state.currentPage = action.payload
    },
    updateCurrentMenuItem: (state, action: PayloadAction<NavItemType>) => {
      state.currentMenuItem = action.payload as any
    },
    toggleHeaderCollapse: (state) => {
      state.headerCollapsed = !state.headerCollapsed
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrateStore, (state, action) => {
      if (!action.payload.navigation) return

      state.drawerOpen = action.payload.navigation.drawerOpen
    })
  }
})

// Actions
export const {
  toggleDrawer,
  closeDrawer,
  openDrawer,
  toggleHeaderCollapse,
  updateCurrentPage,
  updateCurrentMenuItem
} = navigationSlice.actions

// Reducer
export const navigationReducer = navigationSlice.reducer

// Selectors
export const selectCurrentMenuItem = (state: RootState) => state.navigation.currentMenuItem

export const selectDrawerOpen = (state: RootState) => state.navigation.drawerOpen

export const selectCurrentPage = (state: RootState) => state.navigation.currentPage

export const selectHeaderCollapsed = (state: RootState) => state.navigation.headerCollapsed
