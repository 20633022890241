import { Month, dateStringToLocalDate, getHeadingFromDate } from '@utils/date-utils'

import { DollarUnit, DollarUnitEnum } from 'components/control-panel/types'
import { Frequency } from 'types/filter'
import { formatFinancialAmount, formatPercentage } from 'utils/number-utils'
import { toDividedNumber } from 'utils/string-utils'

import {
  FinancialHierarchyNode,
  INodeFormattingExpanded,
  NodeFormatStyle,
  NodeFormatType,
  NodeFormatting
} from '../types'
import { isFirstLevelNode, isHeadingOrPointerNode, isSpacer } from './node-utils'
import { isTotal } from './node-utils'

export const resolveNodeFormatType = (
  node: FinancialHierarchyNode,
  cellFormatting?: NodeFormatting
) => {
  const label = _.get(node, 'label', '')
  const formatting = cellFormatting ?? _.get(node, 'metadata.formatting', {})

  let nodeType: NodeFormatType

  if (formatting?.type) {
    nodeType = formatting.type
  } else if (label.endsWith('%')) {
    nodeType = NodeFormatType.Percentage
  } else {
    nodeType = NodeFormatType.Number
  }

  return nodeType
}

export function resolveNodeFormatting(
  node: FinancialHierarchyNode,
  cellFormatting?: NodeFormatting
): INodeFormattingExpanded {
  const formatting = cellFormatting ?? _.get(node, 'metadata.formatting', {})
  const path = _.get(node, 'path', '')

  const decimals: number = formatting?.decimals || 0

  let style: NodeFormatStyle
  if (formatting?.style) {
    style = formatting.style
  } else if ((isHeadingOrPointerNode(node) && isFirstLevelNode(path)) || isTotal(node)) {
    style = NodeFormatStyle.Bold
  } else {
    style = NodeFormatStyle.Normal
  }

  return {
    type: resolveNodeFormatType(node, cellFormatting),
    decimals,
    style,
    fontWeight:
      {
        [NodeFormatStyle.Bold]: 'bold',
        [NodeFormatStyle.Normal]: 'normal',
        [NodeFormatStyle.Italic]: 'normal'
      }[style] || 'normal',
    fontStyle: style === NodeFormatStyle.Italic ? 'italic' : 'normal'
  }
}

export const getDivideBy = (displayUnit: DollarUnit) => {
  if (displayUnit === DollarUnitEnum.K) {
    return 1000
  } else if (displayUnit === DollarUnitEnum.M) {
    return 1000000
  } else {
    return 1
  }
}

export const financialValueGetter = (
  dataPath: string,
  data: FinancialHierarchyNode,
  divideBy: number,
  cellFormatting?: NodeFormatting
) => {
  const formatType = resolveNodeFormatType(data, cellFormatting)
  const cellValue = _.get(data, dataPath, 0)
  if (formatType === NodeFormatType.Percentage) {
    return cellValue
  }

  const unitConveredValue = toDividedNumber(cellValue, divideBy)
  return unitConveredValue
}

export const financialValueFormatter = (
  value: number,
  data: FinancialHierarchyNode,
  cellFormatting?: NodeFormatting,
  isCellValueVisible: boolean = true,
  isPercentColumn: boolean = false
) => {
  const formattings = resolveNodeFormatting(data, cellFormatting)

  if (formattings.type === NodeFormatType.Percentage || isPercentColumn) {
    return formatPercentage(value, formattings.decimals)
  }

  if (isSpacer(data) || !isCellValueVisible) {
    return ''
  }

  return formatFinancialAmount(value, formattings.decimals)
}

export const formatFinancialDateHeaderUsingFrequency = (
  date: string,
  frequency: Frequency,
  fiscalYearStart?: Month
) => {
  const dateFieldRegex = /^\d{4}-\d{2}-\d{2}$/

  if (dateFieldRegex.test(date)) {
    const dateLocal = dateStringToLocalDate(date, 'startOfDay')
    const formattedDate = getHeadingFromDate(frequency, dateLocal, fiscalYearStart)

    return formattedDate
  }

  return date
}
