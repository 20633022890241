import { useMutation, useQueryClient } from '@tanstack/react-query'

import { queryErrorToast, querySuccessToast } from '@queries/query-toasts'
import axiosServices from '@utils/axios'

import useAuth from '@hooks/useAuth'

export interface IDeleteNotificationProfileProps {
  onSuccess?: () => void
}

export const useDeleteNotificationProfile = (props: IDeleteNotificationProfileProps) => {
  const queryClient = useQueryClient()
  const { user } = useAuth()

  return useMutation({
    mutationFn: (id: string | number) => {
      return axiosServices.delete(`/businesses/${user?.business_id}/notification_profiles/${id}`)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['fetch-notification-profiles', user?.business_id]
      })
      props.onSuccess?.()
      querySuccessToast('Notification Profile deleted successfully')
    },
    onError: (error: any) => {
      queryErrorToast(error.message)
    }
  })
}
