import { EnumValueUnion, enumToOptions } from '@utils/obj-utils'

import { FilterModels, FinancialReportExportFilters, SortModels } from 'components/financial/types'
import { IParameterizedFiltersParameters } from 'pages/component-management/types/component-types'

import { Business } from '../../types/auth'
import { FilterIdentifier } from '../../types/page-filters'
import { User } from '../../types/user'

export interface ComponentExportFilters extends FinancialReportExportFilters {
  parameterizedFilters?: IParameterizedFiltersParameters
  filterModels?: FilterModels
  sortModels?: SortModels
  syncMode?: boolean
}

export interface FilterProps {
  identifier: FilterIdentifier
  klassName?: string
  defaultValue?: any
  exclusions?: string[]
  options?: Option[] | any
  order?: number
  label?: string
  mode?: SelectControlModeEnum
  mobileView: boolean
  category?: string
  status?: 'loading' | 'ready'

  showFrequencyToggle?: boolean
  showSecondaryFrequencyToggle?: boolean
  showSecondaryRangeSelector?: boolean
}

export interface Option {
  label: string
  value: string
  group_name?: string
  group_val?: { label: string; value: string }[]
}

export enum FilterDisplayModeEnum {
  default = 'default',
  list = 'list'
}
export type FilterDisplayMode = EnumValueUnion<typeof FilterDisplayModeEnum>

export const isFilterDisplayModeList = (filterDisplayMode: FilterDisplayMode): boolean =>
  filterDisplayMode === FilterDisplayModeEnum.list

export interface BaseFilterProps {
  filter?: FilterProps
  filterDisplayMode?: FilterDisplayMode
}

export interface DropDownFilterProps extends BaseFilterProps {
  mode?: SelectControlMode
  value?: string
  labelName?: string
}

/// General Controls Types ///

export enum SelectControlModeEnum {
  select = 'select',
  group = 'group',
  item = 'item',
  list = 'list'
}

export type SelectControlMode = EnumValueUnion<typeof SelectControlModeEnum>

/// General Controls Types End ///

/// Custom Control Fields ///

export enum DateFrequencyEnum {
  Y = 'year',
  Q = 'quarter',
  M = 'month',
  W = 'week',
  D = 'day'
}
export type DateFrequency = EnumValueUnion<typeof DateFrequencyEnum> // 'year' | 'quarter' | 'month' | 'week' | 'day';
export const DateFrequencyOptions = enumToOptions(DateFrequencyEnum) // [ {label: value: }, {label: value: }]
export const DateFrequencyOptionsCustom = [
  { label: 'YEAR', value: 'year' },
  { label: 'QUARTER', value: 'quarter' },
  { label: 'MONTH', value: 'month' },
  { label: 'WEEK', value: 'week' },
  { label: 'DAY', value: 'day' }
]

// 'today' | '7days' | '4weeks';
export enum TimePeriodEnum {
  Today = 'today',
  '7 days' = '7days',
  '4 weeks' = '4weeks'
}
export type TimePeriodType = EnumValueUnion<typeof TimePeriodEnum> // 'today' | '7days' | '4weeks';
export const TimePeriodOptions = enumToOptions(TimePeriodEnum) // [ {label: value: }, {label: value: }]

//'archived' | 'not_archived';
export enum ArchiveStatusEnum {
  Archived = 'archived',
  'Not Archived' = 'not_archived'
}
export type ArchiveStatusType = EnumValueUnion<typeof ArchiveStatusEnum> //'archived' | 'not_archived';
export const ArchiveToggleOptions = enumToOptions(ArchiveStatusEnum) // [ {label: value: }, {label: value: }]

// 'sales' | 'quantity'
export enum SalesQuantityToggleEnum {
  $ = 'sales',
  Quantity = 'quantity'
}
export type SalesQuantityType = EnumValueUnion<typeof SalesQuantityToggleEnum> // 'sales' | 'quantity'
export const SalesQuantityToggleOptions = enumToOptions(SalesQuantityToggleEnum) // [ {label: value: }, {label: value: }]

export const SalesQuantityToggleOptionsCustom = [
  { label: 'Sales', value: 'sales' },
  { label: 'Quantity', value: 'quantity' }
]

//'K' | 'M' | 'E';
export enum DollarUnitEnum {
  K = 'K',
  M = 'M',
  E = 'E'
}
export type DollarUnit = EnumValueUnion<typeof DollarUnitEnum> //'K' | M' | 'E';
export const DollarUnitOptions = enumToOptions(DollarUnitEnum) // [ {label: value: }, {label: value: }]
export const DollarUnitOptionsCustom = [
  { label: 'Millions (M)', value: DollarUnitEnum.M },
  { label: 'Thousands (K)', value: DollarUnitEnum.K },
  { label: 'Exact', value: DollarUnitEnum.E }
]

export const DollarUnits = Object.values(DollarUnitEnum) // ['K', 'M', 'E'];

export enum ItemVendorEnum {
  Item = 'item',
  Vendor = 'vendor'
}
export type ItemVendorType = EnumValueUnion<typeof ItemVendorEnum> // 'item' | 'vendor'
export const ItemVendorOptions = enumToOptions(ItemVendorEnum) // [ {label: value: }, {label: value: }]

export enum StatusEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}
export type StatusType = EnumValueUnion<typeof StatusEnum> // 'active' | 'inactive'
export const StatusOptions = enumToOptions(StatusEnum) // [ {label: value: }, {label: value: }]

export const isStatusActive = (status: StatusEnum): boolean => status === StatusEnum.ACTIVE
export const isStatusInactive = (status: StatusEnum): boolean => status === StatusEnum.INACTIVE

export enum DataExportEnum {
  XLSX = 'XLSX'
}
export type DataExportType = EnumValueUnion<typeof DataExportEnum> // 'XLSX'

export const isExportTypeXLSX = (exportType: DataExportEnum): boolean =>
  exportType === DataExportEnum.XLSX

export const DataExportTypes = Object.values(DataExportEnum)

export type ViewMoreOptionGroup = {
  group_name: string
  group_val: { label: string; value: string }[]
}

/* Dimension Filter Types */

export enum DimensionFilterEnum {
  departments = 'departments',
  verticals = 'verticals',
  subsidiaries = 'subsidiaries',
  customers = 'customers',
  projects = 'projects',
  business_locations = 'business_locations',
  general_ledger_classifications = 'general_ledger_classifications'
}

export const DimensionFilterSortOrder = [
  DimensionFilterEnum.subsidiaries,
  DimensionFilterEnum.business_locations,
  DimensionFilterEnum.departments,
  DimensionFilterEnum.verticals,
  DimensionFilterEnum.customers,
  DimensionFilterEnum.projects,
  DimensionFilterEnum.general_ledger_classifications
]

// Record<string, string> is dimension_name_id_label map
export type Dimensions = {
  subsidiaries: Record<string, string>
  departments: Record<string, string>
  customers: Record<string, string>
  verticals: Record<string, string>
  projects: Record<string, string>
  business_locations: Record<string, string>
  general_ledger_classifications: Record<string, string>
}

export type DimensionFilterType = EnumValueUnion<typeof DimensionFilterEnum>

export enum SelectedDimensionsKeys {
  department_name_ids = 'department_name_ids',
  vertical_name_ids = 'vertical_name_ids',
  subsidiary_name_ids = 'subsidiary_name_ids',
  customer_name_ids = 'customer_name_ids',
  project_name_ids = 'project_name_ids',
  general_ledger_classification_name_ids = 'general_ledger_classification_name_ids',
  business_location_name_ids = 'business_location_name_ids',
  customer_group_name_ids = 'customer_group_name_ids'
}

export type SelectedDimensions = {
  [SelectedDimensionsKeys.department_name_ids]: string[]
  [SelectedDimensionsKeys.vertical_name_ids]: string[]
  [SelectedDimensionsKeys.subsidiary_name_ids]: string[]
  [SelectedDimensionsKeys.customer_name_ids]: string[]
  [SelectedDimensionsKeys.project_name_ids]: string[]
  [SelectedDimensionsKeys.general_ledger_classification_name_ids]: string[]
  [SelectedDimensionsKeys.business_location_name_ids]: string[]
  [SelectedDimensionsKeys.customer_group_name_ids]: string[]
}

export type SelectedDimensionsType = keyof SelectedDimensions

export const EmptySelectedDimensions: SelectedDimensions = {
  department_name_ids: [],
  vertical_name_ids: [],
  subsidiary_name_ids: [],
  customer_name_ids: [],
  project_name_ids: [],
  business_location_name_ids: [],
  general_ledger_classification_name_ids: [],
  customer_group_name_ids: []
}
/* End Dimension Filter Types */

export type DimensionNameObject = {
  [key: string]: string[]
}

export const TimeZoneDefault = 'UTC'

export const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm'

export enum TimeZoneAccessModeEnum {
  global = 'global',
  page = 'page'
}

export type TimeZoneAccessMode = EnumValueUnion<typeof TimeZoneAccessModeEnum>

export interface INotificationProfile {
  id: number | string
  name: string
  description?: string
  business: Business
  users: User[]
  createdAt: string
  updatedAt: string
}

export interface INotificationProfilePayload extends INotificationProfile {
  userIds: string[]
}

export interface IJobScheduleHistory {
  id: number | string
}
