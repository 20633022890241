import React, { useRef } from 'react'

import MenuIcon from '@mui/icons-material/Menu'
import { Divider, Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import { ShouldRowBeSkippedParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { generateExportFileName } from '@utils/string-utils'

import { Button } from '@core/button'
import { Popover, PopoverContent, PopoverTrigger } from '@core/popover'

import DropDownIconButton from '@components/DropDownIconButton'
import { FinancialReportType } from '@components/financial/types'
import { ChargerForm, closePopover } from '@components/form/charger-form'
import { ChargerFormFieldConfig, FormLayoutTypes } from '@components/form/types'
import { Download, Icon } from '@components/icons'

import {
  selectCurrencyId,
  selectDataExportAsyncMode,
  selectFinancialHierarchyId,
  selectLanguageId,
  selectViewMoreItems
} from '@store/slices/action-bar'
import { selectFilterModels, selectSortModels } from '@store/slices/component/table-parameters'
import { selectDimensions } from '@store/slices/dimension'
import { selectCurrentPage } from '@store/slices/navigation'

import { useGetRegisteredAgGridRefs } from 'contexts/grid-ref-context'
import { useParameterizedFilters } from 'pages/component-management/viewer/index'
import { infoToast } from 'queries/query-toasts'
import { usePageSelector, useSelector } from 'store'
import { selectExpandedDateFilterInfo } from 'store/slices/date-time-filter'

import { useActivityContext } from '../activity-logger/context'
import Tooltip from '../core/tooltip'
import {
  BaseFilterProps,
  DataExportEnum,
  DataExportType,
  FilterDisplayModeEnum,
  isFilterDisplayModeList
} from './types'

interface DataExporterProps extends BaseFilterProps {
  gridRef?: React.RefObject<AgGridReact<any>>
  fileName?: string
  onExportDialogOpen?: () => void
  onExportDialogClose?: () => void
  enabledExports?: DataExportType[]
  exporterFieldsConfig?: ChargerFormFieldConfig[]
  backendExportHandler?: (data: any, exportFilters: any) => void
  financialReportType?: FinancialReportType
}

export const extractComponentIds = (layoutStructure?: any): number[] => {
  const componentIds: number[] = []

  layoutStructure?.forEach((row: { children: any[] }) => {
    row.children?.forEach((column) => {
      column.children?.forEach((widget: { componentId: number }) => {
        if (widget?.componentId) componentIds.push(widget.componentId)
      })
    })
  })

  return componentIds
}

const DataExporter = ({
  fileName = '',
  gridRef,
  filter,
  filterDisplayMode = FilterDisplayModeEnum.default,
  exporterFieldsConfig,
  backendExportHandler,
  financialReportType
}: DataExporterProps) => {
  const downloadReadyMessage = 'Your Excel export will be ready shortly for download.'

  const gridRefs = useGetRegisteredAgGridRefs()

  const { logActivity } = useActivityContext()
  const expandedDateFilter = usePageSelector(selectExpandedDateFilterInfo)
  const dimensions = usePageSelector(selectDimensions)
  const viewMoreItems = usePageSelector(selectViewMoreItems)
  const selectedHierarchyId = usePageSelector(selectFinancialHierarchyId)
  const languageId = usePageSelector(selectLanguageId)
  const currencyId = usePageSelector(selectCurrencyId)
  const dataExportAsyncMode = usePageSelector(selectDataExportAsyncMode)

  const { resolvedParameterizedFilters } = useParameterizedFilters({ firstFetch: false })
  const currentPage = useSelector(selectCurrentPage)
  const layoutStructure = currentPage?.pageSettings?.layout_structure

  const componentIds = extractComponentIds(layoutStructure)
  const filterModels = useSelector(selectFilterModels(componentIds))
  const sortModels = useSelector(selectSortModels(componentIds))

  const exportFilters = {
    dateFilter: expandedDateFilter,
    showDuplicateAccounts: viewMoreItems.duplicateGlAccount,
    showAllZeroRows: viewMoreItems.showAllZeroRows,
    selectedDimensionFilters: dimensions,
    selectedHierarchyId: selectedHierarchyId,
    parameterizedFilters: resolvedParameterizedFilters,
    filterModels: filterModels,
    sortModels: sortModels,
    languageId,
    currencyId,
    syncMode: !dataExportAsyncMode
  }

  const shouldExportByBackend = (): boolean => {
    return !!backendExportHandler
  }

  // TODO: Remove the use of gridRef? in this component everywhere
  const handleExportXLSX = () => {
    infoToast(downloadReadyMessage)
    logActivity({
      action: 'export'
    })

    let params: any = null

    if (fileName) {
      params = {
        fileName: generateExportFileName(fileName, 'xlsx'),
        shouldRowBeSkipped: (params: ShouldRowBeSkippedParams) => params.node.footer // skips the total rows in TreeData and RowGroup view
      }
    }

    if (params) {
      gridRef?.current?.api.exportDataAsExcel(params)

      gridRefs?.forEach((ref: any) => {
        ref.current?.api.exportDataAsExcel(params)
      })
    } else {
      gridRef?.current?.api.exportDataAsExcel()

      gridRefs?.forEach((ref: any) => {
        ref.current?.api.exportDataAsExcel()
      })
    }
  }

  const fieldsConfig = exporterFieldsConfig || []

  const popoverTriggerRef = useRef<HTMLButtonElement>(null)

  const renderButtonOnly = () => {
    return (
      <div>
        <Button
          onClick={() => backendExportHandler?.({}, exportFilters)}
          size='action-bar'
          variant='outline'
          className='m-1'
        >
          <Icon icon={<Download />} />
        </Button>
      </div>
    )
  }

  const exportByBackend = () => {
    if (isFilterDisplayModeList(filterDisplayMode)) {
      return (
        <>
          <Box sx={{ px: '16px' }}>
            <Grid container justifyContent='space-between' alignItems='center'>
              <Typography variant='subText'>
                {filter?.label ? filter.label : 'Share / Export'}
              </Typography>
              <MenuIcon />
            </Grid>
            <Divider sx={{ my: '4px' }} />

            <Grid
              container
              justifyContent='space-between'
              alignItems='center'
              sx={{ padding: 0, margin: 0, my: '8px' }}
            >
              <ChargerForm
                submitLabel='Export'
                showLabels={true}
                layout={FormLayoutTypes.INLINE}
                onSubmit={(data) => {
                  backendExportHandler?.(data, exportFilters)
                  closePopover(popoverTriggerRef.current)
                }}
                fieldsConfig={fieldsConfig}
              />
            </Grid>
          </Box>
        </>
      )
    } else {
      if (!fieldsConfig?.length) {
        return renderButtonOnly()
      }

      return (
        <>
          <Popover>
            <PopoverTrigger asChild>
              <DropDownIconButton ref={popoverTriggerRef} size='action-bar' className='m-1'>
                <Icon icon={<Download />} />
              </DropDownIconButton>
            </PopoverTrigger>
            <PopoverContent>
              <ChargerForm
                submitLabel='Export'
                showLabels={true}
                onCancel={() => {
                  closePopover(popoverTriggerRef.current)
                }}
                layout={FormLayoutTypes.POPOVER}
                onSubmit={(data) => {
                  backendExportHandler?.(data, exportFilters)
                  closePopover(popoverTriggerRef.current)
                }}
                fieldsConfig={fieldsConfig}
              />
            </PopoverContent>
          </Popover>
        </>
      )
    }
  }

  const exportByFrontend = () => {
    if (isFilterDisplayModeList(filterDisplayMode)) {
      return (
        <>
          <Box sx={{ px: '16px' }}>
            <Grid container justifyContent='space-between' alignItems='center'>
              <Typography variant='subText'>
                {filter?.label ? filter.label : 'Share / Export'}
              </Typography>
              <MenuIcon />
            </Grid>
            <Divider sx={{ my: '4px' }} />

            <Grid
              container
              justifyContent='space-between'
              alignItems='center'
              sx={{ padding: 0, margin: 0, my: '8px' }}
            >
              <Typography variant='subtitle1'>Export as {DataExportEnum.XLSX}</Typography>
              <IconButton size='actionBarNoSelection' onClick={() => handleExportXLSX()}>
                <Icon icon={<Download />} />
              </IconButton>
            </Grid>
          </Box>
        </>
      )
    } else {
      return (
        <React.Fragment>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            <Tooltip title={`Export to ${DataExportEnum.XLSX}`}>
              <Button onClick={handleExportXLSX} size='action-bar' variant='ghost'>
                <Icon icon={<Download />} />
              </Button>
            </Tooltip>
          </Box>
        </React.Fragment>
      )
    }
  }

  // For now, we perform backend export for few pages.
  // For other components, use the default export component from Ag-Grid.
  return shouldExportByBackend() ? exportByBackend() : exportByFrontend()
}

DataExporter.approximateWidth = 40
export default DataExporter
