export const SendMail = () => {
  return (
    <g transform='translate(3,2) scale(1.1)'>
      <path
        fill='currentColor'
        d='M16.614,5.208c0-0.276-0.225-0.501-0.502-0.501H7.53l1.208,0.813 c0.078,0.053,0.142,0.119,0.199,0.19h6.455L10.408,9.32L7.971,7.566l-0.965,0.65l3.111,2.218c0.17,0.123,0.412,0.121,0.582,0 l4.912-3.502v5.09H5.205V9.428L5.003,9.565C4.845,9.669,4.669,9.721,4.488,9.721C4.39,9.721,4.295,9.7,4.202,9.67v2.854 c0,0.277,0.225,0.502,0.502,0.502h11.409c0.277,0,0.502-0.225,0.502-0.502V5.958c0-0.026-0.004-0.05-0.008-0.072 c0.006-0.027,0.008-0.054,0.008-0.08V5.208H16.614z'
      />
      <path
        fill='currentColor'
        d='M4.308,8.799c0,0.066,0.037,0.127,0.096,0.158c0.06,0.031,0.13,0.027,0.185-0.01l3.735-2.514 C8.373,6.4,8.403,6.344,8.403,6.283c0-0.06-0.03-0.115-0.079-0.149L4.59,3.621C4.535,3.585,4.464,3.58,4.405,3.611 c-0.059,0.032-0.096,0.093-0.096,0.16v1.454C3.721,5.221,3.235,5.195,2.83,5.146C0.888,4.916,0.368,3.75,0.347,3.7 C0.318,3.632,0.252,3.59,0.181,3.59c-0.012,0-0.024,0-0.035,0.003C0.06,3.611,0,3.685,0,3.77c0,2.944,3.495,3.571,4.307,3.677 L4.308,8.799L4.308,8.799z'
      />
    </g>
  )
}
