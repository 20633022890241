import React, { useState } from 'react'

import { cn } from '@utils/style-utils'

import { Button } from '@core/button'
import { Separator } from '@core/separator'
import { Text } from '@core/text'

import ManageNotificationProfiles from '@components/control-panel/report-scheduler/shared/manage-notification-profiles'
import ManageSchedules from '@components/control-panel/report-scheduler/shared/manage-schedules'
import ScheduleHistory from '@components/control-panel/report-scheduler/shared/schedule-history'
import { EnumTabToggle } from '@components/tab/enum-tab-toggle'

export enum ReportScheduleModes {
  Schedules = 'Schedules',
  NotificationProfiles = 'Notification Profiles',
  ScheduleHistory = 'Schedule History'
}

const ReportScheduleExplorer = ({
  onClose,
  classNames = ''
}: {
  onClose?: () => void
  classNames?: string
}) => {
  const [activeTab, setActiveTab] = useState(ReportScheduleModes.Schedules)

  const onCancel = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <div
      className={cn(
        'z-10 mb-[100px] flex h-full w-[678px] flex-col bg-white px-3 py-4',
        classNames
      )}
    >
      <div className='flex items-center justify-between pt-3'>
        <Text variant='h5' className='text-black-dark'>
          Report Scheduler
        </Text>
        <div className='flex items-center justify-between'>
          <Button variant='outline' color='primary' onClick={onCancel}>
            Close
          </Button>
        </div>
      </div>

      <Separator className='my-4' />

      <EnumTabToggle
        id='schedule-management-tabs'
        enumObject={ReportScheduleModes}
        value={activeTab}
        onChange={setActiveTab}
      />

      <Separator className='my-2' />

      {activeTab === ReportScheduleModes.Schedules && <ManageSchedules />}

      {activeTab === ReportScheduleModes.NotificationProfiles && <ManageNotificationProfiles />}

      {activeTab === ReportScheduleModes.ScheduleHistory && <ScheduleHistory />}
    </div>
  )
}

export default ReportScheduleExplorer
