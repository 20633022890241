export const ArchiveOut = () => (
  <>
    <rect x='5' y='6' width='14' height='2' fill='currentColor' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6 9H18V13.3066C17.718 13.1245 17.3864 13.0052 17.0172 13.0002C16.5049 12.9932 16.0578 13.2079 15.713 13.5193C15.1464 14.0312 14.5831 14.5454 14.0219 15.0588C13.3853 15.6412 13.2612 16.6637 13.9643 17.3386C14.3665 17.7247 14.8968 17.8507 15.3889 17.7583C15.3889 17.8464 15.3889 17.9267 15.389 18H6V9ZM9.5 10H14.5V11H9.5V10Z'
      fill='currentColor'
    />
    <path
      d='M17.6064 15.8414C17.6064 16.0159 17.6064 16.1898 17.6064 16.3643C17.6064 18.2314 17.6078 18.0992 17.6064 19.9662C17.6064 20.2837 17.4056 20.5036 17.0858 20.553C16.7998 20.5972 16.5138 20.4417 16.4237 20.179C16.3941 20.0927 16.3906 19.9959 16.3906 19.9043C16.3892 17.949 16.3892 17.9937 16.3892 16.0383C16.3892 15.9738 16.3892 15.9093 16.3892 15.7966C16.2434 15.9218 16.1285 16.0166 16.0179 16.1154C15.8573 16.259 15.7017 16.4085 15.5404 16.5514C15.2776 16.7839 14.8944 16.7845 14.657 16.5566C14.4197 16.3288 14.4352 15.9758 14.6972 15.7361C15.2579 15.223 15.8193 14.7106 16.3835 14.2009C16.7773 13.8453 17.2309 13.8551 17.6226 14.2141C18.1833 14.7271 18.7503 15.2342 19.311 15.7472C19.5146 15.9336 19.5632 16.1753 19.4547 16.3933C19.342 16.6212 19.0976 16.7661 18.8257 16.7154C18.6756 16.687 18.5228 16.6008 18.4051 16.5046C18.1424 16.2899 17.9008 16.0528 17.65 15.825C17.6352 15.8302 17.6212 15.8362 17.6064 15.8414Z'
      fill='currentColor'
    />
  </>
)
