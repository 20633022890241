import React, { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'

import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { Separator } from '@core/separator'

import AgGrid from '@components/ag-grid'
import { JSONCellRenderer } from '@components/ag-grid/custom-cell-renderer/json-cell-renderer'
import { useFetchScheduleHistory } from '@components/control-panel/report-scheduler/queries/notification-profiles/fetch-schedule-history'

import {
  multiTextColumnDef,
  numericColumnDef,
  timeZoneDateColumnDef
} from '@layout-components/general/data-connections/utils/col-defs'

import { selectCurrentPage } from '@store/slices/navigation'
import { selectUserBusinessTimeZone } from '@store/slices/user-preferences'

const ScheduleHistory = () => {
  const currentPage = useSelector(selectCurrentPage)
  const { isPending, data } = useFetchScheduleHistory(currentPage?.pageSettings?.id)
  const timeZone = useSelector(selectUserBusinessTimeZone)

  const scheduleHistory = useMemo(() => data ?? [], [data])
  const gridRef = useRef<AgGridReact>(null)

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      numericColumnDef('id', 'Alert ID'),
      timeZoneDateColumnDef('alertTime', 'Sent at', timeZone),
      multiTextColumnDef('extraAttr.message', 'Message', { minWidth: 200 }),
      multiTextColumnDef('categoryType', 'Category'),
      {
        field: 'businessAlertLines',
        headerName: 'Business Alert Lines',
        cellRenderer: JSONCellRenderer,
        cellRendererParams: {
          hideEmpty: true,
          title: 'Business Alert Lines'
        },
        initialWidth: 200,
        maxWidth: 300
      }
    ]
  }, [timeZone])

  return (
    <>
      <div className='flex h-full flex-col gap-2'>
        <div className='flex min-h-0 grow flex-col'>
          <Separator className='w-full' />
          <div className='min-h-0 grow'>
            <AgGrid
              style={{ height: 'calc(100% - 10%)' }}
              ref={gridRef}
              autoSizeStrategy={{ type: 'fitCellContents' }}
              rowData={scheduleHistory}
              columnDefs={columnDefs}
              defaultColDef={{
                resizable: true,
                filter: 'agTextColumnFilter',
                floatingFilter: true,
                filterParams: {
                  buttons: ['clear']
                }
              }}
              loading={isPending}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ScheduleHistory
