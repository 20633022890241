import { useMutation, useQueryClient } from '@tanstack/react-query'

import axiosServices from '@utils/axios'

import useAuth from '@hooks/useAuth'

import { querySuccessToast } from 'queries/query-toasts'

interface AddSubsidiaryParams {
  name: string
  currency_code: string
  parent_node_id?: number
}

export const addSubsidiary = async (
  business_id: number | undefined,
  params: AddSubsidiaryParams
) => {
  const response = await axiosServices.post(`/businesses/${business_id}/subsidiaries`, params)
  return response.data
}

export const useAddSubsidiary = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: AddSubsidiaryParams) => addSubsidiary(user?.business_id, params),
    onSuccess: () => {
      querySuccessToast('Subsidiary Added Successfully')

      // Invalidate and refetch queries here if needed
      queryClient.invalidateQueries({
        queryKey: ['fetch-subsidiaries-hierarchy', user?.business_id]
      })
      queryClient.invalidateQueries({
        queryKey: ['fetch-model-properties']
      })
    }
  })
}
