import { useMutation, useQueryClient } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import { queryErrorToast, querySuccessToast } from 'queries/query-toasts'
import axiosServices from 'utils/axios'

function archiveSubsidiary({ business_id, id }: { business_id: number; id: number }) {
  return axiosServices.post(`/businesses/${business_id}/subsidiaries/${id}/archive`)
}

export const useArchiveSubsidiary = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id }: { id: number }) =>
      archiveSubsidiary({ business_id: user?.business_id!, id }),
    onSuccess: () => {
      querySuccessToast('Subsidiary Archived Successfully')
      queryClient.invalidateQueries({
        queryKey: ['fetch-subsidiaries-hierarchy', user?.business_id]
      })
      queryClient.invalidateQueries({
        queryKey: ['fetch-model-properties']
      })
    },
    onError: (err, variables) => {
      queryErrorToast(`Error Archiving Subsidiary. \n ${err?.message}`)
    }
  })
}
