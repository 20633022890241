import { AdvancedFilterModel, FilterModel, SortModelItem } from 'ag-grid-community'

import { useQuery } from '@tanstack/react-query'

import useAuth from '@hooks/useAuth'

import axiosServices from 'utils/axios'

export interface ModelPropertiesPayload {
  id: number
  properties: Record<
    string,
    {
      source: string
      confirmed: string
    }
  >
  standaloneProperties: Record<string, { source: string; confirmed: string }>
  additionalFields: Record<string, { headerName: string; value: string | number }>
  [key: string]: any
}

export interface ResponsePayload {
  records: ModelPropertiesPayload[]
  propertyTypes: string[]
  standalonePropertyTypes: string[]
  additionalFields: Array<{ field: string; headerName: string }>
  hasObjectStats: boolean
  count: number
}

export const fetchModelProperties = (
  businessId: number,
  model?: string,
  options?: {
    page?: number
    limit?: number
    filterModel?: FilterModel | AdvancedFilterModel | null
    sortModel?: SortModelItem[]
    showStatistics?: boolean
  }
): Promise<ResponsePayload> => {
  return axiosServices
    .post(`/businesses/${businessId}/property_renamings/data`, {
      model,
      page: options?.page,
      limit: options?.limit,
      filterModel: options?.filterModel,
      sortModel: options?.sortModel,
      showStatistics: options?.showStatistics
    })
    .then((response) => response.data)
}

export const useFetchModelProperties = (model: string, showStatistics = false) => {
  const { user } = useAuth()

  return useQuery({
    queryKey: ['fetch-model-properties', user?.business_id, model, showStatistics],
    queryFn: () => fetchModelProperties(user?.business_id!, model, { showStatistics }),
    enabled: !!user?.business_id
  })
}
