import React, { useCallback } from 'react'

import { GridApi, IRowNode } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { usePageDispatch, usePageSelector } from '@store/index'
import { PageActionPayload } from '@store/page-util'
import {
  selectDisplayExpansionControls,
  selectHasExpandableNodes,
  selectMaxExpandLevel,
  selectMostExpandedLevel,
  updateExpandLevels,
  updateHasExpandableNodes
} from '@store/slices/action-bar'
import { selectInitialMostExpandedLevel } from '@store/slices/action-bar'

import { Button } from 'components/core/button'
import Tooltip from 'components/core/tooltip'
import { ExpandDefault, ExpandLess, ExpandMore, Icon } from 'components/icons'
import { AgGridRef } from 'contexts/grid-ref-context'

import { useGetRegisteredAgGridRefs } from '../../contexts/grid-ref-context'
import { BaseFilterProps, FilterDisplayModeEnum, isFilterDisplayModeList } from './types'

export const DEFAULT_MOST_EXPANDED_LEVEL = 1
export const DEFAULT_MAX_EXPANSION_LEVEL = 2

interface ExpandCollapseControlProps extends BaseFilterProps {
  gridRef?: React.RefObject<AgGridReact<any>>
  labelName?: string
  displayExpansionControls?: boolean
  type?: string // NOTE: The type here is essentially a key to store and retrieve the grid refs in a given page/view. This is not a financial report type
}

export const restoreDefaultExpansion = (
  pageDispatch: (pageActionPayload: PageActionPayload) => void,
  gridRefs: AgGridRef[] = [],
  initialMostExpandedLevel: number,
  event?: React.MouseEvent<HTMLButtonElement>
) => {
  _.forEach(gridRefs, (ref) => {
    ref?.current?.api?.forEachNode((node: IRowNode) => {
      if (node.group) {
        const shouldExpand = node.level <= initialMostExpandedLevel - 1
        node.setExpanded(shouldExpand, event as unknown as MouseEvent | KeyboardEvent)
      }
    })
  })

  pageDispatch(updateExpandLevels({ mostExpandedLevel: initialMostExpandedLevel - 1 }))

  if (event && event.currentTarget) {
    event.currentTarget.blur()
  }
}

const isApiValid = (api: GridApi | undefined | null): boolean => {
  return !!api && !api.isDestroyed()
}

// Helper to find the next expandable level
const findNextExpandableLevel = (api: GridApi, maxExpandLevel: number): number => {
  if (!isApiValid(api)) return -1
  let nextLevel = Infinity
  api.forEachNode((node: IRowNode) => {
    if (node.group && !node.expanded) {
      nextLevel = Math.min(nextLevel, node.level)
    }
  })
  return nextLevel !== Infinity && nextLevel <= maxExpandLevel ? nextLevel : -1
}

// Helper to expand nodes at a given level
const expandNodesAtLevel = (
  api: GridApi,
  level: number,
  event: React.MouseEvent<HTMLButtonElement>
) => {
  if (!isApiValid(api)) return
  api.forEachNode((node: IRowNode) => {
    if (node.group && node.level === level) {
      node.setExpanded(true, event as unknown as MouseEvent | KeyboardEvent)
    }
  })
}

// Helper to find the previous level to collapse
const findPreviousExpandableLevel = (api: GridApi): number => {
  if (!isApiValid(api)) return -1
  let prevLevel = -1
  api.forEachNode((node: IRowNode) => {
    if (node.group && node.expanded) {
      prevLevel = Math.max(prevLevel, node.level)
    }
  })
  return prevLevel
}

// Helper to collapse nodes at a given level
const collapseNodesAtLevel = (
  api: GridApi,
  level: number,
  event: React.MouseEvent<HTMLButtonElement>
) => {
  if (!isApiValid(api)) return
  api.forEachNode((node: IRowNode) => {
    if (node.group && node.level === level) {
      node.setExpanded(false, event as unknown as MouseEvent | KeyboardEvent)
    }
  })
}

export const recalculateHasExpandableNodes = (api: GridApi): boolean => {
  if (!isApiValid(api)) return false
  // Only check the first 1000 rows for performance reasons
  // Note: In case of server-side row model, there might be millions of rows
  const rowCount = Math.min(api.getDisplayedRowCount(), 1000)
  for (let i = 0; i < rowCount; i++) {
    const node = api.getDisplayedRowAtIndex(i)
    if (
      node &&
      node.displayed &&
      node.group &&
      node.expanded !== undefined && // Needed to exclude "rowGroupFooter_ROOT_NODE_ID" nodes
      !node.expanded &&
      (node.allChildrenCount || 0) > 0
    ) {
      // Early exit: return true when a matching node is found
      return true
    }
  }
  // If no matching node is found, return false
  return false
}

export const recalculateMostExpandedLevel = (api: GridApi): number => {
  if (!isApiValid(api)) return -1
  let maxLevel = -1
  const rowCount = api.getDisplayedRowCount()

  for (let i = 0; i < rowCount; i++) {
    const node = api.getDisplayedRowAtIndex(i)
    if (node && node.group && node.expanded) {
      maxLevel = Math.max(maxLevel, node.level)
    }
  }

  return maxLevel
}

const ExpandCollapseControl = ({
  gridRef,
  filterDisplayMode = FilterDisplayModeEnum.default,
  type = 'income_statement' // NOTE: The type here is essentially a key to store and retrieve the grid refs in a given page/view. This is not a financial report type
}: ExpandCollapseControlProps) => {
  const pageDispatch = usePageDispatch()
  const pageSelectedMostExpandedLevel = usePageSelector(selectMostExpandedLevel)
  const mostExpandedLevel = pageSelectedMostExpandedLevel ?? DEFAULT_MOST_EXPANDED_LEVEL - 2
  const maxExpandLevel = usePageSelector(selectMaxExpandLevel) ?? DEFAULT_MAX_EXPANSION_LEVEL
  const hasExpandableNodes = usePageSelector(selectHasExpandableNodes)
  const displayExpansionControls = usePageSelector(selectDisplayExpansionControls)

  const initialMostExpandedLevel = usePageSelector(selectInitialMostExpandedLevel)

  const gridRefs = useGetRegisteredAgGridRefs(type)

  const toggleExpandCollapse = useCallback(
    (expand: boolean, event: React.MouseEvent<HTMLButtonElement>) => {
      const allGrids = [gridRef, ...gridRefs]

      allGrids.forEach((ref) => {
        const api = ref?.current?.api
        if (!api || api.isDestroyed()) return

        if (expand) {
          const nextLevel = findNextExpandableLevel(api, maxExpandLevel)
          if (nextLevel >= 0) {
            expandNodesAtLevel(api, nextLevel, event)
            pageDispatch(updateExpandLevels({ mostExpandedLevel: nextLevel }))
          }
        } else {
          const prevLevel = findPreviousExpandableLevel(api)
          if (prevLevel >= 0) {
            collapseNodesAtLevel(api, prevLevel, event)
            const newMostExpandedLevel = Math.max(0, prevLevel - 1)
            pageDispatch(updateExpandLevels({ mostExpandedLevel: newMostExpandedLevel }))
          }
        }

        const newHasExpandableNodes = recalculateHasExpandableNodes(api)
        if (newHasExpandableNodes !== hasExpandableNodes) {
          pageDispatch(updateHasExpandableNodes({ hasExpandableNodes: newHasExpandableNodes }))
        }

        const newMostExpandedLevel = recalculateMostExpandedLevel(api)
        if (newMostExpandedLevel !== mostExpandedLevel) {
          pageDispatch(updateExpandLevels({ mostExpandedLevel: newMostExpandedLevel }))
        }
      })

      event.currentTarget.blur()
    },
    [mostExpandedLevel, maxExpandLevel, hasExpandableNodes, gridRef, gridRefs, pageDispatch]
  )

  const handleRestoreDefaultExpansion = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      restoreDefaultExpansion(
        pageDispatch,
        [gridRef as AgGridRef, ...gridRefs],
        initialMostExpandedLevel,
        event
      )
    },
    [gridRef, gridRefs, pageDispatch, initialMostExpandedLevel]
  )

  const isExpandOneLevelDisabled = !hasExpandableNodes
  const isCollapseOneLevelDisabled = mostExpandedLevel < 0

  if (isFilterDisplayModeList(filterDisplayMode)) {
    return <div />
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title='Expand 1 Level'>
        <Button
          onClick={(e) => toggleExpandCollapse(true, e)}
          size='action-bar'
          variant='ghost'
          disabled={isExpandOneLevelDisabled || !displayExpansionControls}
          className='m-1'
        >
          <Icon icon={<ExpandMore />} />
        </Button>
      </Tooltip>
      <Tooltip title='Restore to Default Expansion'>
        <Button
          onClick={handleRestoreDefaultExpansion}
          size='action-bar'
          variant='ghost'
          disabled={!displayExpansionControls}
          className='m-1'
        >
          <Icon icon={<ExpandDefault />} />
        </Button>
      </Tooltip>
      <Tooltip title='Collapse 1 Level'>
        <Button
          onClick={(e) => toggleExpandCollapse(false, e)}
          size='action-bar'
          variant='ghost'
          disabled={isCollapseOneLevelDisabled || !displayExpansionControls}
          className='m-1'
        >
          <Icon icon={<ExpandLess />} />
        </Button>
      </Tooltip>
    </div>
  )
}

ExpandCollapseControl.approximateWidth = 100

export default ExpandCollapseControl
