import { useQuery } from '@tanstack/react-query'

import axiosServices from '@utils/axios'
import { camelCaseKeys } from '@utils/case-conversion'

import useAuth from '@hooks/useAuth'

import { QUERY_STALE_TIME_DEFAULT } from 'queries/constants'

export interface IJobSchedule {
  id: number
  name: string
  replicationJobId?: number
  awsEventbridgeScheduleArn?: string
  awsEventbridgeTargetArn?: string
  connectedSourceSystemId?: number
  groupName?: string
  scheduleExpression: string
  scheduleExpressionTimezone?: string
  state: ScheduleStateEnum
  businessTimezone?: string
  scheduleType: string
  targetInput?: string
  parallelism?: number
  replicationJobIdFromTargetInput?: number
  friendlyName?: string
  description?: string
  scheduleConfigs?: string

  notificationProfileId?: string | number
}

export enum JobScheduleType {
  Rust = 'rust',
  NoneRust = 'normal',
  EmailReporting = 'email_reporting'
}

export const isJobScheduleEmailReporting = (
  scheduleType: JobScheduleType | string | undefined | null
): boolean => scheduleType === JobScheduleType.EmailReporting
export const isJobScheduleRust = (
  scheduleType: JobScheduleType | string | undefined | null
): boolean => scheduleType === JobScheduleType.Rust
export const isJobScheduleNoneRust = (
  scheduleType: JobScheduleType | string | undefined | null
): boolean => scheduleType === JobScheduleType.NoneRust

export enum ScheduleStateEnum {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED'
}

const fetchJobSchedules = async (
  businessId: number | undefined,
  connectedSourceSystemId?: string | undefined,
  scheduleType?: string,
  sourceID?: number | undefined | string
): Promise<IJobSchedule[]> => {
  let endpoint = `/businesses/${businessId}/data_replication_schedule`

  const queryParams = new URLSearchParams()
  if (connectedSourceSystemId) {
    queryParams.append('connected_source_system_id', connectedSourceSystemId)
  }

  if (scheduleType) {
    queryParams.append('schedule_type', scheduleType)
  }

  if (sourceID) {
    queryParams.append('source_id', sourceID.toString())
  }

  if (queryParams.toString()) {
    endpoint += `?${queryParams.toString()}`
  }

  const response = await axiosServices.get(endpoint)

  return camelCaseKeys(response.data) as IJobSchedule[]
}

export const useFetchJobSchedules = (
  connectedSourceSystemId?: string | undefined,
  scheduleType?: string,
  sourceID?: number | undefined | string
) => {
  const { user } = useAuth()

  return useQuery({
    queryKey: [
      'fetch-job-schedules',
      user?.business_id,
      connectedSourceSystemId,
      scheduleType,
      sourceID
    ],
    enabled: !!user?.business_id,
    queryFn: () =>
      fetchJobSchedules(user?.business_id, connectedSourceSystemId, scheduleType, sourceID),
    staleTime: QUERY_STALE_TIME_DEFAULT
  })
}
