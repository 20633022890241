import { useQuery } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'

import { IComponentResultsData } from '../types'
import { IClientComponentParameters, IServerComponentParamaters } from '../types/component-types'
import { frequencyToDateTrunc } from '../utils/frequency-date-trunc'

export const QUERY_KEY_PREFIX = 'fetch-component-results'

export const fetchComponentResults = async (
  id: number,
  business_id: number,
  params: Partial<IClientComponentParameters>
): Promise<IComponentResultsData> => {
  const { date_truncation, ...rest } = params
  const body: Partial<IServerComponentParamaters> = _.assign(
    {},
    rest,
    date_truncation && { date_truncation: frequencyToDateTrunc(date_truncation) }
  )

  const response = await axiosServices.post(
    `/businesses/${business_id}/components/${id}/results`,
    body
  )
  return response.data
}

export const useFetchComponentResults = (
  id: number,
  params: Partial<IClientComponentParameters>
) => {
  const { user } = useAuth()
  const business_id = user?.business_id!
  return useQuery({
    queryKey: [QUERY_KEY_PREFIX, id, business_id, params],
    queryFn: () => fetchComponentResults(id, business_id, params),
    enabled: !!business_id,
    retry: false
  })
}
