import React, { useMemo, useState } from 'react'

import { getRangeInfoFromDateFilterInfo } from '@utils/date-filter-utils'
import {
  getPropsRange,
  getTrailing12Months,
  getYearToDateRange,
  handleFrequencyChange
} from '@utils/date-frequency-utils'
import { enumToOptions } from '@utils/obj-utils'

import { DateComponentPicker } from '@components/control-panel/calendar-filter-popover/date-component-picker'
import QuickActionsPanel from '@components/control-panel/calendar-filter-popover/quick-actions-panel'
import { ComponentRenderMap } from '@components/control-panel/calendar-filter-popover/types'
import Button from '@components/core/button'
import { Separator } from '@components/core/separator'
import { Tabs, TabsList, TabsTrigger } from '@components/core/tab'
import { Text } from '@components/core/text'

import { usePageSelector } from '@store/index'
import { selectDateFilter } from '@store/slices/date-time-filter'

import {
  DateConfig,
  DateFilterInfo,
  DateInfo,
  DateRange,
  DateRangeType,
  Frequency,
  YearMonthDayInfo
} from 'types/filter'
import { Month } from 'utils/date-utils'

interface CalendarFilterPopoverProps {
  title?: React.ReactNode
  defaultFrequency?: Frequency
  rangeType?: DateRangeType
  fiscalYearStart?: Month
  dateFilterInfo?: DateFilterInfo
  isListMode?: boolean
  onClose: () => void
  onApply: (dateFilterInfo: DateFilterInfo) => void
  frequenciesToOmit?: Frequency[]
  minAllowed?: YearMonthDayInfo
  maxAllowed?: YearMonthDayInfo

  showFrequencyToggle?: boolean
  isFinancialReport?: boolean
}

export const CalendarFilterPopover = (props: CalendarFilterPopoverProps) => {
  const {
    dateFilterInfo,
    onApply,
    onClose,
    rangeType,
    isListMode = false,
    frequenciesToOmit = [Frequency.Daily, Frequency.Weekly, Frequency.MonthlyQuarterly],
    showFrequencyToggle = true,
    isFinancialReport = false
  } = props

  const globalFilter = usePageSelector(selectDateFilter)

  const dateFilter = dateFilterInfo ?? globalFilter?.filterInfo

  const fiscalYearStart = useMemo(() => {
    if (!dateFilter) return
    return props.fiscalYearStart || dateFilter.fiscalYearStart
  }, [dateFilter, props.fiscalYearStart])

  const asOfDate = useMemo(() => {
    if (!dateFilter) return

    return rangeType === DateRangeType.AsOfDate || dateFilter.rangeType === DateRangeType.AsOfDate
  }, [rangeType, dateFilter])

  const [frequency, setFrequency] = useState(dateFilter ? dateFilter.frequency : Frequency.Monthly)

  const [date, setDate] = useState<DateConfig>(() => {
    if (!dateFilter) {
      return {}
    }

    if (dateFilter.rangeType === DateRangeType.AsOfDate) {
      return { [frequency]: { endDate: dateFilter.endDate } }
    }

    return { [frequency]: { startDate: dateFilter.startDate, endDate: dateFilter.endDate } }
  })

  const updatePickerDate = (
    frequency: Frequency,
    field: 'startDate' | 'endDate',
    newDate: DateInfo
  ) => {
    setDate({
      ...date,
      [frequency]: {
        ...date[frequency],
        [field]: newDate
      }
    })
  }

  const updateFrequencyDateRange = (frequency: Frequency, newDateRange: DateRange) => {
    setDate({
      ...date,
      [frequency]: newDateRange
    })
  }

  const handleFreqChange = (
    freq: Frequency,
    oldRange: { startDate: DateInfo; endDate: DateInfo; frequency: Frequency }
  ) => {
    const propsRange = getPropsRange(freq, props.minAllowed, props.maxAllowed, fiscalYearStart)
    const newRange = handleFrequencyChange(oldRange, freq, propsRange, fiscalYearStart)
    updateFrequencyDateRange(freq, newRange)
    setFrequency(freq)
  }

  const freqDate =
    date[frequency] ||
    (frequency === Frequency.Monthly
      ? getTrailing12Months(fiscalYearStart)
      : getYearToDateRange(frequency, fiscalYearStart))

  const filter: DateFilterInfo = asOfDate
    ? {
        frequency,
        fiscalYearStart: fiscalYearStart,
        rangeType: DateRangeType.AsOfDate,
        endDate: freqDate.endDate
      }
    : {
        frequency,
        fiscalYearStart: fiscalYearStart,
        rangeType: DateRangeType.Range,
        startDate: freqDate.startDate,
        endDate: freqDate.endDate
      }

  const dateRange = getRangeInfoFromDateFilterInfo(filter)

  const handleApply = () => {
    const filter: DateFilterInfo = asOfDate
      ? {
          frequency,
          fiscalYearStart: fiscalYearStart,
          rangeType: DateRangeType.AsOfDate,
          endDate: freqDate.endDate
        }
      : {
          frequency,
          fiscalYearStart: fiscalYearStart,
          rangeType: DateRangeType.Range,
          startDate: freqDate.startDate,
          endDate: freqDate.endDate
        }
    onApply(filter)
  }

  const filteredFrequencies = enumToOptions(Frequency, frequenciesToOmit)

  return (
    <div className='flex min-h-56 w-full flex-1'>
      {!isListMode && (
        <QuickActionsPanel
          fiscalYearStart={fiscalYearStart}
          updateFrequencyDateRange={updateFrequencyDateRange}
          setFrequency={setFrequency}
          isFinancialReport={isFinancialReport}
        />
      )}

      <div className='flex flex-1 flex-col p-4'>
        <Text variant='h5'>Date Filter</Text>
        <Separator className='my-2 bg-grey-lighter'></Separator>

        {showFrequencyToggle && (
          <>
            <Tabs
              id='date-filter-tabs'
              defaultValue={Frequency.Monthly.toString()}
              className='w-full'
              onValueChange={(freq) => {
                const originalInfo = dateRange.originalInfo
                if (originalInfo.rangeType === DateRangeType.Range) {
                  handleFreqChange(Frequency[freq as Frequency], {
                    startDate: originalInfo.startDate,
                    endDate: originalInfo.endDate,
                    frequency: originalInfo.frequency
                  })
                } else {
                  throw new Error('Unsupported range')
                }
              }}
              value={frequency.toString()}
            >
              <div className='flex w-full justify-between p-2'>
                <div className='flex-1'>
                  <TabsList
                    className='grid w-full bg-grey-background'
                    style={{
                      gridTemplateColumns: `repeat(${filteredFrequencies.length}, minmax(0, 1fr))`
                    }}
                  >
                    {filteredFrequencies.map((freq) => (
                      <TabsTrigger key={freq.value} value={freq.value.toString()}>
                        {freq.value == Frequency.MonthlyQuarterly
                          ? 'Month Quarter'
                          : freq.value.toString()}
                      </TabsTrigger>
                    ))}
                  </TabsList>
                </div>
              </div>
            </Tabs>

            <Separator className='my-2 bg-grey-lighter'></Separator>
          </>
        )}

        <div className='flex items-center justify-between'>
          <Text variant='tableValue' className='flex-1 text-grey-details'>
            Date Range
          </Text>

          {['startDate', 'endDate']
            .filter((dateType) => dateType !== 'startDate' || !asOfDate)
            .flatMap((elem, index) => (index ? ['-', elem] : [elem]))
            .map((dateType) => {
              if (dateType === '-') {
                return (
                  <div className='mx-2 flex items-center' key={dateType}>
                    <span className='text-h5 text-grey-details'>-</span>
                  </div>
                )
              }

              const propsRange = getPropsRange(frequency)

              const commonProps = {
                frequency: frequency,
                fiscalYearStart,
                value: date[frequency]?.[dateType as 'startDate' | 'endDate'],
                minAllowed:
                  dateType === 'endDate' ? date[frequency]?.startDate : propsRange.minAllowed,
                maxAllowed:
                  dateType === 'startDate' ? date[frequency]?.endDate : propsRange.maxAllowed,
                onChange: (info: DateInfo) => updatePickerDate(frequency, dateType as any, info),
                render: fiscalYearStart
                  ? ((ComponentRenderMap as any)[frequency] as (info: DateInfo) => React.ReactNode)
                  : void 0
              }

              return (
                <div key={dateType}>
                  <DateComponentPicker key={dateType} {...commonProps} />
                </div>
              )
            })}
        </div>
        <Separator className='my-2 bg-grey-lighter'></Separator>
        <div className='flex items-center justify-between'>
          <Button variant='outline' onClick={onClose}>
            Cancel
          </Button>
          <Button variant='primary' onClick={handleApply}>
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}
