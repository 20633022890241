import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@components/core/select'

import { useDispatch } from '@store/index'
import { updateColumn } from '@store/slices/component/table-config'

import { ITableColumn, RoundingType, isCellDataTypeNumber } from '../../types/table-builder-types'

export const Rounding = ({ item: column }: { item: ITableColumn }) => {
  const dispatch = useDispatch()
  const disabled = !isCellDataTypeNumber(column.cellDataType) || column.hide
  const value = column.customData?.rounding || RoundingType.NONE

  return (
    <Select
      disabled={disabled}
      value={value}
      onValueChange={(value) =>
        dispatch(
          updateColumn({
            field: column.field,
            customData: { ...column.customData, rounding: value as RoundingType }
          })
        )
      }
    >
      <SelectTrigger>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value={RoundingType.NONE}>None</SelectItem>
        <SelectItem value={RoundingType.EXACT}>Exact</SelectItem>
        <SelectItem value={RoundingType.INTEGER}>Nearest Integer</SelectItem>
        <SelectItem value={RoundingType.ONE_DECIMAL}>1 Decimal Place</SelectItem>
        <SelectItem value={RoundingType.TWO_DECIMAL}>2 Decimal Places</SelectItem>
        <SelectItem value={RoundingType.THOUSANDS}>Nearest Thousands</SelectItem>
        <SelectItem value={RoundingType.MILLIONS}>Nearest Millions</SelectItem>
      </SelectContent>
    </Select>
  )
}
