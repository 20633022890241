import { useMutation, useQueryClient } from '@tanstack/react-query'

import { TreeItem } from 'components/tree/types'
import useAuth from 'hooks/useAuth'
import { querySuccessToast } from 'queries/query-toasts'
import { dispatch } from 'store'
import { openSnackbar } from 'store/reducers/snackbar'
import axiosServices from 'utils/axios'

export const useEditNode = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      nodeId,
      item,
      hierarchyId
    }: {
      nodeId: number
      item: TreeItem
      hierarchyId: number
    }) => {
      // rails updates the appropriate fields based on the identifier
      // NOTE: can not update identifier for GL Nodes
      const payload = {
        label: item.label,
        hierarchy_id: hierarchyId,
        referenced_node_id: item.referencedNodeId,
        show_on_report: item.showOnReport,
        flip_sign: item.flipSign
      }

      return axiosServices.put(
        `/businesses/${user?.business_id}/general_ledger_account_hierarchy_nodes/${nodeId}`,
        {
          general_ledger_account_hierarchy_node: payload
        }
      )
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: ['fetch-hierarchy-builder-nodes', user?.business_id, variables.hierarchyId]
      })
      queryClient.invalidateQueries({
        queryKey: ['fetch-all-hierarchies-with-nodes', user?.business_id]
      })
      queryClient.invalidateQueries({
        queryKey: ['fetch-report-tree', user?.business_id, variables.hierarchyId]
      })

      querySuccessToast('Node Updated Successfully')
    },
    onError: (error, variables) => {
      dispatch(
        openSnackbar({
          open: true,
          message: `Error Editing GL Hierarchy Node. ${error?.message}`,
          variant: 'alert',
          alert: { color: 'error' },
          close: false
        })
      )
    }
  })
}
