import { useCreateNotificationProfile } from '@components/control-panel/report-scheduler/queries/notification-profiles/create-notification-profile'
import { useUpdateNotificationProfile } from '@components/control-panel/report-scheduler/queries/notification-profiles/update-notification-profile'
import { INotificationProfile } from '@components/control-panel/types'
import { ChargerFormFieldConfig, FieldTypes, FormLayoutTypes } from '@components/form/types'

import { ChargerForm } from 'components/form/charger-form'
import { isBlank } from 'utils/lodash'
import * as Yup from 'yup'

export const NotificationProfileForm = ({
  existingProfile,
  onSuccess,
  onCancel
}: {
  existingProfile?: INotificationProfile | null
  onSuccess?: () => void
  onCancel?: () => void
}) => {
  const fieldsConfig: ChargerFormFieldConfig[] = _.compact([
    isBlank(existingProfile)
      ? undefined
      : {
          label: 'Notification Profile ID',
          name: 'id',
          type: FieldTypes.TEXT_FIELD,
          defaultValue: existingProfile?.id,
          hidden: true
        },
    {
      label: 'Notification Profile Name',
      name: 'name',
      type: FieldTypes.TEXT_FIELD,
      defaultValue: existingProfile?.name,
      isDisabled: false,
      validationSchema: Yup.string().trim().required('Required')
    },
    {
      label: 'Description',
      name: 'description',
      isDisabled: false,
      type: FieldTypes.TEXT_FIELD,
      defaultValue: existingProfile?.description
    }
  ]) as ChargerFormFieldConfig[]

  const { mutate: createNotificationProfile } = useCreateNotificationProfile()
  const { mutate: updateNotificationProfile } = useUpdateNotificationProfile()

  return (
    <div className='w-full max-w-[500px]'>
      <ChargerForm
        layout={FormLayoutTypes.POPOVER}
        title={isBlank(existingProfile) ? undefined : `Edit ${existingProfile?.name}`}
        fieldsConfig={fieldsConfig}
        onSubmit={(payload: INotificationProfile) => {
          isBlank(existingProfile)
            ? createNotificationProfile({ payload, onSuccess })
            : updateNotificationProfile({ payload, onSuccess })
        }}
        onCancel={onCancel}
        submitLabel={isBlank(existingProfile) ? 'Create' : 'Update'}
      />
    </div>
  )
}
