import { Switch } from '@components/core/switch'

import { useDispatch } from '@store/index'
import { updateColumn } from '@store/slices/component/table-config'

import { ITableColumn, isCellDataTypeNumber } from '../../types/table-builder-types'

export const NoCommaSeparator = ({ item: column }: { item: ITableColumn }) => {
  const dispatch = useDispatch()

  const disabled = !isCellDataTypeNumber(column.cellDataType) || column.hide

  const value = !!column.customData?.noCommaSeparator

  return (
    <div className='flex items-center'>
      <Switch
        disabled={disabled}
        checked={value}
        onCheckedChange={(checked) =>
          dispatch(updateColumn({ field: column.field, customData: { noCommaSeparator: checked } }))
        }
      />
    </div>
  )
}
