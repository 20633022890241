import { useState } from 'react'

import { ConfirmationDialog } from '@core/custom/confirmation-dialog'

import Button from '@components/core/button'
import { Icon, Trash } from '@components/icons'

export interface IConfirmationData {
  id: number
}

interface DeleteCellRendererProps {
  data: IConfirmationData
  onDelete: (data: IConfirmationData) => void
}

export function DeleteCellRenderer({ data, onDelete }: DeleteCellRendererProps) {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const handleDeleteClick = () => {
    setShowDeleteDialog(true)
  }

  const handleConfirmDelete = () => {
    onDelete(data)
    setShowDeleteDialog(false)
  }

  return (
    <div className='flex size-full items-center'>
      <Button
        size='action-bar'
        variant='text'
        className='rounded-[4px] p-0 focus:border-hidden'
        onClick={handleDeleteClick}
      >
        <Icon icon={<Trash />} className='text-red-600 hover:text-red-800' />
      </Button>

      <ConfirmationDialog
        open={showDeleteDialog}
        onOpenChange={setShowDeleteDialog}
        title='Delete Item'
        description='Are you sure you want to delete this item? This action cannot be undone.'
        confirmLabel='Delete'
        cancelLabel='Cancel'
        onConfirm={handleConfirmDelete}
      />
    </div>
  )
}
