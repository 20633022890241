import { Fragment, useEffect, useState } from 'react'

import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Input,
  ListItem,
  SelectChangeEvent
} from '@mui/material'

import { useDebounce } from '@uidotdev/usehooks'

import { IconButton } from '@components/core/icon-button'

import { useDispatch, useSelector } from '@store/index'
import { selectFieldConfigs, setFieldConfigs } from '@store/slices/component/query-config'

import MuiSelect from 'components/form/mui-select'
import { Icon, PlusCircle, Trash } from 'components/icons'

import {
  AggregateOptions,
  AggregateType,
  CutoffDateOptions,
  CutoffDateType,
  ExpandLevelType,
  IFieldAttribute,
  NoneType,
  QueryBuilderPlacement,
  TruncateOptions,
  TruncateType
} from '../../types/query-builder-types'
import { fieldSelected } from '../../utils/field-config-utils'
import generateFieldAlias from '../../utils/generate-field-alias'

const clearableFields: Partial<Record<keyof IFieldAttribute, Array<keyof IFieldAttribute>>> = {
  is_date_hook: ['truncate', 'is_year_over_year'],
  cutoffDate: ['is_date_hook', 'truncate', 'is_year_over_year', 'as_of_date'],
  presence: [
    'aggregate',
    'truncate',
    'is_date_hook',
    'is_year_over_year',
    'as_of_date',
    'expand_level',
    'show_total',
    'drilldown_field',
    'cutoffDate'
  ]
}

function FieldConfig({
  fieldName,
  numericField,
  dateField,
  stringField,
  booleanField,
  kpiHeadingField,
  kpiFormulaField,
  config,
  removeConfig,
  updateConfig,
  totalConfigs
}: {
  fieldName: string
  numericField: boolean
  dateField: boolean
  stringField: boolean
  booleanField: boolean
  kpiHeadingField?: boolean
  kpiFormulaField?: boolean
  config: Partial<IFieldAttribute>
  totalConfigs: number
  removeConfig: () => void
  updateConfig: (changes: Partial<IFieldAttribute>) => void
}) {
  const {
    aggregate = NoneType.NONE,
    truncate = NoneType.NONE,
    is_hidden = false,
    is_date_hook = false,
    is_year_over_year = false,
    as_of_date = false,
    name_alias = '',
    expand_level = '0',
    show_total = false,
    drilldown_field = false,
    cutoffDate = NoneType.NONE,
    presence = false
  } = config

  const [alias, setAlias] = useState(() => name_alias || fieldName)
  const onChangeAlias = (e: React.ChangeEvent<HTMLInputElement>) => setAlias(e.target.value)
  const debouncedName = useDebounce(alias, 500)

  useEffect(() => {
    updateConfig({ name_alias: debouncedName })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- updateConfig is not memoized
  }, [debouncedName])

  const handleChange = <T extends keyof IFieldAttribute>(key: T, value: IFieldAttribute[T]) => {
    const changes = { [key]: value } as Partial<IFieldAttribute>
    if (_.includes(_.keys(clearableFields), key)) {
      clearableFields[key]?.forEach((field) => {
        changes[field] = undefined
      })
    }
    if (_.includes(['aggregate', 'truncate', 'cutoffDate', 'presence'], key)) {
      changes.name_alias = generateFieldAlias({
        fieldName,
        previousAlias: alias,
        aggregate,
        cutoffDate,
        presence,
        [key]: value
      })
      setAlias(changes.name_alias)
    }

    updateConfig(changes)
  }

  const kpiField = kpiHeadingField || kpiFormulaField

  const cutoffDateSelected = fieldSelected(cutoffDate)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid item xs={12} container alignItems='center' sx={{ '&.MuiGrid-item': { pt: 0 } }}>
          <Box mr={1} flexGrow={1}>
            <Input value={alias} onChange={onChangeAlias} fullWidth />
          </Box>
          {totalConfigs > 1 && (
            <IconButton onClick={removeConfig} aria-label='Delete configuration'>
              <Icon icon={<Trash />} />
            </IconButton>
          )}
        </Grid>
      </Grid>
      {!kpiField && (
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={presence}
                onChange={(e) => handleChange('presence', e.target.checked)}
              />
            }
            label='Presence'
          />
        </Grid>
      )}
      {!presence && numericField && (
        <Grid item>
          <MuiSelect
            options={[
              { value: NoneType.NONE, label: 'None' },
              { value: AggregateOptions.SUM, label: 'Sum' },
              { value: AggregateOptions.AVG, label: 'Avg' },
              { value: AggregateOptions.COUNT, label: 'Count' },
              { value: AggregateOptions.COUNT_DISTINCT, label: 'Count Distinct' },
              { value: AggregateOptions.MIN, label: 'Min' },
              { value: AggregateOptions.MAX, label: 'Max' }
            ]}
            value={aggregate}
            onChange={(e: SelectChangeEvent<string>) => {
              handleChange('aggregate', e.target.value as AggregateType)
            }}
            label='Aggregate'
            fullWidth={false}
          />
        </Grid>
      )}
      {!presence && stringField && (
        <Grid item>
          <MuiSelect
            options={[
              { value: NoneType.NONE, label: 'None' },
              { value: AggregateOptions.COUNT, label: 'Count' },
              { value: AggregateOptions.COUNT_DISTINCT, label: 'Count Distinct' },
              { value: AggregateOptions.MIN, label: 'Min' },
              { value: AggregateOptions.MAX, label: 'Max' }
            ]}
            value={aggregate}
            onChange={(e: SelectChangeEvent<string>) => {
              handleChange('aggregate', e.target.value as AggregateType)
            }}
            label='Aggregate'
            fullWidth={false}
          />
        </Grid>
      )}
      {!presence && booleanField && (
        <Grid item>
          <MuiSelect
            options={[
              { value: NoneType.NONE, label: 'None' },
              { value: AggregateOptions.COUNT, label: 'Count' },
              { value: AggregateOptions.COUNT_DISTINCT, label: 'Count Distinct' }
            ]}
            value={aggregate}
            onChange={(e: SelectChangeEvent<string>) => {
              handleChange('aggregate', e.target.value as AggregateType)
            }}
            label='Aggregate'
            fullWidth={false}
          />
        </Grid>
      )}
      {!presence && dateField && !cutoffDateSelected && (
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={is_date_hook}
                onChange={(e) => handleChange('is_date_hook', e.target.checked)}
              />
            }
            label='Date Hook'
          />
        </Grid>
      )}
      {!presence && dateField && (
        <Grid item>
          <MuiSelect
            options={[
              { value: NoneType.NONE, label: 'None' },
              { value: CutoffDateOptions.CURRENT_DATE, label: 'Current Date' },
              { value: CutoffDateOptions.START_DATE, label: 'Start Date' },
              { value: CutoffDateOptions.END_DATE, label: 'End Date' }
            ]}
            value={cutoffDate}
            onChange={(e: SelectChangeEvent<string>) => {
              handleChange('cutoffDate', e.target.value as CutoffDateType)
            }}
            label='Cutoff Date'
            fullWidth={false}
          />
        </Grid>
      )}
      {!presence && dateField && !is_date_hook && !cutoffDateSelected && (
        <Grid item>
          <MuiSelect
            options={[
              { value: NoneType.NONE, label: 'None' },
              { value: TruncateOptions.DATE_FREQUENCY_HOOK, label: 'Date Frequency Hook' },
              { value: TruncateOptions.DAY, label: 'Day' },
              { value: TruncateOptions.MONTH, label: 'Month' },
              { value: TruncateOptions.QUARTER, label: 'Quarter' },
              { value: TruncateOptions.YEAR, label: 'Year' }
            ]}
            value={truncate}
            onChange={(e: SelectChangeEvent<string>) => {
              handleChange('truncate', e.target.value as TruncateType)
            }}
            label='Truncate'
            fullWidth={false}
          />
        </Grid>
      )}
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              checked={is_hidden}
              onChange={(e) => handleChange('is_hidden', e.target.checked)}
            />
          }
          label='Hide'
        />
      </Grid>
      {((!presence && dateField && !!is_date_hook && !cutoffDateSelected) || kpiField) && (
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={is_year_over_year}
                onChange={(e) => handleChange('is_year_over_year', e.target.checked)}
              />
            }
            label='Is Year Over Year'
          />
        </Grid>
      )}
      {!presence && dateField && is_date_hook && !cutoffDateSelected && (
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={as_of_date}
                onChange={(e) => handleChange('as_of_date', e.target.checked)}
              />
            }
            label='As of Date'
          />
        </Grid>
      )}
      {!presence && stringField && (
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={drilldown_field}
                onChange={(e) => handleChange('drilldown_field', e.target.checked)}
              />
            }
            label='Drilldown Field'
          />
        </Grid>
      )}
      {kpiField && (
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={show_total}
                onChange={(e) => handleChange('show_total', e.target.checked)}
              />
            }
            label='Show Total'
          />
        </Grid>
      )}
      {kpiHeadingField && (
        <Grid item>
          <MuiSelect
            options={[
              { value: ExpandLevelType.ZERO, label: '0' },
              { value: ExpandLevelType.ONE, label: '1' },
              { value: ExpandLevelType.TWO, label: '2' }
            ]}
            value={expand_level}
            onChange={(e: SelectChangeEvent<string>) => {
              handleChange('expand_level', e.target.value as ExpandLevelType)
            }}
            label='Expand Level'
            fullWidth={false}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default function FieldConfigs({
  labelId,
  field_type,
  parent,
  name,
  placement
}: {
  labelId: string
  field_type: string
  parent: string
  name: string
  placement?: QueryBuilderPlacement
}) {
  const numericField = _.includes(['integer', 'float', 'decimal', 'number'], field_type)
  const dateField = _.includes(['datetime', 'date', 'timestamp'], field_type)
  const stringField = _.includes(['string', 'enum', 'text'], field_type)
  const booleanField = _.includes(['boolean'], field_type)
  const kpiHeadingField = _.includes(['heading'], field_type)
  const kpiFormulaField = _.includes(['formula'], field_type)
  const kpiField = kpiHeadingField || kpiFormulaField

  const dispatch = useDispatch()
  const fieldConfigs = useSelector(selectFieldConfigs(name, parent, placement))

  const [configs, setConfigs] = useState(fieldConfigs)

  const addConfig = () => {
    const newConfigs = [...configs, {}]
    setConfigs(newConfigs)
    dispatch(setFieldConfigs({ fieldName: name, path: parent, configs: newConfigs, placement }))
  }

  const removeConfig = (index: number) => {
    const newConfigs = configs.filter((_, i) => i !== index)
    setConfigs(newConfigs)
    dispatch(setFieldConfigs({ fieldName: name, path: parent, configs: newConfigs, placement }))
  }

  const updateConfig = (index: number, changes: Partial<IFieldAttribute>) => {
    const newConfigs = [...configs]
    newConfigs[index] = { ...newConfigs[index], ...changes }
    setConfigs(newConfigs)
    dispatch(setFieldConfigs({ fieldName: name, path: parent, configs: newConfigs, placement }))
  }

  return (
    <ListItem sx={{ border: '1px solid black' }}>
      <Grid container pt={2} spacing={2}>
        {configs.map((config, index) => (
          <Fragment key={index}>
            <Grid item xs={12}>
              <FieldConfig
                {...{
                  fieldName: name,
                  totalConfigs: configs.length,
                  labelId,
                  numericField,
                  dateField,
                  stringField,
                  booleanField,
                  kpiHeadingField,
                  kpiFormulaField,
                  config,
                  removeConfig: () => removeConfig(index),
                  updateConfig: (changes) => updateConfig(index, changes)
                }}
              />
            </Grid>
            {index !== configs.length - 1 && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
          </Fragment>
        ))}

        {!kpiField && (
          <Grid item xs={12} container alignItems='center' sx={{ '&.MuiGrid-item': { pt: 0 } }}>
            <Box flexGrow={1}>
              <Divider />
            </Box>
            <IconButton onClick={() => addConfig()}>
              <Icon icon={<PlusCircle />} />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </ListItem>
  )
}
