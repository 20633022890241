import { Month, getFrequencyAndDateRangeForQuickActions } from '@utils/date-utils'

import Button from '@components/core/button'
import { Separator } from '@components/core/separator'
import { Text } from '@components/core/text'

import useAuth from '@hooks/useAuth'

import { DateRange, Frequency } from '../../../types/filter'
import { QuickActionType } from './types'

const QuickActionsPanel = ({
  fiscalYearStart,
  updateFrequencyDateRange,
  setFrequency,
  isFinancialReport = false
}: {
  fiscalYearStart: Month | undefined
  updateFrequencyDateRange: (frequency: Frequency, newDateRange: DateRange) => void
  setFrequency: (frequency: Frequency) => void
  isFinancialReport?: boolean
}) => {
  const { user } = useAuth()

  const handleQuickActionSelection = (quickActionType: QuickActionType) => {
    const { frequency, dateRange } = getFrequencyAndDateRangeForQuickActions({
      quickActionType,
      user,
      isFinancialReport
    })
    updateFrequencyDateRange(frequency, dateRange)
    setFrequency(frequency)
  }

  return (
    <div className='flex basis-48 flex-col bg-grey-background p-4'>
      <div className='flex flex-1 flex-col items-center'>
        <Button
          variant='outline'
          className='mb-2 h-7 w-32'
          onClick={() => handleQuickActionSelection(QuickActionType.YTD)}
        >
          Year To Date
        </Button>
        <Button
          variant='outline'
          className='mb-2 h-7 w-32'
          onClick={() => handleQuickActionSelection(QuickActionType.PREVIOUS_YEAR)}
        >
          Previous Year
        </Button>
        <Button
          variant='outline'
          className='mb-2 h-7 w-32'
          onClick={() => handleQuickActionSelection(QuickActionType.TTM)}
        >
          TTM
        </Button>
        <Button
          variant='outline'
          className='mb-2 h-7 w-32'
          onClick={() => handleQuickActionSelection(QuickActionType.LAST_MONTH)}
        >
          Last Month
        </Button>
        <Button
          variant='outline'
          className='mb-2 h-7 w-32'
          onClick={() => handleQuickActionSelection(QuickActionType.CURRENT_MONTH)}
        >
          Current Month
        </Button>
      </div>
      <div className='flex flex-col items-center'>
        <Separator className='my-2 bg-grey'></Separator>
        <Text variant='details'>Quick Actions</Text>
      </div>
    </div>
  )
}

export default QuickActionsPanel
