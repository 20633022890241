import { useMutation } from '@tanstack/react-query'

import axiosServices from '@utils/axios'
import { snakeCaseKeys } from '@utils/case-conversion'

import useAuth from '@hooks/useAuth'

import { queryErrorToast, querySuccessToast } from 'queries/query-toasts'

export interface ISendTestScheduledReportMailBody {
  id: number
}

export interface ISendTestScheduledReportMailProps {
  onSuccess?: () => void
}

export const useSendTestScheduledReportMail = (props: ISendTestScheduledReportMailProps) => {
  const { user } = useAuth()

  return useMutation({
    mutationFn: (body: ISendTestScheduledReportMailBody) => {
      const payload: any = { data_replication_schedule: {} }

      const { id } = body
      payload.data_replication_schedule = snakeCaseKeys({ id })

      return axiosServices.post(
        `/businesses/${user?.business_id}/data_replication_schedule/${id}/send_test_scheduled_report_mail`,
        payload
      )
    },
    onSuccess: (data) => {
      props.onSuccess?.()
      querySuccessToast(data?.data?.message || 'Test email sent successfully')
    },
    onError: (error: any) => {
      queryErrorToast(error.message)
    }
  })
}
