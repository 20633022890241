import { useMemo, useState } from 'react'

import Button from '@components/core/button'
import { Input } from '@components/core/input'
import { Popover, PopoverContent, PopoverTrigger } from '@components/core/popover'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@components/core/select'
import { Separator } from '@components/core/separator'

import { CURRENCIES } from '@pages/property-renaming/constants'
import { useAddSubsidiary } from '@pages/property-renaming/queries/add-subsidiary'

export function AddSubsidiaryPopover() {
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [name, setName] = useState('')
  const [currency, setCurrency] = useState('USD')
  const { mutate: addSubsidiary } = useAddSubsidiary()

  const currencies = useMemo(() => {
    return CURRENCIES
  }, [])

  return (
    <Popover open={popoverOpen} onOpenChange={(open) => setPopoverOpen(open)}>
      <PopoverTrigger asChild>
        <Button variant='outline' size='extra-small'>
          Add New Subsidiary
        </Button>
      </PopoverTrigger>

      <PopoverContent>
        <div className='flex flex-col'>
          <div className='flex justify-between'>
            <Button variant='outline' size='small' onClick={() => setPopoverOpen(false)}>
              Cancel
            </Button>
            <Button
              variant={!name ? 'disabled' : 'primary'}
              size='small'
              disabled={!name}
              onClick={() => {
                addSubsidiary({ name, currency_code: currency })
              }}
            >
              Add Subsidiary
            </Button>
          </div>
          <Separator className='my-2' />
          <div className='flex flex-col gap-2'>
            <Input
              type='text'
              placeholder='Name'
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Input>
            <Select defaultValue='USD' value={currency} onValueChange={(curr) => setCurrency(curr)}>
              <SelectTrigger>
                <SelectValue placeholder='Select Currency' />
              </SelectTrigger>
              <SelectContent>
                {currencies.map((curr) => (
                  <SelectItem key={curr.code} value={curr.code}>
                    <span className='font-normal'>{curr.name}</span>{' '}
                    <span className='font-semibold'>({curr.code})</span>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}
