import React from 'react'

import {
  Month,
  getFiscalYearFromCalendarYear,
  getMonthFromIndex,
  getYearHeading
} from '@utils/date-utils'

import { Select } from '@components/core/select'
import { SelectContent, SelectItem, SelectTrigger, SelectValue } from '@components/core/select'

import { store } from '@store/index'

import { YearInfo } from 'types/filter'

export interface YearPickerProps {
  fiscalYearStart?: Month
  value: YearInfo
  maxAllowed?: YearInfo
  minAllowed?: YearInfo
  onChange: (year: YearInfo) => void
  render?: (year: YearInfo) => React.ReactNode
  suffix?: string
  yearsList?: YearInfo[]
}

export const getYears = (fiscalYear?: Month): YearInfo[] => {
  const currentYear = new Date().getFullYear()
  const earliestTransactionYear =
    store.getState().auth.user?.business_earliest_transaction_year || currentYear - 1
  const latestTransactionYear =
    store.getState().auth.user?.business_latest_transaction_year || currentYear

  const range = 11
  const fiscalCurrentYear = getFiscalYearFromCalendarYear(
    latestTransactionYear,
    getMonthFromIndex(new Date().getMonth()),
    fiscalYear
  )
  const defaultStartYear = fiscalCurrentYear - range
  const start = earliestTransactionYear ?? defaultStartYear
  const end = fiscalCurrentYear
  const years = new Array(end - start + 1).fill(0).map((_, idx) => ({ year: idx + start }))
  return years
}

const toValue = (info?: YearInfo) => (!info || !info.year ? undefined : `${info?.year}`)
const fromValue = (value: string) => ({ year: +value })

const outOfBounds = (info: YearInfo, minAllowed?: YearInfo, maxAllowed?: YearInfo) => {
  if (minAllowed && info.year < minAllowed.year) return true
  if (maxAllowed && info.year > maxAllowed.year) return true
  return false
}

export const YearPicker = (props: YearPickerProps) => {
  const { fiscalYearStart, value, onChange, render, maxAllowed, minAllowed, suffix, yearsList } =
    props

  const years = yearsList ?? getYears(fiscalYearStart)

  return (
    <div className='w-auto'>
      <Select
        value={toValue(value)}
        onValueChange={(value) => onChange(fromValue(value))}
        defaultValue={toValue(value)}
      >
        <SelectTrigger variant='outline' layout='inline'>
          <SelectValue placeholder='Select Year' className='w-auto' />
        </SelectTrigger>
        <SelectContent>
          {years
            .filter((yearInfo) => yearInfo.year !== null && yearInfo.year !== undefined)
            .map((info) => (
              <SelectItem
                key={info.year}
                value={toValue(info) as string}
                disabled={outOfBounds(info, minAllowed, maxAllowed)}
                className='flex h-9 w-full justify-between rounded-none data-[state=checked]:bg-[#E9F0FD] data-[state=checked]:text-black'
              >
                {render
                  ? render(info)
                  : `${getYearHeading(info.year, fiscalYearStart)}${suffix ? ` ${suffix}` : ''}`}
              </SelectItem>
            ))}
        </SelectContent>
      </Select>
    </div>
  )
}
