import { CSSProperties, ReactNode, Ref, forwardRef, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Card,
  CardContent,
  CardContentProps,
  CardHeader,
  CardHeaderProps,
  CardProps,
  LinearProgress,
  useMediaQuery
} from '@mui/material'
// material-ui
import { Theme, useTheme } from '@mui/material/styles'

import { Separator } from '@core/separator'

import { usePageDispatch, usePageSelector } from '@store/index'
import { selectRightDrawerOpen, setRightDrawer } from '@store/slices/action-bar'
import {
  selectHeaderCollapsed,
  toggleHeaderCollapse,
  updateCurrentPage
} from '@store/slices/navigation'

import { PageSettings } from '@pages/layout-view/types'

import Button from 'components/core/button'
import Tooltip from 'components/core/tooltip'
import { KeyedObject } from 'types/root'
import { cn } from 'utils/style-utils'

import { rightDrawerWidth } from '../config'
import RightDrawer from '../layout/MainLayout/RightDrawer'
import { FILTER_IDENTIFIER } from '../types/page-filters'
import ErrorBoundary from './ErrorBoundary'
import ToggleDimensionFilters from './control-panel/toggle-dimension-filters'
import { PageError } from './fallback/page-error'
import { ChevronUp, Icon } from './icons'

// header style
const headerLG = (actionAlign: 'left' | 'right') => ({
  p: '0',
  marginBottom: '0.25rem',
  '& .MuiCardHeader-action': {
    m: 0,
    display: 'flex',
    alignSelf: 'center',
    flex: 1,
    overflow: 'hidden',
    justifyContent: actionAlign === 'left' ? 'flex-start' : 'flex-end'
  },
  '& .MuiCardHeader-content': {
    flex: 'none'
  }
})

const headerSX = (actionAlign: 'left' | 'right') => ({
  p: '0.25rem 0.5rem 0',
  marginBottom: '0.25rem',
  '& .MuiCardHeader-action': {
    m: 0,
    display: 'flex',
    alignSelf: 'center',
    flex: 1,
    overflow: 'hidden',
    justifyContent: actionAlign === 'left' ? 'flex-start' : 'flex-end'
  },
  '& .MuiCardHeader-content': {
    flex: 'none'
  }
})

// ==============================|| CUSTOM - MAIN CARD ||============================== //

export interface MainCardProps extends KeyedObject {
  border?: boolean
  boxShadow?: boolean
  children?: ReactNode | string
  subheader?: ReactNode | string
  secondaryAlign?: 'left' | 'right'
  style?: CSSProperties
  content?: boolean
  contentSX?: CardContentProps['sx']
  darkTitle?: boolean
  divider?: boolean
  sx?: CardProps['sx']
  secondary?: CardHeaderProps['action']
  shadow?: string
  elevation?: number
  title?: ReactNode | string
  modal?: boolean
  refetching?: boolean
  pageSettings?: PageSettings
}

const ActionWrapper = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch()

  const headerCollapsed = useSelector(selectHeaderCollapsed)

  return (
    <div className='flex flex-1 items-center gap-2'>
      <div
        className={
          'flex h-11 w-full items-center gap-2 border border-l-0 border-t-0 border-solid border-grey bg-white px-2 py-[0.3125rem]'
        }
      >
        <Tooltip title={headerCollapsed ? 'Show header' : 'Collapse header'}>
          <Button
            variant='ghost'
            size='action-bar'
            onClick={() => dispatch(toggleHeaderCollapse())}
          >
            <div className={cn('transition-transform', headerCollapsed && 'rotate-180')}>
              <Icon icon={<ChevronUp />} />
            </div>
          </Button>
        </Tooltip>

        <Separator orientation='vertical' className='mt-[-5px] h-[38px]' />

        <div className='flex w-full justify-between'>{children}</div>
      </div>
    </div>
  )
}

const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      subheader,
      content = true,
      secondaryAlign = 'left',
      contentSX = {},
      darkTitle,
      divider = true,
      elevation,
      secondary,
      shadow,
      sx = {},
      title,
      modal = false,
      refetching,
      pageSettings,
      ...others
    }: MainCardProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const pageDispatch = usePageDispatch()

    const rightDrawerOpen = usePageSelector(selectRightDrawerOpen)

    boxShadow = theme.palette.mode === 'dark' ? boxShadow || true : boxShadow
    const matchesSmOrLarger = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

    const hasDimensionFilters = useMemo(() => {
      if (!pageSettings) return false

      const searchFilters = _.get(pageSettings, 'settings.search_filters', {})
      return _.has(searchFilters, _.camelCase(FILTER_IDENTIFIER.DIMENSION_FILTERS))
    }, [pageSettings])

    const handleRightDrawerClose = () => {
      pageDispatch(setRightDrawer({ rightDrawerOpen: false }))
    }

    useEffect(() => {
      if (pageSettings) {
        dispatch(
          updateCurrentPage({
            pageSettings: pageSettings
          })
        )
      }
    }, [dispatch, pageSettings])

    return (
      <ErrorBoundary fallback={<PageError />}>
        <Card
          className={cn('main-card bg-transparent', _.get(pageSettings, 'settings.className'))}
          elevation={elevation || 0}
          ref={ref}
          {...others}
          sx={{
            background: 'none',
            height: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            border: border ? '1px solid' : 'none',
            borderColor:
              theme.palette.mode === 'dark' ? theme.palette.divider : theme.palette.grey.A800,
            boxShadow:
              boxShadow && (!border || theme.palette.mode === 'dark')
                ? shadow || theme.customShadows.z1
                : 'inherit',
            ':hover': {
              boxShadow: boxShadow ? shadow || theme.customShadows.z1 : 'inherit'
            },
            ...(modal && {
              position: 'absolute' as const,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: `calc( 100% - 50px)`, sm: 'auto' },
              '& .MuiCardContent-root': {
                overflowY: 'auto',
                minHeight: 'auto',
                maxHeight: `calc(100vh - 200px)`
              }
            }),
            ...sx
          }}
        >
          {/* card header and action */}
          {title && (
            <CardHeader
              className='card-header'
              sx={matchesSmOrLarger ? headerLG(secondaryAlign) : headerSX(secondaryAlign)}
              titleTypographyProps={{ variant: 'h4' }}
              title={_.isBoolean(title) ? null : title}
              action={
                secondary || hasDimensionFilters ? (
                  <ActionWrapper>
                    {secondary}
                    {hasDimensionFilters && <ToggleDimensionFilters />}
                  </ActionWrapper>
                ) : undefined
              }
              subheader={!darkTitle ? subheader : undefined}
            />
          )}

          {refetching && <LinearProgress color='primary' />}

          {/* card content */}
          {content && (
            <CardContent
              className='card-content flex flex-col'
              sx={{
                padding: '1rem',
                paddingTop: title ? 0 : undefined,
                width: rightDrawerOpen ? `calc(100% - ${rightDrawerWidth})` : '100%',
                flex: (contentSX as any)?.height != null ? undefined : 1,
                ...contentSX
              }}
            >
              {children}
              <RightDrawer open={rightDrawerOpen} handleDrawerClose={handleRightDrawerClose} />
            </CardContent>
          )}
          {!content && children}
        </Card>
      </ErrorBoundary>
    )
  }
)

export default MainCard
