import { useMutation, useQueryClient } from '@tanstack/react-query'

import axiosServices from '@utils/axios'
import { isNotNil } from '@utils/lodash'
import { errorSnack, successSnack } from '@utils/snackbar-utils'

import { INotificationProfilePayload } from '@components/control-panel/types'

import useAuth from '@hooks/useAuth'

export const useUpdateNotificationProfile = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      payload,
      onSuccess,
      onError
    }: {
      payload: Partial<INotificationProfilePayload>
      onSuccess?: () => void
      onError?: () => void
    }) => {
      const { name, description, userIds } = payload

      return axiosServices.put(
        `/businesses/${user?.business_id}/notification_profiles/${payload.id}`,
        {
          notification_profile: {
            name,
            description,
            user_ids: isNotNil(userIds) ? userIds : undefined
          }
        }
      )
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['fetch-notification-profiles', user?.business_id]
      })
      successSnack('Notification Profile Updated Successfully')
      variables.onSuccess && variables.onSuccess()
    },
    onError: (error, variables) => {
      variables.onError && variables.onError()
      errorSnack(`Error Updating Notification Profile: ${error.message}`)
    }
  })
}
