import React, { Ref, forwardRef, useCallback, useEffect, useState } from 'react'

import { cn } from '@utils/style-utils'

import DropDownIconButton from '@components/DropDownIconButton'
import DateRangeQuickSelect from '@components/control-panel/shared/date-range-quick-select'
import { Popover, PopoverContent, PopoverTrigger } from '@components/core/popover'
import { Icon } from '@components/icons'

import { setDisplayExpansionControls } from '@store/slices/action-bar'

import { Separator } from 'components/core/separator'
import Tooltip from 'components/core/tooltip'
import { Calendar } from 'components/icons/calendar'
import { usePageDispatch, usePageSelector } from 'store'
import { selectExpandedDateFilterInfo, updateDateFilter } from 'store/slices/date-time-filter'
import { DateFilterInfo, Frequency, YearMonthDayInfo } from 'types/filter'

import { CalendarFilterPopover } from './calendar-filter-popover/calendar-filter-popover'
import DateFilterFrequencyQuickSelect from './shared/date-filter-frequency-quick-select'
import { BaseFilterProps, FilterDisplayModeEnum, isFilterDisplayModeList } from './types'

export const SecondaryRangeDefaultStatus = false

interface DateFilterControlProps extends BaseFilterProps {
  title?: React.ReactNode
  frequenciesToOmit?: Frequency[]
  minAllowed?: YearMonthDayInfo
  maxAllowed?: YearMonthDayInfo
  onOpen?: () => void
  onApply?: () => void
  defaultDateFilter?: DateFilterInfo
  showFrequencyToggle?: boolean
  showSecondaryFrequencyToggle?: boolean
  showSecondaryRangeSelector?: boolean
  isFinancialReport?: boolean
}

const CalendarIconComponent = forwardRef((props, ref: Ref<HTMLButtonElement>) => {
  return (
    <Tooltip title='Date Range'>
      <Icon icon={<Calendar />} {...props} className='m-1' />
    </Tooltip>
  )
})

const DateFilterControl = (props: DateFilterControlProps) => {
  const {
    filter,
    showFrequencyToggle: showFrequencyToggleProp,
    showSecondaryFrequencyToggle: showSecondaryFrequencyToggleProp,
    showSecondaryRangeSelector: showSecondaryRangeSelectorProp,
    isFinancialReport: isFinancialReportProp
  } = props

  const pageDispatch = usePageDispatch()
  const expandedDateFilterInfo = usePageSelector(selectExpandedDateFilterInfo)
  const [popoverOpen, setPopoverOpen] = useState(false)

  useEffect(() => {
    if (popoverOpen) {
      props.onOpen?.()
    }
  }, [props, popoverOpen])

  const handleRangeChange = useCallback(
    (dateFilter: DateFilterInfo) => {
      pageDispatch(updateDateFilter(dateFilter))
      pageDispatch(setDisplayExpansionControls({ displayExpansionControls: false }))
      setPopoverOpen(false)
    },
    [pageDispatch]
  )

  useEffect(() => {
    if (_.isNil(props.defaultDateFilter)) return

    handleRangeChange(props.defaultDateFilter)
  }, [handleRangeChange, props.defaultDateFilter])

  if (!expandedDateFilterInfo) {
    return <></>
  }

  const listMode = isFilterDisplayModeList(
    props?.filterDisplayMode ?? FilterDisplayModeEnum.default
  )

  const showFrequencyToggle = showFrequencyToggleProp ?? filter?.showFrequencyToggle ?? true

  const showSecondaryFrequencyToggle =
    showSecondaryFrequencyToggleProp ?? filter?.showSecondaryFrequencyToggle ?? true

  const showSecondaryRangeSelector =
    showSecondaryRangeSelectorProp ??
    filter?.showSecondaryRangeSelector ??
    SecondaryRangeDefaultStatus

  const handleApply = (dateFilterInfo: DateFilterInfo) => {
    setPopoverOpen(false)
    handleRangeChange(dateFilterInfo)
    props.onApply?.()
  }

  return (
    <div className='flex items-center space-x-2'>
      <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
        <PopoverTrigger asChild>
          <DropDownIconButton size='action-bar'>
            <CalendarIconComponent></CalendarIconComponent>
          </DropDownIconButton>
        </PopoverTrigger>
        <PopoverContent className={cn('flex', listMode ? 'w-full flex-1' : 'w-[640px] p-0')}>
          <CalendarFilterPopover
            frequenciesToOmit={props.frequenciesToOmit}
            minAllowed={props.minAllowed}
            maxAllowed={props.maxAllowed}
            title={props.title}
            isListMode={listMode}
            dateFilterInfo={_.get(expandedDateFilterInfo, 'originalInfo')}
            onApply={handleApply}
            onClose={() => setPopoverOpen(false)}
            showFrequencyToggle={showFrequencyToggle}
            isFinancialReport={isFinancialReportProp}
          />
        </PopoverContent>
      </Popover>

      {showFrequencyToggle && showSecondaryFrequencyToggle && (
        <>
          <Separator orientation='vertical' className='h-[38px]' />
          <DateFilterFrequencyQuickSelect
            frequenciesToOmit={props.frequenciesToOmit}
            dateFilterInfo={expandedDateFilterInfo?.originalInfo}
            onApply={props.onApply}
          />
        </>
      )}

      {showSecondaryRangeSelector && (
        <>
          <Separator orientation='vertical' className='h-[38px]' />
          <DateRangeQuickSelect />
        </>
      )}
    </div>
  )
}

DateFilterControl.aproximateWidth = 180
export default DateFilterControl
