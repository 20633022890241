import { useCallback } from 'react'

import { FilterChangedEvent, GridApi, IRowNode, RowGroupOpenedEvent } from 'ag-grid-community'

import { AgGridRef } from '@contexts/grid-ref-context'
import { restoreExpansionWithClick } from '@utils/ag_grid/restore-expansion-with-click'

import { setMaxExpansionLevel, setMostExpandedLevel } from '@components/ag-grid'
import { autoFitAllColumns } from '@components/ag-grid/utils'
import {
  recalculateHasExpandableNodes,
  recalculateMostExpandedLevel
} from '@components/control-panel/expand-collapse-control'

import { useDispatch, usePageDispatch, usePageSelector } from '@store/index'
import {
  selectHasExpandableNodes,
  selectMaxExpandLevel,
  selectMostExpandedLevel,
  updateExpandLevels,
  updateHasExpandableNodes,
  updateMaxExpandLevel
} from '@store/slices/action-bar'
import { addExpandedGroup, removeExpandedGroup } from '@store/slices/expanded-groups'

interface UseExpandLevelHandlerProps {
  persistExpandedGroups?: boolean
  combinedId: string
  gridRef?: AgGridRef | null
}

const TIMEOUT_DELAY = 100

// Utility function to generate a unique group ID
export const getGroupId = (node: IRowNode): string => {
  const keys = []
  let currentNode = node
  while (currentNode) {
    if (currentNode.key != null) {
      keys.unshift(currentNode.key)
    }
    currentNode = currentNode.parent as IRowNode
  }
  return keys.join('/')
}

export const useExpandLevelHandler = ({
  persistExpandedGroups = false,
  combinedId,
  gridRef = null
}: UseExpandLevelHandlerProps) => {
  const pageDispatch = usePageDispatch()
  const dispatch = useDispatch()

  const currentMaxExpandLevel = usePageSelector(selectMaxExpandLevel)
  const currentHasExpandableNodes = usePageSelector(selectHasExpandableNodes)
  const currentMostExpandedLevel = usePageSelector(selectMostExpandedLevel)

  const onRowDataUpdated = useCallback(
    (params: { api: GridApi }) => {
      const api = params.api
      let maxLevel = 0

      api.forEachNode((node: IRowNode) => {
        if (node.group) {
          maxLevel = Math.max(maxLevel, node.level)
        }
      })

      if (!_.eq(maxLevel, currentMaxExpandLevel)) {
        pageDispatch(updateMaxExpandLevel({ maxExpandLevel: maxLevel }))
      }

      setTimeout(() => {
        const newHasExpandableNodes = recalculateHasExpandableNodes(api)
        if (!_.eq(newHasExpandableNodes, currentHasExpandableNodes)) {
          pageDispatch(updateHasExpandableNodes({ hasExpandableNodes: newHasExpandableNodes }))
        }
      }, TIMEOUT_DELAY)
    },
    [pageDispatch, currentMaxExpandLevel, currentHasExpandableNodes]
  )

  const onRowGroupOpened = useCallback(
    (event: RowGroupOpenedEvent<any, any>) => {
      if (!event.event) return // skip if it's not from a user action

      if (persistExpandedGroups) {
        const groupId = getGroupId(event.node)
        const isExpanded = event.node.expanded && event.node.group
        if (isExpanded) {
          dispatch(addExpandedGroup({ id: combinedId, group: groupId }))
        } else {
          dispatch(removeExpandedGroup({ id: combinedId, group: groupId }))
        }
      }

      if (!event.node.expanded) {
        // Collapse all child nodes with BFS when a node is collapsed
        const collapseChildren = (node: IRowNode) => {
          const queue = [node]

          while (queue.length > 0) {
            const currentNode = queue.shift()!
            if (currentNode.childrenAfterGroup) {
              for (const child of currentNode.childrenAfterGroup) {
                if (child.group) {
                  queue.push(child)
                  child.expanded = false
                  if (persistExpandedGroups) {
                    const groupId = getGroupId(child)
                    dispatch(removeExpandedGroup({ id: combinedId, group: groupId }))
                  }
                }
              }
            }
          }
        }

        collapseChildren(event.node)
      }

      // Recalculate hasExpandableNodes and mostExpandedLevel
      const newHasExpandableNodes = recalculateHasExpandableNodes(event.api)
      const newMostExpandedLevel = recalculateMostExpandedLevel(event.api)

      if (!_.isEqual(newHasExpandableNodes, currentHasExpandableNodes)) {
        pageDispatch(updateHasExpandableNodes({ hasExpandableNodes: newHasExpandableNodes }))
      }

      if (!_.isEqual(newMostExpandedLevel, currentMostExpandedLevel)) {
        pageDispatch(updateExpandLevels({ mostExpandedLevel: newMostExpandedLevel }))
      }

      event.api.onGroupExpandedOrCollapsed()
    },
    [
      persistExpandedGroups,
      currentHasExpandableNodes,
      currentMostExpandedLevel,
      dispatch,
      combinedId,
      pageDispatch
    ]
  )

  const onFilterChanged = useCallback(
    (event: FilterChangedEvent) => {
      if (_.eq(event.source, 'api')) return

      const allFilters = event.api.getFilterModel()
      const isAnyFilterActive = Object.keys(allFilters).length > 0

      gridRef && restoreExpansionWithClick(pageDispatch, gridRef)

      if (isAnyFilterActive) {
        let mostExpandedLevel = 0
        let maxLevel = 0

        event.api.forEachNode((node: IRowNode) => {
          if (node.group) {
            const childrenAfterFilter = node.childrenAfterFilter || []
            const filterEvent = { type: 'filter' } as MouseEvent

            if (childrenAfterFilter.length > 0) {
              node.setExpanded(true, filterEvent)
              mostExpandedLevel = Math.max(mostExpandedLevel, node.level + 1)
              maxLevel = Math.max(maxLevel, node.level + 1)
            } else {
              node.setExpanded(false, filterEvent)
            }
          }
        })

        mostExpandedLevel = Math.max(0, mostExpandedLevel)
        maxLevel = Math.max(0, maxLevel)

        pageDispatch(updateExpandLevels({ mostExpandedLevel }))
        pageDispatch(updateMaxExpandLevel({ maxExpandLevel: maxLevel }))

        setMostExpandedLevel(mostExpandedLevel)
        setMaxExpansionLevel(maxLevel)
      }
      autoFitAllColumns(event)
    },
    [pageDispatch, gridRef]
  )

  return { onRowDataUpdated, onRowGroupOpened, onFilterChanged }
}
