import { useMutation, useQueryClient } from '@tanstack/react-query'

import axiosServices from '@utils/axios'

import useAuth from '@hooks/useAuth'

import { queryErrorToast, querySuccessToast } from 'queries/query-toasts'

export interface IDeleteJobScheduleProps {
  onSuccess?: () => void
}

export const useDeleteJobSchedule = (props: IDeleteJobScheduleProps) => {
  const queryClient = useQueryClient()
  const { user } = useAuth()

  return useMutation({
    mutationFn: (id: number) => {
      return axiosServices.delete(
        `/businesses/${user?.business_id}/data_replication_schedule/${id}`
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fetch-job-schedules', user?.business_id] })
      props.onSuccess?.()
      querySuccessToast('Schedule deleted successfully')
    },
    onError: (error: any) => {
      queryErrorToast(error.message)
    }
  })
}
