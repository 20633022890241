import {
  AgGridEvent,
  ColDef,
  Column,
  ColumnEvent,
  GridApi,
  IRowNode,
  RowGroupOpenedEvent,
  RowModelType,
  SizeColumnsToContentStrategy
} from 'ag-grid-community'
import { NavigateToNextCellParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { isNotEmpty } from '@utils/lodash'

import { Skeleton } from '@components/core/skeleton'

import { quantileSorted } from 'simple-statistics'

import skeletonCellRenderer from './skeleton-cell-renderer'

const HEADER_PADDING = 35
const MENU_BUTTON_PADDING = 20
const DATE_FILTER_PADDING = 80
const GENERIC_FILTER_PADDING = 50
const PADDING_PER_LEVEL = 30
const DATA_PADDING = 40
const PADDING = 25

const BACKSTOP_WIDTH = 40

// Define the character widths for each font in use.
const CHAR_WIDTHS_BY_FONT: Record<
  string,
  { filterCharWidth: number; contentCharWidth: number; contentCharGroupWidth: number }
> = {
  Roboto: { filterCharWidth: 5.02, contentCharWidth: 5.28, contentCharGroupWidth: 5.47 }
}

const DEFAULT_FONT_FAMILY = 'Roboto'

const CONTENT_PERCENTILE_DEFAULT = 0.9
const CONTENT_PERCENTILE_GROUP = 0.95
const CONTENT_PERCENTILE_NUMBER = 1.0

const NUMBER_PERCENTILE_THRESHOLD = 0.8

const DISPLAY_ROW_LIMIT = 1000

const COLUMNS_WITH_FULL_WIDTH = ['source_system_account_id']
const COLORED_COLUMNS_CLASS_NAME = 'colored-columns'

interface ColumnWidthInfo {
  newWidth: number
  maxContentWidth?: number
}

interface GridContext {
  hasPersistedColumnState?: boolean
  gridContainerRef?: React.RefObject<HTMLDivElement>
}

function getFontFamilyForColumn(column: Column): string {
  const cellStyle = column.getColDef()?.cellStyle

  let resolvedStyle: Record<string, any> | null = null
  if (typeof cellStyle === 'function') {
    const mockRowNode = {
      data: {}
    } as unknown as IRowNode<any>

    resolvedStyle =
      cellStyle({
        node: mockRowNode,
        colDef: column.getColDef(),
        column: column,
        value: null,
        data: mockRowNode.data,
        rowIndex: 0,
        context: null,
        api: {} as GridApi<any>
      }) || null
  } else if (typeof cellStyle === 'object' && cellStyle !== null) {
    resolvedStyle = cellStyle
  }

  if (resolvedStyle && resolvedStyle.fontFamily) {
    return resolvedStyle.fontFamily
  }

  return 'Roboto'
}

function getCharWidthsForFont(fontFamily: string) {
  return CHAR_WIDTHS_BY_FONT[fontFamily] || CHAR_WIDTHS_BY_FONT[DEFAULT_FONT_FAMILY]
}

export const addPinnedFilterIcon = (
  agGridWrapperElement: HTMLDivElement,
  agGrid: AgGridReact,
  pinnedColumn?: string
) => {
  const elements = agGridWrapperElement
    .querySelector('.ag-header')!
    .querySelectorAll<HTMLElement>('.ag-filter-icon[ref=eFilter]')

  elements.forEach((element) => {
    processFilterIconElement(element, agGrid, pinnedColumn)
  })
}

const processFilterIconElement = (
  element: HTMLElement,
  agGrid: AgGridReact,
  pinnedColumn?: string
) => {
  const closestHeader = element.closest('.ag-header-cell') as HTMLElement | null
  const colId = closestHeader?.getAttribute('col-id')
  if (!colId) return

  element.classList.remove('pinned-red-icon')
  if (_.eq(colId, pinnedColumn)) {
    element.classList.remove('ag-hidden')
    element.classList.add('pinned-red-icon')
  } else if (!agGrid.api.getColumn(colId)?.isFilterActive()) {
    element.classList.add('ag-hidden')
  }
  element.onclick = function (e: MouseEvent) {
    e.stopPropagation()
    agGrid.api.showColumnFilter(colId)
  }
}

export const autoFitColumnsWithAPI = (api: GridApi) => {
  if (!api || api.isDestroyed()) return
  autoFitAllColumns(undefined, api)
}

export const autoFitAllColumns = (
  event?: ColumnEvent | RowGroupOpenedEvent | AgGridEvent,
  api?: GridApi
) => {
  api = api ? api : event?.api
  if (!api || api.isDestroyed()) {
    console.warn('autoFitAllColumns aborted: API is null or destroyed')
    return
  }

  const context = api.getGridOption('context') as GridContext
  const hasPersistedColumnState = context?.hasPersistedColumnState

  if (hasPersistedColumnState) {
    return
  }

  const autoSizeStrategy = api?.getGridOption('autoSizeStrategy')
  switch (autoSizeStrategy?.type) {
    case 'fitGridWidth':
      api.sizeColumnsToFit()
      break
    case 'fitCellContents': {
      const fitCellContentsStrategy = autoSizeStrategy as SizeColumnsToContentStrategy
      const skipHeader = fitCellContentsStrategy?.skipHeader || false
      const colIds = fitCellContentsStrategy?.colIds || []

      const allColumns = api.isPivotMode()
        ? api.getPivotResultColumns() || []
        : api.getColumns() || []

      const columnWidthsMap: Record<string, ColumnWidthInfo> = {}

      if (isNotEmpty(colIds)) {
        api.autoSizeColumns(colIds, skipHeader)
        colIds.forEach((colId) => {
          calculateWidthWithFiltersAndTypes(colId, api, context, skipHeader, columnWidthsMap)
        })
      } else {
        api.autoSizeAllColumns(skipHeader)
        allColumns.forEach((col) => {
          calculateWidthWithFiltersAndTypes(
            col.getColId(),
            api,
            context,
            skipHeader,
            columnWidthsMap,
            col
          )
        })
      }

      const totalDesiredWidth = Object.values(columnWidthsMap).reduce(
        (sum, widths) => sum + widths.newWidth,
        0
      )

      const gridContainerRef = context?.gridContainerRef
      const gridWidth =
        gridContainerRef && gridContainerRef.current ? gridContainerRef.current.clientWidth : 0

      if (gridWidth > 0 && totalDesiredWidth < gridWidth) {
        adjustColumnsWithExtraSpace(columnWidthsMap)
      }

      adjustColumnWidthsToConstraints(api, columnWidthsMap)

      const columnWidths = Object.entries(columnWidthsMap).map(([key, { newWidth }]) => ({
        key,
        newWidth
      }))
      if (columnWidths.length > 0) {
        api.setColumnWidths(columnWidths, true)
      }

      break
    }
    default:
      break
  }
}

const isNumberColumnBasedOnValues = (column: Column, colId: string, api: GridApi): boolean => {
  let numberCount = 0
  let totalCount = 0

  const model = api.getGridOption('rowModelType') as RowModelType

  if (model === 'clientSide') {
    const columnData: any[] = [] // To collect data for the column

    api.forEachNodeAfterFilterAndSort((node: IRowNode) => {
      if (!node.data) {
        return
      }

      // Get the first key-value pair from node.data
      const firstKey = Object.keys(node.data)[0]
      const value = firstKey ? _.get(node.data, firstKey) : undefined
      columnData.push(value) // Collect the data for this column

      if (value !== null && value !== undefined) {
        totalCount++
        if (typeof value === 'number' || (!isNaN(value) && value !== '')) {
          numberCount++
        }
      }
    })
  }

  const isNumberColumn = totalCount > 0 && numberCount / totalCount >= NUMBER_PERCENTILE_THRESHOLD

  // If more than 80% of the values are numbers, treat it as a number column
  return isNumberColumn
}

const calculateWidthWithFiltersAndTypes = (
  colId: string,
  api: GridApi,
  context: GridContext,
  skipHeader: boolean,
  columnWidthsMap: Record<string, ColumnWidthInfo>,
  column?: Column
) => {
  if (!column) {
    column = api.getColumn(colId) || undefined
  }
  if (!column) {
    console.warn('Column not found for ID:', colId)
    return
  }

  // Determine if the column is a number column
  const isNumberColumn =
    column.getColDef()?.type === 'numericColumn' ||
    column.getColDef()?.type === 'rightAligned' ||
    isNumberColumnBasedOnValues(column, colId, api)

  // Determine the font to calculate widths.
  const fontFamily = getFontFamilyForColumn(column)

  const { filterCharWidth, contentCharWidth, contentCharGroupWidth } =
    getCharWidthsForFont(fontFamily)

  const contentPercentile = isNumberColumn
    ? CONTENT_PERCENTILE_NUMBER
    : getContentPercentile(column)
  const filterWidth = calculateFilterWidth(column, filterCharWidth)
  const headerWidth = skipHeader ? 0 : calculateHeaderWidth(column, api, filterCharWidth)
  const contentWidths = getContentWidths(
    column,
    colId,
    api,
    contentCharWidth,
    contentCharGroupWidth
  )

  const maxContentWidthFromData = getMaxContentWidthFromData(contentWidths, colId, isNumberColumn)
  if (isNumberColumn) {
    // Directly calculate maxContentWidth for numeric columns
    columnWidthsMap[colId] = {
      newWidth: Math.max(filterWidth, headerWidth, maxContentWidthFromData, BACKSTOP_WIDTH),
      maxContentWidth: maxContentWidthFromData
    }
  } else {
    // Perform full calculations for non-numeric columns
    const percentileWidth = calculatePercentileWidth(contentWidths, contentPercentile)
    const newWidth = Math.round(Math.max(filterWidth, headerWidth, percentileWidth, BACKSTOP_WIDTH))

    columnWidthsMap[colId] = {
      newWidth: newWidth,
      maxContentWidth: maxContentWidthFromData || newWidth
    }
  }
}

const getContentPercentile = (column: Column): number => {
  const isGroupColumn = column.getUserProvidedColDef()?.cellRenderer === 'agGroupCellRenderer'
  return isGroupColumn ? CONTENT_PERCENTILE_GROUP : CONTENT_PERCENTILE_DEFAULT
}

const calculateFilterWidth = (column: Column, filterCharWidth: number): number => {
  const filterInstance = column.getColDef()?.filter
  if (!filterInstance) return 0

  if (column.getColDef().filter === 'agDateColumnFilter') {
    // e.g. 10 characters for date filter + padding
    return filterCharWidth * 10 + DATE_FILTER_PADDING
  } else {
    return GENERIC_FILTER_PADDING
  }
}

const calculateHeaderWidth = (column: Column, api: GridApi, filterCharWidth: number): number => {
  let headerText = api.getDisplayNameForColumn(column, 'header') || ''
  const parent = column.getParent()
  if (headerText === '' && parent) {
    headerText = api.getDisplayNameForColumnGroup(parent, 'header') || ''
  }

  if (column.getColDef()?.wrapHeaderText) {
    headerText = removeLastWord(headerText)
  }

  let headerWidth = filterCharWidth * headerText.length
  if (String(column.getColDef()?.cellClass).includes(COLORED_COLUMNS_CLASS_NAME)) {
    headerWidth += MENU_BUTTON_PADDING
  }
  headerWidth += HEADER_PADDING
  if (!column.getColDef()?.suppressHeaderMenuButton) {
    headerWidth += MENU_BUTTON_PADDING
  }
  if (column.getColDef()?.sortable || column.getColDef()?.sortable === undefined) {
    headerWidth += MENU_BUTTON_PADDING
  }
  return headerWidth
}

const removeLastWord = (text: string): string => {
  const lastSpaceIndex = text.lastIndexOf(' ')
  return lastSpaceIndex !== -1 ? text.substring(0, lastSpaceIndex) : text
}

const getContentWidths = (
  column: Column,
  colId: string,
  api: GridApi,
  contentCharWidth: number,
  contentCharGroupWidth: number
): number[] => {
  const dataType = column.getUserProvidedColDef()?.type

  const contentWidths: number[] = []
  if (dataType === 'date') {
    // e.g., YYYY-MM-DD => ~10 characters
    const dateWidth = contentCharWidth * 10 + PADDING
    contentWidths.push(dateWidth)
  } else if (dataType === 'boolean') {
    // True / False => up to 5 characters
    const booleanWidth = contentCharWidth * 5 + PADDING
    contentWidths.push(booleanWidth)
  } else if (isGroupColumn(column)) {
    collectGroupColumnContentWidths(api, contentWidths, contentCharWidth, contentCharGroupWidth)
  } else {
    collectContentWidthsForColumn(
      column,
      colId,
      api,
      contentWidths,
      contentCharWidth,
      contentCharGroupWidth
    )
  }

  return contentWidths
}

const isGroupColumn = (column: Column): boolean =>
  column.getUserProvidedColDef()?.cellRenderer === 'agGroupCellRenderer'

const collectGroupColumnContentWidths = (
  api: GridApi,
  contentWidths: number[],
  contentCharWidth: number,
  contentCharGroupWidth: number
) => {
  api.forEachNodeAfterFilterAndSort((node: IRowNode) => {
    const labelLength = node.data?.label?.length ?? 0

    let currentNodeContentWidth =
      (node.group ? contentCharGroupWidth : contentCharWidth) * labelLength
    currentNodeContentWidth += node.level * PADDING_PER_LEVEL
    contentWidths.push(currentNodeContentWidth)
  })
}

const collectContentWidthsForColumn = (
  column: Column,
  colId: string,
  api: GridApi,
  contentWidths: number[],
  contentCharWidth: number,
  contentCharGroupWidth: number
) => {
  const model = api.getGridOption('rowModelType') as RowModelType

  if (model === 'serverSide') {
    collectServerSideContentWidths(
      colId,
      api,
      contentWidths,
      contentCharWidth,
      contentCharGroupWidth
    )
  } else {
    collectClientSideContentWidths(
      column,
      colId,
      api,
      contentWidths,
      contentCharWidth,
      contentCharGroupWidth
    )
  }
}

const collectServerSideContentWidths = (
  colId: string,
  api: GridApi,
  contentWidths: number[],
  contentCharWidth: number,
  contentCharGroupWidth: number
) => {
  let displayedRowCount = api.getDisplayedRowCount() || 0

  if (displayedRowCount > DISPLAY_ROW_LIMIT) {
    displayedRowCount = DISPLAY_ROW_LIMIT
  }

  for (let i = 0; i < displayedRowCount; i++) {
    const rowNode = api.getDisplayedRowAtIndex(i)
    if (rowNode && rowNode.data) {
      const label = extractLabelFromRowData(rowNode.data, colId)
      const labelStr = label != null ? String(label) : ''
      const labelLength = labelStr.length
      let currentNodeContentWidth =
        (rowNode.group ? contentCharGroupWidth : contentCharWidth) * labelLength
      currentNodeContentWidth += (rowNode.level + 1) * PADDING_PER_LEVEL
      contentWidths.push(currentNodeContentWidth)
    }
  }
}

const collectClientSideContentWidths = (
  column: Column,
  colId: string,
  api: GridApi,
  contentWidths: number[],
  contentCharWidth: number,
  contentCharGroupWidth: number
) => {
  api.forEachNodeAfterFilter((node: IRowNode) => {
    if (!node.displayed) {
      return
    }
    const value = api.getCellValue({ colKey: column, rowNode: node })
    let valueStr =
      api.getCellValue({
        colKey: column,
        rowNode: node,
        useFormatter: true
      }) ?? ''

    if (!isNaN(value as any) && typeof value === 'number') {
      const formattedValue = formatNumericValue(value as number, node.data, column.getColId())

      valueStr = formattedValue
    }
    const valueLength = valueStr.length
    const currentNodeContentWidth = contentCharWidth * valueLength + DATA_PADDING
    if (valueStr !== '') {
      contentWidths.push(currentNodeContentWidth)
    }
  })
}

// Adds extra spaces to align positive and negative numbers
const formatNumericValue = (value: number, data: any, colId: string): string => {
  let finalValue = value

  if (finalValue === 0) {
    return '- '
  }

  if (colId.toLowerCase().includes('percent')) {
    finalValue = value * 100

    return finalValue < 0 ? `(${Math.abs(finalValue).toFixed(1)}%)` : `${finalValue.toFixed(1)}% `
  } else {
    const roundedValue = Math.round(finalValue).toLocaleString()

    return finalValue < 0 ? `(${roundedValue.slice(1)})` : `${roundedValue}`
  }
}

const extractLabelFromRowData = (data: any, colId: string): string => {
  let label = data[colId] || ''
  if (label === '') {
    const colIdWithoutLabel = colId.endsWith('.label') ? colId.slice(0, -6) : colId
    label = getLabelFromDataProperties(data, colIdWithoutLabel)
  }
  return label
}

const getLabelFromDataProperties = (data: any, colIdWithoutLabel: string): string => {
  let label = ''
  if (colIdWithoutLabel.endsWith('_coalesce')) {
    label = getCoalesceLabel(data, colIdWithoutLabel)
  } else if (colIdWithoutLabel.endsWith('_confirmed')) {
    label = getConfirmedOrSourceLabel(data, colIdWithoutLabel, 'confirmed')
  } else if (colIdWithoutLabel.endsWith('_source')) {
    label = getConfirmedOrSourceLabel(data, colIdWithoutLabel, 'source')
  } else {
    label = _.get(data, colIdWithoutLabel, '')
  }
  return label
}

const getCoalesceLabel = (data: any, colIdWithoutLabel: string): string => {
  const property = colIdWithoutLabel.slice(0, -'_coalesce'.length)
  let label = _.get(data, `properties.${property}.confirmed`, '')
  if (_.isEmpty(label)) {
    label = _.get(data, `properties.${property}.source`, '')
  }
  if (_.isEmpty(label)) {
    label = _.get(data, `standaloneProperties.${property}.confirmed`, '')
    if (_.isEmpty(label)) {
      label = _.get(data, `standaloneProperties.${property}.source`, '')
    }
  }
  if (_.isEmpty(label)) {
    label = _.get(data, `additionalFields.${property}`, '')
  }
  return label
}

const getConfirmedOrSourceLabel = (
  data: any,
  colIdWithoutLabel: string,
  sourceOrConfirmed: string
): string => {
  const property = colIdWithoutLabel.slice(0, -`_${sourceOrConfirmed}`.length)
  let label = _.get(data, `properties.${property}.${sourceOrConfirmed}`, '')
  if (_.isEmpty(label)) {
    label = _.get(data, `standaloneProperties.${property}.${sourceOrConfirmed}`, '')
  }
  if (_.isEmpty(label)) {
    label = _.get(data, `additionalFields.${property}`, '')
  }
  return label
}

const calculatePercentileWidth = (contentWidths: number[], contentPercentile: number): number => {
  if (contentWidths.length > 0) {
    contentWidths.sort((a, b) => a - b)
    return quantileSorted(contentWidths, contentPercentile)
  }
  return 0
}

const getMaxContentWidthFromData = (
  contentWidths: number[],
  colId: string,
  isNumberColumn: boolean
): number => {
  if ((COLUMNS_WITH_FULL_WIDTH.includes(colId) || isNumberColumn) && contentWidths.length > 0) {
    return Math.max(...contentWidths)
  }
  return 0
}

const adjustColumnsWithExtraSpace = (columnWidthsMap: Record<string, ColumnWidthInfo>) => {
  COLUMNS_WITH_FULL_WIDTH.forEach((colId) => {
    const widths = columnWidthsMap[colId]
    if (widths && widths.maxContentWidth) {
      columnWidthsMap[colId].newWidth = widths.maxContentWidth
    }
  })
}

const adjustColumnWidthsToConstraints = (
  api: GridApi,
  columnWidthsMap: Record<string, ColumnWidthInfo>
) => {
  Object.entries(columnWidthsMap).forEach(([colId, widthInfo]) => {
    const column = api.getColumn(colId)
    if (column) {
      const minWidth = column.getMinWidth()
      const maxWidth = column.getMaxWidth()
      if (minWidth != null && widthInfo.newWidth < minWidth) {
        widthInfo.newWidth = minWidth
      }
      if (maxWidth != null && widthInfo.newWidth > maxWidth) {
        widthInfo.newWidth = maxWidth
      }
    }
  })
}

export const fixedColumnWidths = (
  columnDefs: ColDef[],
  fieldWidthMapping: Record<string, number>
) => {
  return _.map(columnDefs, (colDef) =>
    _.assign(
      {},
      colDef,
      colDef.field && fieldWidthMapping[colDef.field]
        ? { minWidth: fieldWidthMapping[colDef.field], maxWidth: fieldWidthMapping[colDef.field] }
        : {}
    )
  )
}

export const autoselectRowOnNavigateToNextCell = (params: NavigateToNextCellParams) => {
  const suggestedNextCell = params.nextCellPosition
  if (!suggestedNextCell) return null

  const KEY_UP = 'ArrowUp'
  const KEY_DOWN = 'ArrowDown'

  if (![KEY_DOWN, KEY_UP].includes(params.key)) {
    return suggestedNextCell
  }

  params.api.forEachNode((node) => {
    if (_.eq(node.rowIndex, suggestedNextCell.rowIndex)) {
      node.setSelected(true)
    }
  })

  return suggestedNextCell
}

export const getSkeletonColumns = (n = 5) =>
  _.times(n, () => ({
    field: 'skeleton',
    headerComponent: () => <Skeleton className='h-4 w-full min-w-20' />,
    cellRenderer: skeletonCellRenderer
  }))
