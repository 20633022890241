import { getDisplayName } from '@utils/schema-renaming'

import { useFetchRenamings } from '@pages/configuration/queries/fetch-renamings'

import {
  DimensionFilterSortOrder,
  DimensionNameObject,
  SelectedDimensionsKeys
} from 'components/control-panel/types'
import pluralize from 'pluralize'

import {
  DimensionFilterEnum,
  DimensionFilterType,
  Dimensions,
  SelectedDimensions,
  SelectedDimensionsType
} from '../components/control-panel/types'
import { capitalizeFirstLetter } from './string-utils'

export const selectedDimensionKeyFromDimensionType = (
  dimensionType: DimensionFilterType | keyof Dimensions
): SelectedDimensionsType => {
  switch (dimensionType) {
    case DimensionFilterEnum.subsidiaries:
      return SelectedDimensionsKeys.subsidiary_name_ids
    default:
      return `${dimensionType?.slice(0, -1)}_name_ids` as SelectedDimensionsType
  }
}

export const useDimensionLabel = (
  dimensionType: DimensionFilterType | keyof Dimensions
): string => {
  const { data: renamingsData } = useFetchRenamings(true)
  const renamings = renamingsData || {}

  const modelName = pluralize
    .singular(dimensionType)
    .split('_')
    .map((word) => capitalizeFirstLetter(word))
    .join('')

  const displayName = getDisplayName(renamings, modelName)

  const label = displayName
    ? displayName
    : dimensionType
        .split('_')
        .map((word) => capitalizeFirstLetter(word))
        .join(' ')

  return pluralize(label)
}

export const glTransactionsFilterKeys = {
  [DimensionFilterEnum.business_locations]: 'business_location_name',
  [DimensionFilterEnum.departments]: 'department_name',
  [DimensionFilterEnum.subsidiaries]: 'subsidiary_name',
  [DimensionFilterEnum.general_ledger_classifications]: 'classification_name',
  [DimensionFilterEnum.customers]: 'customers',
  [DimensionFilterEnum.verticals]: 'vertical_name',
  [DimensionFilterEnum.projects]: 'project_name'
}

export const dimensionKeyToDimensionTypeMap = {
  [SelectedDimensionsKeys.business_location_name_ids]: 'business_locations',
  [SelectedDimensionsKeys.department_name_ids]: 'departments',
  [SelectedDimensionsKeys.subsidiary_name_ids]: 'subsidiaries',
  [SelectedDimensionsKeys.general_ledger_classification_name_ids]: 'general_ledger_classifications',
  [SelectedDimensionsKeys.customer_name_ids]: 'customers',
  [SelectedDimensionsKeys.vertical_name_ids]: 'verticals',
  [SelectedDimensionsKeys.project_name_ids]: 'projects',
  [SelectedDimensionsKeys.customer_group_name_ids]: 'customer_groups'
}

export const SortDimensionFilters = (a: string, b: string) => {
  const indexA = DimensionFilterSortOrder.indexOf(a as DimensionFilterEnum)
  const indexB = DimensionFilterSortOrder.indexOf(b as DimensionFilterEnum)

  if (indexA === -1) return 1
  if (indexB === -1) return -1

  return indexA - indexB
}

export const selectedDimensionsPayload = <T extends SelectedDimensions>(payload: Partial<T>) => {
  const {
    department_name_ids,
    vertical_name_ids,
    subsidiary_name_ids,
    project_name_ids,
    general_ledger_classification_name_ids,
    business_location_name_ids
  } = payload

  return {
    department_name_ids,
    vertical_name_ids,
    subsidiary_name_ids,
    project_name_ids,
    general_ledger_classification_name_ids,
    business_location_name_ids
  }
}

export const prepareDimensionOptions = (
  dimensions: DimensionNameObject
): { value: string; label: string }[] => {
  const dimensionNamesWithValues = Object.entries(dimensions).filter(
    ([_, value]) => value.length > 0
  )
  const dimensionNames = dimensionNamesWithValues.map(([key, _]) => key)
  const options = dimensionNames.map((dimensionName) => {
    const baseKey = dimensionName.split('_name_ids')[0]
    const dimensionKey = pluralize(baseKey)
    const value = DimensionFilterEnum[dimensionKey as keyof typeof DimensionFilterEnum]
    const label = value
      .split('_')
      .map((word) => capitalizeFirstLetter(word))
      .join(' ')
    const updatedLabel = label === 'General Ledger Classifications' ? 'Classifications' : label
    return { value: dimensionName, label: updatedLabel }
  })

  return options
}
