import { useMutation, useQueryClient } from '@tanstack/react-query'

import axiosServices from '@utils/axios'
import { errorSnack, successSnack } from '@utils/snackbar-utils'

import { INotificationProfile } from '@components/control-panel/types'

import useAuth from '@hooks/useAuth'

export const useCreateNotificationProfile = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      payload,
      onSuccess
    }: {
      payload: Partial<INotificationProfile>
      onSuccess?: () => void
    }) => {
      const { name, description } = payload

      return axiosServices.post(`/businesses/${user?.business_id}/notification_profiles`, {
        notification_profile: {
          name,
          description
        }
      })
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['fetch-notification-profiles', user?.business_id]
      })
      successSnack('Notification Profile Created Successfully')
      variables.onSuccess && variables.onSuccess()
    },
    onError: (error) => {
      errorSnack(`Error Creating Notification Profile: ${error.message}`)
    }
  })
}
