import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { rehydrateStore } from '@store/actions/rehydrate-store'

import { RootState } from '../index'

interface ModelState {
  selectedRowId: number | null
  selectedModel: any
}

const initialState: ModelState = {
  selectedRowId: null,
  selectedModel: null
}

const financialModelsSlice = createSlice({
  name: '@FINANCIAL_MODELS',
  initialState,
  reducers: {
    initializeTable: (state, action: PayloadAction<{ defaultRowId: number; model?: any }>) => {
      if (!state.selectedRowId && !state.selectedModel) {
        state.selectedRowId = action.payload.defaultRowId
        state.selectedModel = action.payload.model || null
      }
    },
    setSelectedModel: (state, action: PayloadAction<{ rowId: number | null; model: any }>) => {
      state.selectedRowId = action.payload.rowId
      state.selectedModel = action.payload.model
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrateStore, (_state, action) => {
      return { ...initialState, ...action.payload.financialModels }
    })
  }
})

// Export actions
export const { initializeTable, setSelectedModel } = financialModelsSlice.actions

// Reducer
export const financialModelsReducer = financialModelsSlice.reducer

// Selector
export const selectModelFocused = (state: RootState) =>
  _.get(state.financialModels, 'selectedRowId')
export const selectModel = (state: RootState) => _.get(state.financialModels, 'selectedModel')
