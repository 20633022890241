import { useQuery } from '@tanstack/react-query'

import axiosServices from '@utils/axios'

import useAuth from '@hooks/useAuth'

import { QUERY_STALE_TIME_DEFAULT } from 'queries/constants'

export const fetchSubsidiariesHierarchy = async (business_id: number) => {
  const { data } = await axiosServices.get(`/businesses/${business_id}/subsidiaries/hierarchy`)
  return data
}

export const useFetchSubsidiariesHierarchy = () => {
  const { user } = useAuth()

  return useQuery({
    queryKey: ['fetch-subsidiaries-hierarchy', user?.business_id],
    queryFn: () => fetchSubsidiariesHierarchy(user?.business_id!),
    staleTime: QUERY_STALE_TIME_DEFAULT
  })
}
