import { useMutation, useQueryClient } from '@tanstack/react-query'

import axiosServices from '@utils/axios'
import { snakeCaseKeys } from '@utils/case-conversion'

import useAuth from '@hooks/useAuth'

import { queryErrorToast, querySuccessToast } from 'queries/query-toasts'

export interface ICreateJobScheduleBody {
  scheduleExpression?: string
  timezone?: string
  state?: string
  sourceId?: number | string | undefined
  sourceType?: string
  scheduleConfigs?: string | undefined
  notificationProfileId?: string | number
}

export interface ICreateJobScheduleProps {
  onSuccess?: () => void
}

export const useCreateJobSchedule = (props: ICreateJobScheduleProps) => {
  const queryClient = useQueryClient()
  const { user } = useAuth()

  return useMutation({
    mutationFn: (body: ICreateJobScheduleBody) => {
      const payload: any = { data_replication_schedule: {} }

      payload.data_replication_schedule = snakeCaseKeys(body)

      return axiosServices.post(
        `/businesses/${user?.business_id}/data_replication_schedule`,
        payload
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fetch-job-schedules', user?.business_id] })
      props.onSuccess?.()
      querySuccessToast('Schedule created successfully')
    },
    onError: (error: any) => {
      queryErrorToast(error.message)
    }
  })
}
