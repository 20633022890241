import React, { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'

import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { humanizeCron } from '@utils/cron-utils'

import { Button } from '@core/button'
import { Popover, PopoverContent, PopoverTrigger } from '@core/popover'
import { Separator } from '@core/separator'

import AgGrid from '@components/ag-grid'
import {
  ActionCellRenderer,
  IActionData
} from '@components/ag-grid/custom-cell-renderer/action-cell-renderer'
import {
  DeleteCellRenderer,
  IConfirmationData
} from '@components/ag-grid/custom-cell-renderer/delete-cell-renderer'
import { JSONCellRenderer } from '@components/ag-grid/custom-cell-renderer/json-cell-renderer'
import OpenInNewTabCellRenderer from '@components/ag-grid/custom-cell-renderer/open-in-new-tab-cell-renderer'
import { closePopover } from '@components/form/charger-form'
import { Icon, Pencil, SendMail } from '@components/icons'

import { useDeleteJobSchedule } from '@layout-components/general/data-connections/queries/delete-job-schedule'
import {
  JobScheduleType,
  useFetchJobSchedules
} from '@layout-components/general/data-connections/queries/fetch-job-schedules'
import { useSendTestScheduledReportMail } from '@layout-components/general/data-connections/queries/send-test-scheduled-report-mail'
import { UpdateScheduleForm } from '@layout-components/general/data-connections/shared/job-schedules/update-schedule-form'
import {
  multiTextColumnDef,
  textSearchColumnDef
} from '@layout-components/general/data-connections/utils/col-defs'

import useAuth from '@hooks/useAuth'

import { selectCurrentPage } from '@store/slices/navigation'

const ManageSchedules = () => {
  const currentPage = useSelector(selectCurrentPage)
  const { isPending, data } = useFetchJobSchedules(
    undefined,
    JobScheduleType.EmailReporting,
    currentPage?.pageSettings?.id
  )

  const { mutate: deleteJobSchedule } = useDeleteJobSchedule({
    onSuccess: () => {
      gridRef.current?.api?.refreshCells({ force: true })
    }
  })

  const { mutate: sendTestEmail } = useSendTestScheduledReportMail({
    onSuccess: () => {
      console.log('Test email sent successfully')
    }
  })

  const jobSchedules = useMemo(() => data ?? [], [data])
  const { isPrimaryBusinessCharger } = useAuth()

  const popoverTriggerRef = useRef<HTMLButtonElement>(null)

  const gridRef = useRef<AgGridReact>(null)

  const actionCellRenderer = (params: ICellRendererParams) => {
    return (
      <div id='action-cell-renderer' className='flex size-full items-center'>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              ref={popoverTriggerRef}
              variant='text'
              className='rounded-[4px] p-0 focus:border-hidden'
            >
              <Icon icon={<Pencil />} />
            </Button>
          </PopoverTrigger>

          <PopoverContent className='w-[550px]'>
            <UpdateScheduleForm
              formType={'update'}
              data={params.data}
              onSuccess={() => {
                closePopover(popoverTriggerRef.current)
              }}
              onCancel={() => closePopover(popoverTriggerRef.current)}
            />
          </PopoverContent>
        </Popover>
      </div>
    )
  }

  const columnDefs: ColDef[] = useMemo(() => {
    const handleDelete = (data: IConfirmationData) => {
      deleteJobSchedule(data.id)
    }

    const onAction = (data: IActionData) => {
      const jobScheduleId = data.id
      sendTestEmail({ id: jobScheduleId })
    }

    const singleColumnEdit = {
      colId: 'actions',
      field: 'id',
      headerName: '',
      minWidth: 40,
      maxWidth: 40,
      sortable: false,
      filter: false,
      cellRenderer: actionCellRenderer,
      getQuickFilterText: () => ''
    }

    const deleteColumn = {
      colId: 'delete',
      field: 'id',
      headerName: '',
      minWidth: 40,
      maxWidth: 40,
      sortable: false,
      filter: false,
      cellRenderer: DeleteCellRenderer,
      cellRendererParams: {
        onDelete: handleDelete
      },
      getQuickFilterText: () => ''
    }

    const sendTestMailColumn = {
      colId: 'delete',
      field: 'id',
      headerName: '',
      minWidth: 40,
      maxWidth: 40,
      sortable: false,
      filter: false,
      cellRenderer: ActionCellRenderer,
      cellRendererParams: {
        onAction: onAction,
        actionType: 'dialog',
        confirmationDialogTitle: 'Send Test Mail',
        confirmationDialogDescription: (
          <>
            Are you sure you want to send a test email? <br />
            This email will be sent to the currently logged-in user.
          </>
        ),
        confirmLabel: 'Send',
        iconName: <Icon icon={<SendMail />} />
      },
      getQuickFilterText: () => ''
    }

    return [
      ...[singleColumnEdit],
      ...[deleteColumn],
      ...[sendTestMailColumn],
      textSearchColumnDef('friendlyName', 'Schedule Name', {
        minWidth: 200,
        getQuickFilterText: (params) => params.data.friendlyName,
        cellRenderer: isPrimaryBusinessCharger() ? 'openInNewTabCellRenderer' : undefined,
        cellRendererParams: {
          displayType: 'text',
          displayText: (params: ICellRendererParams) => params.node.data.friendlyName,
          onActionButtonClick: (params: ICellRendererParams) => {
            const name = params.node.data.name
            const url = `https://us-west-2.console.aws.amazon.com/scheduler/home?region=us-west-2#schedules/data-connector/${name}`
            window.open(url)
            params.api.deselectAll()
          }
        }
      }),
      {
        colId: 'scheduleExpressionTimezone',
        field: 'scheduleExpressionTimezone',
        headerName: 'Timezone'
      },
      {
        colId: 'humanizedCron',
        headerName: 'Scheduled Time',
        valueGetter: (params) =>
          humanizeCron(params.data.scheduleExpression, params.data.scheduleExpressionTimezone)
      },
      multiTextColumnDef('state', 'State'),
      {
        field: 'scheduleConfigs',
        headerName: 'Filters',
        cellRenderer: JSONCellRenderer,
        cellRendererParams: {
          title: 'Applied Filters'
        },
        maxWidth: 300
      }
    ]
  }, [isPrimaryBusinessCharger, deleteJobSchedule, sendTestEmail])

  return (
    <>
      <div className='flex min-h-11 w-full items-center space-x-2'>
        <Popover>
          <PopoverTrigger asChild>
            <Button ref={popoverTriggerRef}>Create Schedule</Button>
          </PopoverTrigger>

          <PopoverContent className='w-[550px]'>
            <UpdateScheduleForm
              formType={'add'}
              onSuccess={() => {
                closePopover(popoverTriggerRef.current)
              }}
              onCancel={() => closePopover(popoverTriggerRef.current)}
            />
          </PopoverContent>
        </Popover>
      </div>

      <div className='flex h-full flex-col gap-2'>
        <div className='flex min-h-0 grow flex-col'>
          <Separator className='w-full' />
          <div className='min-h-0 grow'>
            <AgGrid
              style={{ height: 'calc(100% - 10%)' }}
              ref={gridRef}
              autoSizeStrategy={{ type: 'fitCellContents' }}
              rowData={jobSchedules}
              columnDefs={columnDefs}
              rowSelection={'single'}
              suppressRowClickSelection={false}
              suppressRowDeselection={true}
              defaultColDef={{
                resizable: true,
                filter: 'agTextColumnFilter',
                floatingFilter: true,
                filterParams: {
                  buttons: ['clear']
                }
              }}
              editType={'fullRow'}
              loading={isPending}
              // isRowSelectable={(node: IRowNode) => isRowSelectable(node.data)}
              gridOptions={{
                components: {
                  getRowNodeId: (data: { id: any }) => data.id,
                  openInNewTabCellRenderer: OpenInNewTabCellRenderer
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ManageSchedules
