import { useSelector } from 'react-redux'

import { sumOfValues } from '@utils/obj-utils'

import { Icon, ToggleLeft } from '@components/icons'

import { usePageDispatch, usePageSelector } from '@store/index'
import {
  selectRightDrawerOpen,
  selectRightDrawerType,
  setRightDrawer,
  setRightDrawerType
} from '@store/slices/action-bar'
import { selectDimensions } from '@store/slices/dimension'
import { selectCurrentPage } from '@store/slices/navigation'

import Button from 'components/core/button'
import { isUnregisteredPage } from 'types/page'
import { cn } from 'utils/style-utils'

import { RightDrawerTypeEnum } from '../../types/menu'

export const ToggleDimensionFilterButton = ({
  dimensionFilterOpen,
  onToggleDimensionFilter
}: {
  dimensionFilterOpen: boolean
  onToggleDimensionFilter: () => void
}) => {
  const selectedDimensions = usePageSelector(selectDimensions)
  const totalCount = sumOfValues(selectedDimensions)

  return (
    <Button
      aria-describedby={'dimension-filters'}
      className='border-none'
      onClick={onToggleDimensionFilter}
      variant={totalCount > 0 ? 'primary' : 'text'}
    >
      {`(${totalCount}) Dimension Filters`}
      <div className={cn('transition-transform', dimensionFilterOpen && 'rotate-180')}>
        <Icon icon={<ToggleLeft />} />
      </div>
    </Button>
  )
}

const ToggleDimensionFilters = () => {
  const pageDispatch = usePageDispatch()

  const currentPage = useSelector(selectCurrentPage)
  const rightDrawerOpen = usePageSelector(selectRightDrawerOpen)
  const rightDrawerType = usePageSelector(selectRightDrawerType)

  const searchFilters = currentPage?.pageSettings?.settings?.search_filters

  const onToggleDimensionFilter = () => {
    if (rightDrawerType !== RightDrawerTypeEnum.dimensions) {
      pageDispatch(setRightDrawerType({ rightDrawerType: RightDrawerTypeEnum.dimensions }))

      if (!rightDrawerOpen) {
        pageDispatch(setRightDrawer({ rightDrawerOpen: true }))
      }
    } else {
      pageDispatch(setRightDrawer({ rightDrawerOpen: !rightDrawerOpen }))
    }
  }

  if (isUnregisteredPage(currentPage?.pageSettings?.layout_type)) {
    return <></>
  }

  if (typeof searchFilters === 'undefined') {
    return <></>
  }

  return (
    <ToggleDimensionFilterButton
      dimensionFilterOpen={rightDrawerOpen}
      onToggleDimensionFilter={onToggleDimensionFilter}
    />
  )
}

export default ToggleDimensionFilters
