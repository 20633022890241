import React, { useEffect, useState } from 'react'

import InfoIcon from '@mui/icons-material/Info'
import { Link } from '@mui/material'

import { ColDef } from 'ag-grid-community'

import { awsCronExpressionValidator, humanizeCron } from '@utils/cron-utils'
import { enumToOptions } from '@utils/obj-utils'

import { Text } from '@core/text'

import AgGrid from '@components/ag-grid'
import { RIGHT_ALIGN_COLUMN_DATA } from '@components/ag-grid/types'
import { Button } from '@components/core/button'
import { Input } from '@components/core/input'
import { Select } from '@components/form/select'

import {
  IJobSchedule,
  ScheduleStateEnum
} from '@layout-components/general/data-connections/queries/fetch-job-schedules'
import { useUpdateJobSchedules } from '@layout-components/general/data-connections/queries/update-job-schedules'
import {
  multiTextColumnDef,
  numericColumnDef,
  textSearchColumnDef
} from '@layout-components/general/data-connections/utils/col-defs'

import useAuth from '@hooks/useAuth'

interface UpdateMultipleSchedulesFormProps {
  scheduleTypeIsRust?: boolean
  data?: IJobSchedule[]
  onSuccess: () => void
  onCancel: () => void
}

export const UpdateMultipleSchedulesForm: React.FC<UpdateMultipleSchedulesFormProps> = ({
  scheduleTypeIsRust,
  onSuccess,
  onCancel,
  data
}) => {
  const { user } = useAuth()
  const [cronVal, setCronVal] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [scheduleState, setScheduleState] = useState<ScheduleStateEnum>(ScheduleStateEnum.Enabled)
  const [parallelism, setParallelism] = useState<number | null>(null)
  const [replicationJobId, setReplicationJobId] = useState<number | null>(null)

  useEffect(() => {
    if (data && data.length > 0) {
      const lastSchedule: IJobSchedule = data[data.length - 1]
      const cronPart = lastSchedule.scheduleExpression.match(/cron\((.*)\)/)?.[1] || ''
      setCronVal(cronPart)
      setScheduleState(lastSchedule.state)

      if (scheduleTypeIsRust) {
        const targetData = JSON.parse(lastSchedule.targetInput || '{}')
        const messageBody = JSON.parse(targetData.MessageBody)

        setParallelism(messageBody.parallelism || null)
        setReplicationJobId(messageBody.replication_job_id || null)
      }
    }
  }, [data, scheduleTypeIsRust])

  const { mutate: updateJobSchedules } = useUpdateJobSchedules({ onSuccess })

  const handleCronChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setCronVal(value)

    try {
      awsCronExpressionValidator(value)
      setError('')
    } catch (err) {
      setError((err as Error).message)
    }
  }

  const handleSave = () => {
    if (!data) {
      return
    }

    const payload =
      data?.map((schedule) => ({
        id: schedule.id,
        scheduleExpression: `cron(${cronVal})`,
        timezone: user?.business_local_timezone || 'UTC',
        parallelism: parallelism ?? null,
        replicationJobId: replicationJobId ?? null,
        state: scheduleState
      })) || []

    updateJobSchedules(payload)
  }

  const columnDefs: ColDef[] = [
    textSearchColumnDef('name', 'Job Schedule Name'),
    {
      colId: 'scheduleExpression',
      field: 'scheduleExpression',
      headerName: 'Schedule Expression'
    },
    {
      colId: 'scheduleExpressionTimezone',
      field: 'scheduleExpressionTimezone',
      headerName: 'TZ'
    },
    multiTextColumnDef('state', 'State'),
    numericColumnDef('parallelism', 'Parallelism', {
      ...RIGHT_ALIGN_COLUMN_DATA
    }),
    numericColumnDef('replicationJobIdFromTargetInput', 'Replication Job ID')
  ]

  return (
    <div>
      <div className='flex w-full items-center justify-between gap-2'>
        <Button onClick={onCancel} type='button' variant='outline' size={'default'}>
          {'Cancel'}
        </Button>
        {data && data.length > 0 && (
          <Button
            onClick={handleSave}
            disabled={!!error}
            type='button'
            variant={error ? 'disabled' : 'primary'}
            size='default'
          >
            Save
          </Button>
        )}
      </div>
      <div className='mt-4 flex flex-col items-stretch'>
        <div className='flex items-center justify-between'>
          <Text variant='h4'>Bulk Update - Job Schedules</Text>
        </div>

        {data && data.length > 0 && (
          <>
            <div className='flex items-center justify-between'>
              <Text variant='description'>Job Schedules</Text>
            </div>
            <div className='h-[calc(40vh-12rem)] w-full'>
              <AgGrid
                style={{ height: '100%' }}
                autoSizeStrategy={{ type: 'fitCellContents' }}
                rowData={data}
                columnDefs={columnDefs}
                defaultColDef={{
                  resizable: true
                }}
                persistFilterState={true}
              />
            </div>
          </>
        )}

        <div className='mt-2 flex gap-4'>
          {/*Timezone*/}
          <div className='flex-1'>
            <div className='mt-2 flex items-center justify-between'>
              <Text variant='description'>New Timezone</Text>
            </div>
            <div className='mt-2 gap-1'>
              <Input
                value={user?.business_local_timezone}
                type='text'
                className='w-full'
                disabled
              />
            </div>
          </div>

          {/*AWS Schedule State*/}
          <div className='flex-1'>
            <div className='mt-2 flex items-center justify-between'>
              <Text variant='description'>State</Text>
            </div>
            <div className='mt-2 gap-1'>
              <Select
                options={enumToOptions(ScheduleStateEnum)}
                value={scheduleState}
                onChange={(value) => setScheduleState(value as ScheduleStateEnum)}
              />
            </div>
          </div>
        </div>

        {scheduleTypeIsRust && (
          <>
            <div className='mt-2 flex gap-4'>
              {/*Parallelism */}
              <div className='flex-1'>
                <div className='mt-2 flex items-center justify-between'>
                  <Text variant='description'>Parallelism</Text>
                </div>
                <Input
                  value={parallelism ?? ''}
                  type='number'
                  onChange={(e) => setParallelism(e.target.value ? Number(e.target.value) : null)}
                  min={1}
                />
              </div>

              {/*Replication Job ID*/}
              <div className='flex-1'>
                <div className='mt-2 flex items-center justify-between'>
                  <Text variant='description'>Replication Job ID</Text>
                </div>
                <Input
                  value={replicationJobId ?? ''}
                  type='number'
                  onChange={(e) =>
                    setReplicationJobId(e.target.value ? Number(e.target.value) : null)
                  }
                />
              </div>
            </div>
          </>
        )}

        {/*Cron Expression*/}
        <div className='mb-8 flex gap-4'>
          <div className='flex-1'>
            <div className='mt-2 flex items-center justify-start'>
              <Text variant='description'>New Schedule Expression</Text>
              <Link
                className='ml-2'
                href='https://docs.aws.amazon.com/eventbridge/latest/userguide/eb-scheduled-rule-pattern.html'
                target='_blank'
                rel='noopener noreferrer'
                display='flex'
                alignItems='center'
              >
                <InfoIcon fontSize='small' />
              </Link>
            </div>
            <Input
              value={cronVal}
              type='text'
              placeholder='0 23 * * ? *'
              onChange={handleCronChange}
            />
            {error && (
              <Text variant='details' className='mt-2 text-error-darker'>
                Error: {error}
              </Text>
            )}
            <Text variant='details' className='mt-2'>
              {humanizeCron(cronVal, '')}
            </Text>
          </div>
          <div className='flex-1'></div>
        </div>
      </div>
    </div>
  )
}
