import { useQuery } from '@tanstack/react-query'

import { QUERY_STALE_TIME_DEFAULT } from '@queries/constants'
import axiosServices from '@utils/axios'
import { camelCaseKeys } from '@utils/case-conversion'

import { IJobScheduleHistory } from '@components/control-panel/types'

import useAuth from '@hooks/useAuth'

const fetchScheduleHistory = async (
  businessId: number | undefined,
  sourceID?: number | undefined | string
): Promise<IJobScheduleHistory[]> => {
  if (!businessId) throw new Error('Business ID is required')

  const endpoint = `/businesses/${businessId}/data_replication_schedule/${sourceID}/schedule_history`

  const response = await axiosServices.get(endpoint)

  return camelCaseKeys(response.data) as IJobScheduleHistory[]
}

export const useFetchScheduleHistory = (sourceID?: number | undefined | string) => {
  const { user } = useAuth()

  return useQuery({
    queryKey: ['fetch-schedule-history', user?.business_id, sourceID],
    enabled: !!user?.business_id,
    queryFn: () => fetchScheduleHistory(user?.business_id, sourceID),
    staleTime: QUERY_STALE_TIME_DEFAULT
  })
}
