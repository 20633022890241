import { useCallback, useEffect, useRef, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router'

import { useDebounce } from '@uidotdev/usehooks'
import { isMacOS } from '@utils/os'
import { DisplayNameSchema, getDisplayName } from '@utils/schema-renaming'
import { humanizeFieldName } from '@utils/string-utils'

import { IconButton } from '@core/icon-button'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@core/select'
import { Skeleton } from '@core/skeleton'
import { Tabs, TabsList, TabsTrigger } from '@core/tab'
import { Text } from '@core/text'
import Tooltip from '@core/tooltip'

import Button from '@components/core/button'
import { SearchInput } from '@components/core/search-input'
import ColumnHidden from '@components/icons/column-hidden.svg?react'
import ColumnVisible from '@components/icons/column-visible.svg?react'
import ForeignKeyHidden from '@components/icons/foreign-key-hidden.svg?react'
import ForeignKeyVisible from '@components/icons/foreign-key-visible.svg?react'
import TableHidden from '@components/icons/table-hidden.svg?react'
import TableVisible from '@components/icons/table-visible.svg?react'

import { useCheckPathSelected } from '@hooks/use-check-path-selected'

import { initComponentManagement } from '@store/actions/component-management'
import { useDispatch, useSelector } from '@store/index'
import { selectTitle } from '@store/slices/component/basic-config'
import {
  MODEL_CUSTOM_SQL_QUERY,
  MODEL_KPI,
  chooseModel,
  selectCurrentSelectedModel,
  selectHideUnusedColumns,
  selectHideUnusedTables,
  selectQueryData,
  selectSearchText,
  selectShowForeignKeys,
  setSearchText,
  toggleHideUnusedColumns,
  toggleHideUnusedTables,
  toggleShowForeignKeys
} from '@store/slices/component/query-config'

import { useFetchModels } from '@pages/component-management/queries/fetch-models'
import { IModel, ModelType } from '@pages/component-management/types/query-builder-types'
import { useFetchRenamings } from '@pages/configuration/queries/fetch-renamings'

import { DrilldownProvider } from 'contexts/drilldown-context'

import { QueryResultProvider } from '../contexts/query-result-context'
import { QueryTransformedResultProvider } from '../contexts/query-transformed-result-context'
import { useFetchComponent } from '../queries/fetch-component'
import { SaveComponent } from './save-component'
import { TitleDescriptionForm } from './title-description-form'

enum EditFlowTabs {
  DATA_SELECTION = 'data-selection',
  DATA_PREVIEW = 'data-preview',
  VISUALIZE = 'visualize'
}

export function EditFlow() {
  const { id } = useParams<{ id: string }>()
  const { isPending, data } = useFetchComponent(id)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // selected tab
  const dataSelectionSelected = useCheckPathSelected(EditFlowTabs.DATA_SELECTION)
  const dataPreviewSelected = useCheckPathSelected(EditFlowTabs.DATA_PREVIEW)
  const visualizeSelected = useCheckPathSelected(EditFlowTabs.VISUALIZE)
  let tabValue: string | undefined = undefined
  if (dataSelectionSelected) tabValue = EditFlowTabs.DATA_SELECTION
  if (dataPreviewSelected) tabValue = EditFlowTabs.DATA_PREVIEW
  if (visualizeSelected) tabValue = EditFlowTabs.VISUALIZE

  // basic config
  const title = useSelector(selectTitle)

  useEffect(() => {
    dispatch(initComponentManagement(data))
  }, [data, dispatch])

  // model select dropdown
  const { data: models = [] } = useFetchModels()
  const selectedModelName = useSelector(selectCurrentSelectedModel())
  const { data: renamingsData } = useFetchRenamings(true)
  const renamings = (renamingsData || {}) as DisplayNameSchema
  const renderName = (modelName: string) =>
    humanizeFieldName(getDisplayName(renamings, modelName) || modelName)
  const onSelect = (modelName: string) =>
    dispatch(chooseModel({ modelName, modelType: ModelType.table }))

  // search bar
  const searchText = useSelector(selectSearchText)
  const [searchInputValue, setSearchInputValue] = useState(searchText)
  const debouncedSearchValue = useDebounce(searchInputValue, 300) // 300ms delay

  useEffect(() => {
    dispatch(setSearchText(debouncedSearchValue))
  }, [debouncedSearchValue, dispatch])

  // foreign key toggle
  const showForeignKeys = useSelector(selectShowForeignKeys)

  // unused tables toggle
  const hideUnusedTables = useSelector(selectHideUnusedTables)

  // unused columns toggle
  const hideUnusedColumns = useSelector(selectHideUnusedColumns)

  // clear selections button
  const selectedData = useSelector(selectQueryData())
  const isEmpty = _.isEmpty(selectedData?.fields) && _.isEmpty(selectedData?.associations)

  const searchInputRef = useRef<HTMLInputElement>(null)

  const handleKeyboardShortcuts = useCallback(
    (event: KeyboardEvent) => {
      if (event.altKey) {
        switch (event.code) {
          case 'Digit1': // Alt + 1 (Option + 1 on Mac)
            navigate(EditFlowTabs.DATA_SELECTION)
            break
          case 'Digit2': // Alt + 2 (Option + 2 on Mac)
            navigate(EditFlowTabs.DATA_PREVIEW)
            break
          case 'Digit3': // Alt + 3 (Option + 3 on Mac)
            navigate(EditFlowTabs.VISUALIZE)
            break
        }
      }

      // "Ctrl + /" for search ("Cmd + /" on Mac)
      if ((event.metaKey || event.ctrlKey) && event.code === 'Slash') {
        event.preventDefault()
        searchInputRef.current?.focus()
      }
    },
    [navigate]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardShortcuts)
    return () => {
      window.removeEventListener('keydown', handleKeyboardShortcuts)
    }
  }, [handleKeyboardShortcuts])

  const isMac = isMacOS()
  const modifierKey = isMac ? '⌥' : 'Alt'

  return (
    <DrilldownProvider componentId={`component_${id}`}>
      <QueryResultProvider>
        <QueryTransformedResultProvider>
          <div className='size-full'>
            <div className='grid grid-cols-3 items-center border-0 border-b border-solid border-grey-light bg-white px-4 py-1.5'>
              <div className='flex flex-row items-center gap-2 overflow-hidden'>
                <div className='flex min-w-0 shrink flex-col justify-center'>
                  {isPending ? (
                    <Skeleton className='h-6 w-48' />
                  ) : (
                    <Text variant='inlineFormTitle' className='truncate'>
                      {title}
                    </Text>
                  )}
                </div>
                <div className='flex shrink-0 items-center'>
                  {isPending ? <Skeleton className='h-8 w-4' /> : <TitleDescriptionForm />}
                </div>
                {tabValue === EditFlowTabs.DATA_SELECTION &&
                  selectedModelName &&
                  selectedModelName !== MODEL_CUSTOM_SQL_QUERY && (
                    <div className='mr-2 flex grow items-center pl-2'>
                      <MenuDivider />

                      {!_.isEmpty(models) && selectedModelName && (
                        <Select value={selectedModelName} onValueChange={onSelect}>
                          <SelectTrigger className='mx-1 h-8 w-fit shrink-0'>
                            <SelectValue placeholder='Select Model' />
                          </SelectTrigger>
                          <SelectContent>
                            {_.map(models, (model: IModel) => (
                              <SelectItem key={model.name} value={model.name}>
                                {renderName(model.name)}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      )}

                      <Tooltip title={`${isMac ? '⌘' : 'Ctrl'} + /`} side='bottom' delay={0}>
                        <SearchInput
                          ref={searchInputRef}
                          className='mx-1 mt-px h-8 min-w-[175px]'
                          value={searchInputValue}
                          onChange={(value) => setSearchInputValue(value)}
                          clearable
                          fullWidth
                        />
                      </Tooltip>

                      {selectedModelName !== MODEL_KPI && (
                        <>
                          <MenuDivider />

                          <Tooltip
                            title={showForeignKeys ? 'Hide foreign keys' : 'Show foreign keys'}
                            side='bottom'
                            delay={0}
                          >
                            <IconButton
                              variant='ghost'
                              onClick={() => dispatch(toggleShowForeignKeys())}
                            >
                              {showForeignKeys ? <ForeignKeyVisible /> : <ForeignKeyHidden />}
                            </IconButton>
                          </Tooltip>

                          <Tooltip
                            title={hideUnusedTables ? 'Show unused tables' : 'Hide unused tables'}
                            side='bottom'
                            delay={0}
                          >
                            <IconButton
                              onClick={() => dispatch(toggleHideUnusedTables())}
                              variant='ghost'
                              disabled={isEmpty}
                            >
                              {hideUnusedTables ? <TableHidden /> : <TableVisible />}
                            </IconButton>
                          </Tooltip>

                          <Tooltip
                            title={
                              hideUnusedColumns ? 'Show unused columns' : 'Hide unused columns'
                            }
                            side='bottom'
                            delay={0}
                          >
                            <IconButton
                              onClick={() => dispatch(toggleHideUnusedColumns())}
                              variant='ghost'
                              disabled={isEmpty}
                            >
                              {hideUnusedColumns ? <ColumnHidden /> : <ColumnVisible />}
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  )}
              </div>
              <div className='-mb-px flex justify-center'>
                <Tabs id='component-management-edit-nav' value={tabValue} onValueChange={navigate}>
                  <TabsList className='grid grid-cols-3 gap-2'>
                    <Tooltip title={`${modifierKey} + 1`} side='bottom' delay={0}>
                      <TabsTrigger value='data-selection'>Data Selection</TabsTrigger>
                    </Tooltip>
                    <Tooltip title={`${modifierKey} + 2`} side='bottom' delay={0}>
                      <TabsTrigger value='data-preview'>Data Preview</TabsTrigger>
                    </Tooltip>
                    <Tooltip title={`${modifierKey} + 3`} side='bottom' delay={0}>
                      <TabsTrigger value='visualize'>Visualize</TabsTrigger>
                    </Tooltip>
                  </TabsList>
                </Tabs>
              </div>
              <div className='flex items-center justify-end gap-2'>
                <Button variant='outline' onClick={() => navigate('..')}>
                  Back to Charts
                </Button>
                <SaveComponent />
              </div>
            </div>
            <div className='h-[calc(100vh-88px)] overflow-hidden bg-grey-backgroundfb'>
              <Outlet />
            </div>
          </div>
        </QueryTransformedResultProvider>
      </QueryResultProvider>
    </DrilldownProvider>
  )
}

function MenuDivider() {
  return <div className='mx-1 h-7 w-px bg-grey-lighter' />
}
