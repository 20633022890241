export const Archive = () => (
  <>
    <rect x='5' y='6' width='14' height='2' fill='currentColor' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6 9H18V13.3317C17.6461 13.0627 17.1884 12.9528 16.762 13.0186C16.4105 13.0729 16.0568 13.2336 15.7888 13.5333C15.5177 13.8365 15.3941 14.2157 15.3939 14.5929C15.3935 15.2154 15.3933 15.3383 15.3933 15.4612C15.3933 15.584 15.3935 15.7069 15.3936 16.3294L15.3938 16.8686C15.3826 16.8663 15.3714 16.864 15.3601 16.8619L15.358 16.8615C14.602 16.7205 13.9403 17.1355 13.6496 17.7226C13.6041 17.8143 13.5687 17.907 13.5428 18H6V9ZM9.5 10H14.5V11H9.5V10Z'
      fill='currentColor'
    />
    <path
      d='M16.3936 18.6586C16.3936 18.4841 16.3936 18.3102 16.3936 18.1357C16.3936 16.2686 16.3922 16.4008 16.3936 14.5338C16.3936 14.2163 16.5944 13.9964 16.9142 13.947C17.2002 13.9028 17.4862 14.0583 17.5763 14.321C17.6059 14.4073 17.6094 14.5041 17.6094 14.5957C17.6108 16.551 17.6108 16.5063 17.6108 18.4617C17.6108 18.5262 17.6108 18.5907 17.6108 18.7034C17.7566 18.5782 17.8715 18.4834 17.9821 18.3846C18.1427 18.241 18.2983 18.0915 18.4596 17.9486C18.7224 17.7161 19.1056 17.7155 19.343 17.9434C19.5803 18.1712 19.5648 18.5242 19.3028 18.7639C18.7421 19.277 18.1807 19.7894 17.6165 20.2991C17.2227 20.6547 16.7691 20.6449 16.3774 20.2859C15.8167 19.7729 15.2497 19.2658 14.689 18.7528C14.4854 18.5664 14.4368 18.3247 14.5453 18.1067C14.658 17.8788 14.9024 17.7339 15.1743 17.7846C15.3244 17.813 15.4772 17.8992 15.5949 17.9954C15.8576 18.2101 16.0992 18.4472 16.35 18.675C16.3648 18.6698 16.3788 18.6638 16.3936 18.6586Z'
      fill='currentColor'
    />
  </>
)
