import { useRef } from 'react'

import { Button } from '@components/core/button'
import { IconButton } from '@components/core/icon-button'
import { Popover, PopoverContent, PopoverTrigger } from '@components/core/popover'
import { Text } from '@components/core/text'
import { closePopover } from '@components/form/charger-form'
import { Icon } from '@components/icons'
import { Refresh } from '@components/icons/refresh'
import { Trash } from '@components/icons/trash'

export const DeactivateRestorePopover = ({
  isActiveResource,
  resourceType,
  onDeactivate,
  onRestore
}: {
  isActiveResource: boolean
  resourceType: 'User' | 'Access Profile' | 'Notification Profile'
  onDeactivate?: () => void
  onRestore?: () => void
}) => {
  const popoverTriggerRef = useRef<HTMLButtonElement>(null)

  return (
    <Popover>
      <PopoverTrigger asChild>
        <IconButton
          ref={popoverTriggerRef}
          className='m-0 border-none p-0 focus:text-primary-darker'
        >
          <Icon icon={isActiveResource ? <Trash /> : <Refresh />} />
        </IconButton>
      </PopoverTrigger>

      <PopoverContent className='mt-1'>
        <div className='flex flex-col gap-2'>
          <Text variant='body' className='text-blue-dark'>
            {isActiveResource ? `Deactivate ${resourceType}?` : `Restore ${resourceType}?`}
          </Text>

          <div className='flex justify-end gap-2'>
            <Button
              variant='text'
              size='extra-small'
              className='mt-2'
              onClick={() => closePopover(popoverTriggerRef.current)}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              size='extra-small'
              className='mt-2'
              onClick={() => {
                isActiveResource ? onDeactivate?.() : onRestore?.()
                closePopover(popoverTriggerRef.current)
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}
