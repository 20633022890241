import { Draft, PayloadAction, createSlice, current } from '@reduxjs/toolkit'

import { GridState } from 'ag-grid-community'

import { isEqualArrayBy } from '@utils/obj-utils'
import { humanizeFieldName } from '@utils/string-utils'

import { getColumnLayoutChanges } from 'pages/component-management/table-builder/utils'
import { IDataType, IHeader } from 'pages/component-management/types/component-types'
import {
  FilterType,
  IFilter,
  ITableColumn,
  ITableConfig,
  TableSettings
} from 'pages/component-management/types/table-builder-types'
import { RootState } from 'store'
import { initComponentManagement } from 'store/actions/component-management'

const initialState: ITableConfig = {
  pivotMode: false,
  columns: [],
  externalFilters: {},
  tableSettings: {},
  serversideTransformation: false
}

const tableConfigSlice = createSlice({
  name: '@COMPONENT/TABLE_CONFIG',
  initialState: initialState,
  reducers: {
    togglePivotMode: (state) => {
      state.pivotMode = !state.pivotMode
    },
    syncColumnsFromHeaders: (state, action: PayloadAction<IHeader[]>) => {
      const headers = action.payload
      const synced = isEqualArrayBy(state.columns, headers, 'field', 'name')
      if (synced) return

      const newColumns = _.map(headers, (header) => {
        const existing = _.find(state.columns, { field: header.name })
        if (existing) return existing

        const column: Draft<ITableColumn> = {
          hide: !!state.columns.length,
          field: header.name,
          headerName: humanizeFieldName(header.name),
          cellDataType: header.data_type,
          customData: {
            formula: header.formula
          }
        }
        if (_.isEqual(header.data_type, IDataType.NUMBER)) {
          column.enableValue = true
        } else {
          column.enableRowGroup = true
          column.enablePivot = true
        }

        return column
      })

      state.columns = newColumns.sort((a, b) => {
        const aIndex = _.findIndex(state.columns, { field: a.field })
        const bIndex = _.findIndex(state.columns, { field: b.field })

        return (
          (aIndex === -1 ? Number.MAX_SAFE_INTEGER : aIndex) -
          (bIndex === -1 ? Number.MAX_SAFE_INTEGER : bIndex)
        )
      })
    },
    modifyColumnLayout: (state, action: PayloadAction<GridState>) => {
      const gridState = action.payload
      const changes = getColumnLayoutChanges(
        gridState,
        current(state.columns) as ITableColumn[],
        state.pivotMode
      )
      if (!changes) return

      state.pivotMode = changes.pivotMode
      state.columns = _.forEach(state.columns, (column) => {
        const field = column.field!
        const layout = changes.columns[field]
        if (!layout) return

        _.assign(column, layout)
      })
    },
    updateColumn: (state, action: PayloadAction<Partial<ITableColumn>>) => {
      const { field, customData, ...changes } = action.payload
      const column = _.find(state.columns, { field })
      if (!column) return

      _.assign(column, changes)
      column.customData = column.customData || {}
      _.assign(column.customData, customData)
    },
    replaceColumns: (state, action: PayloadAction<ITableColumn[]>) => {
      state.columns = action.payload as Draft<ITableColumn>[]
      state.pivotMode = _.some(action.payload, 'pivot')
    },
    enableExternalFilter: (state, action: PayloadAction<string>) => {
      const field = action.payload
      state.externalFilters[field] = {
        filterType: FilterType.SELECT,
        options: [],
        defaultValue: undefined
      }
    },
    disableExternalFilter: (state, action: PayloadAction<string>) => {
      const field = action.payload
      delete state.externalFilters[field]
    },
    updateExternalFilter: (
      state,
      action: PayloadAction<{ field: string; filterConfig: IFilter }>
    ) => {
      const { field, filterConfig } = action.payload
      state.externalFilters[field] = filterConfig
    },
    updateTableSettings: (state, action: PayloadAction<TableSettings>) => {
      state.tableSettings = state.tableSettings || {}
      _.assign(state.tableSettings, action.payload)
    },
    toggleServersideTransformation: (state) => {
      state.serversideTransformation = !state.serversideTransformation
    },
    resetTableConfig: () => _.cloneDeep(initialState)
  },
  extraReducers: (builder) => {
    builder.addCase(initComponentManagement, (_state, action) => {
      const tableConfig = _.get(action.payload, 'component_attributes.table_config', initialState)
      return _.assign({}, initialState, tableConfig)
    })
  }
})

// Actions
export const {
  togglePivotMode,
  syncColumnsFromHeaders,
  modifyColumnLayout,
  updateColumn,
  replaceColumns,
  enableExternalFilter,
  disableExternalFilter,
  updateExternalFilter,
  updateTableSettings,
  toggleServersideTransformation,
  resetTableConfig
} = tableConfigSlice.actions

// Reducer
export const tableConfigReducer = tableConfigSlice.reducer

// Selectors
export const selectTableConfig = (state: RootState) => state.component.tableConfig

export const selectColumns = (state: RootState) => selectTableConfig(state).columns

export const selectExternalFilter = (field: string | null) => (state: RootState) =>
  field ? selectTableConfig(state).externalFilters[field] : null

export const selectPivotMode = (state: RootState) => selectTableConfig(state).pivotMode

export const selectTableSettings = (state: RootState) => selectTableConfig(state).tableSettings

export const selectServersideTransformation = (state: RootState) =>
  selectTableConfig(state).serversideTransformation
